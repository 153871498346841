import Vue from 'vue'

let LedgerType = {
  	//转换流水类型
  	formatLedgerType: function(ledgerType){
      switch(ledgerType){
        case 'Funding': return window.vm.$t('ledgerType.funding');
        case 'Trade': return window.vm.$t('ledgerType.trade');
        case 'Settled UPL': return window.vm.$t('ledgerType.settledUPL');
        case 'Settled RPL': return window.vm.$t('ledgerType.settledRPL');
        case 'Transfer Out': return window.vm.$t('ledgerType.transferOut');
        case 'Transfer In': return window.vm.$t('ledgerType.transferIn');
        case 'Open Long': return window.vm.$t('side.long');
        case 'Open Short': return window.vm.$t('side.short');
        case 'Close Buy': return window.vm.$t('side.buy');
        case 'Close Sell': return window.vm.$t('side.sell');
        case 'Full Liquidation of Long': return window.vm.$t('ledgerType.fullLiquidationOfLong');
        case 'Full Liquidation of Short': return window.vm.$t('ledgerType.fullLiquidationOfShort');
        default : return ledgerType;
      }
  	}
}

Vue.directive('ledgerType', {
  bind: function(el, binding, vnode){
    let timestamp = new Date().getTime();
    // 给vnode更新key，用于数据更新时重新渲染改节点元素
    vnode.key = timestamp;
    el.innerHTML = LedgerType.formatLedgerType(binding.value);
  },
  unbind: function(el){

  }
});

export default LedgerType
