import colors from 'vuetify/lib/util/colors'

export default {
  dark: localStorage.getItem("iobots-darkMode") == 1 ? true : false,
  themes:{
    light:{
      primary: "#9E9E9E",
      // red: '#F44336',
      // green: '#4CAF50',
      // upBars: '#A9DCC3',
      // downBars: '#F5A6AE',
    },
    dark:{
      // primary: "#9E9E9E",
      // red: '#F44336',
      // green: '#4CAF50',
      // upBars: '#A9DCC3',
      // downBars: '#F5A6AE',
    },
  },
  // red: '#F44336',
  // green: '#4CAF50',
  // upBars: '#A9DCC3',
  // downBars: '#F5A6AE',
}