import Vue from 'vue'

let Role = {
  	//转换角色
  	format: function(role){
      switch(role){
        case 'USER': return window.vm.$t('role.user');
        case 'TRADER': return window.vm.$t('role.trader');
        default : return role;
      }
  	}
}

Vue.directive('role', {
  bind: function(el, binding, vnode){
    let timestamp = new Date().getTime();
    // 给vnode更新key，用于数据更新时重新渲染改节点元素
    vnode.key = timestamp;
    el.innerHTML = Role.format(binding.value);
  },
  unbind: function(el){

  }
});

export default Role
