import Vue from 'vue'
import Vuex from 'vuex'
import asset from './modules/asset'
import theme from './modules/theme'
import user from './modules/user'
import data from './modules/data'
import communication from './modules/communication'

Vue.use(Vuex);

export const store = new Vuex.Store({
    modules: {
        asset,
        theme,
        user,
        data,
        communication
    },
    state: {
        
    },
    mutations: {

    },
});