import { renkoMultipliers } from "./data";
import { timeZones } from "./timeZones";

const state = {
	// 所有交易所
	exchanges: null,
	// 交易类别
	tradingCategories: null,
	// 订单类别
	orderCategories: null,
	// 订单策略
	orderStrategies: null,
	// 策略
	strategies: null,
	// 订单方向
	orderSides: ['Long', 'Short', 'Buy', 'Sell'],
	// 信号交易对
	signalSymbols: null,
	// 信号交易对的小数位
	signalDecimalNumbers: null,
	// 现货交易对
	spotSymbols: null,
	// 现货交易对详情信息
	spotSymbolInfos: null,
	// 现货交易对的小数位
	spotDecimalNumbers: null,
	// 期货交易对
	futuresSymbols: null,
	// 期货交易对的小数位
	futuresDecimalNumbers: null,
  	// 期货交易对的基本货币
  	futuresBaseCurrencies: null,
	// 期货合约交易对详情信息
	futuresSymbolInfos: null,
  	// 永续USDT合约交易对
	swapUsdtSymbols: null,
	// 永续USDT合约交易对详情信息
	swapUsdtSymbolInfos: null,
	// 现货交易对的小数位
	// 上一次市场价
	lastMarketPrice: null,
	// 当前市场价
	marketPrice: null,
	// 自适应屏幕,默认PC,否则mobile
	responsive: 'PC',
	// 合约面值
	contractFaceValue: null,
	// 交易所支持的现货交易对
	exchangeSupportedSpotSymbols: null,
	// 交易所支持的期货交易对
	exchangeSupportedFuturesSymbols: null,
	// 交易所支持的永续USDT合约交易对
	exchangeSupportedSwapUsdtSymbols: null,
	// 当前国家代码
	countryCode: null,
	// 当前网站所在ip地址
	ip: null,
	// IobotsWS推送过来的数据下标
	webSocketDataIndex: 0,
	// IobotsWS推送过来的数据
	webSocketData: null,
	// Renko的乘数
	renkoMultipliers,
	// spot摘要
	spotSummary: null,
	// swap-usdt摘要
	usdtSummary: null,
	// swap-coin摘要
	coinSummary: null,
	// 网络状态: 默认true已经联网
	networkStatus: true,
	// Renko交易对
	renkoSymbols: null,
	// Renko交易对小数位
	renkoDecimalNumbers: null,
	// 资产页面数据
	assetInfo:{
		okexSpot:[],
		okexFunding:[],
		okexSwap:[],
		okexSpotTotal:[],
		okexFuturesTotal:[],
		krakenSpot:[],
		krakenFunding:[],
		krakenSwap:[],
		krakenSpotTotal:[],
		krakenFuturesTotal:[],
	},
	// 所有货币集合
	currencies: null,
	// 策略颜色集合
	strategyColors: null,
	// 信号周期数组
	signalIntervals: null,
	// 时区
	timeZones,
	// 信号页面跳转公共数据,
	signalPage:null,
	// 手机底部导航栏
	mobileBtnPage:0,
	// 投资组合历史收益图表数据
	portfolioRoeHistoryChart: [],
	// 交易类型跳转公共数据
	tradingTypePage:null,
	// 版本号
	version: null,
	// 所有货币集合
	marketTypeCryptoCurrencies: null,
	// 交易默认的交易所
	defaultExchange: 'ftx'
}

const getters = {
	exchanges: state => {
		if (state.exchanges) {
			return state.exchanges;
		}
		let exchanges = JSON.parse(localStorage.getItem('iobots-exchanges'));
		if (exchanges) {
			state.exchanges = exchanges;
		}
		return state.exchanges;
	},
	tradingCategories: state => {
		if (state.tradingCategories) {
			return state.tradingCategories;
		}
		let tradingCategories = JSON.parse(localStorage.getItem('iobots-tradingCategories'));
		if (tradingCategories) {
			state.tradingCategories = tradingCategories;
		}
		return state.tradingCategories;
	},
	orderCategories: state => {
		if (state.orderCategories) {
			return state.orderCategories;
		}
		let orderCategories = JSON.parse(localStorage.getItem('iobots-orderCategories'));
		if (orderCategories) {
			state.orderCategories = orderCategories;
		}
		return state.orderCategories;
	 },
	 orderStrategies: state => {
		if (state.orderStrategies) {
			return state.orderStrategies;
		}
		let orderStrategies = JSON.parse(localStorage.getItem('iobots-orderStrategies'));
		if (orderStrategies) {
			state.orderStrategies = orderStrategies;
		}
		return state.orderStrategies;
	 },
	 strategies: state => {
		if (state.strategies) {
			return state.strategies;
		}
		let strategies = JSON.parse(localStorage.getItem('iobots-strategies'));
		if (strategies) {
			state.strategies = strategies;
		}
		return state.strategies;
	 },
	 orderSides: state => {
		if (state.orderSides) {
			return state.orderSides;
		}
		let orderSides = JSON.parse(localStorage.getItem('iobots-orderSides'));
		if (orderSides) {
			state.orderSides = orderSides;
		}
		return state.orderSides;
	 },
	 signalSymbols: state => {
		if (state.signalSymbols) {
			return state.signalSymbols;
		}
		let signalSymbols = JSON.parse(localStorage.getItem('iobots-signalSymbols'));
		if (signalSymbols) {
			state.signalSymbols = signalSymbols;
		}
		return state.signalSymbols;
	},
	signalDecimalNumbers: state => {
		if (state.signalDecimalNumbers) {
			return state.signalDecimalNumbers;
		}
		let signalDecimalNumbers = JSON.parse(localStorage.getItem('iobots-signalDecimalNumbers'));
		if (signalDecimalNumbers) {
			state.signalDecimalNumbers = signalDecimalNumbers;
		}
		return state.signalDecimalNumbers;
	},
	spotSymbols: state => {
		if (state.spotSymbols) {
			return state.spotSymbols;
		}
		let spotSymbols = JSON.parse(localStorage.getItem('iobots-spotSymbols'));
		if (spotSymbols) {
			state.spotSymbols = spotSymbols;
		}
		return state.spotSymbols;
	},
	spotSymbolInfos: state => {
		if (state.spotSymbolInfos) {
			return state.spotSymbolInfos;
		}
		let spotSymbolInfos = JSON.parse(localStorage.getItem('iobots-spotSymbolInfos'));
		if (spotSymbolInfos) {
			state.spotSymbolInfos = spotSymbolInfos;
		}
		return state.spotSymbolInfos;
	},
	spotDecimalNumbers: state => {
		if (state.spotDecimalNumbers) {
			return state.spotDecimalNumbers;
		}
		let spotDecimalNumbers = JSON.parse(localStorage.getItem('iobots-spotDecimalNumbers'));
		if (spotDecimalNumbers) {
			state.spotDecimalNumbers = spotDecimalNumbers;
		}
		return state.spotDecimalNumbers;
	},
	futuresSymbols: state => {
		if (state.futuresSymbols) {
			return state.futuresSymbols;
		}
		let futuresSymbols = JSON.parse(localStorage.getItem('iobots-futuresSymbols'));
		if (futuresSymbols) {
			state.futuresSymbols = futuresSymbols;
		}
		return state.futuresSymbols;
	},
	swapUsdtSymbols: state => {
		if (state.swapUsdtSymbols) {
			return state.swapUsdtSymbols;
		}
		let swapUsdtSymbols = JSON.parse(localStorage.getItem('iobots-swapUsdtSymbols'));
		if (swapUsdtSymbols) {
			state.swapUsdtSymbols = swapUsdtSymbols;
		}
		return state.swapUsdtSymbols;
	},
	swapUsdtSymbolInfos: state => {
		if (state.swapUsdtSymbolInfos) {
			return state.swapUsdtSymbolInfos;
		}
		let swapUsdtSymbolInfos = JSON.parse(localStorage.getItem('iobots-swapUsdtSymbolInfos'));
		if (swapUsdtSymbolInfos) {
			state.swapUsdtSymbolInfos = swapUsdtSymbolInfos;
		}
		return state.swapUsdtSymbolInfos;
	},
	futuresDecimalNumbers: state => {
		if (state.futuresDecimalNumbers) {
			return state.futuresDecimalNumbers;
		}
		let futuresDecimalNumbers = JSON.parse(localStorage.getItem('iobots-futuresDecimalNumbers'));
		if (futuresDecimalNumbers) {
			state.futuresDecimalNumbers = futuresDecimalNumbers;
		}
		return state.futuresDecimalNumbers;
	},
	futuresBaseCurrencies: state => {
		return state.futuresBaseCurrencies;
	},
	futuresSymbolInfos: state => {
		if (state.futuresSymbolInfos) {
			return state.futuresSymbolInfos;
		}
		let futuresSymbolInfos = JSON.parse(localStorage.getItem('iobots-futuresSymbolInfos'));
		if (futuresSymbolInfos) {
			state.futuresSymbolInfos = futuresSymbolInfos;
		}
		return state.futuresSymbolInfos;
	},
	lastMarketPrice: state => {
		return state.lastMarketPrice;
	},
	marketPrice: state => {
		return state.marketPrice;
	},
	responsive: state => {
		return state.responsive;
	},
	contractFaceValue: state => {
    if (state.contractFaceValue) {
    	return state.contractFaceValue;
    }
    let contractFaceValue = JSON.parse(localStorage.getItem('iobots-contractFaceValue'));
    if (contractFaceValue) {
    	state.contractFaceValue = contractFaceValue;
    }
		return state.contractFaceValue;
	},
	exchangeSupportedSpotSymbols: state => {
    if (state.exchangeSupportedSpotSymbols) {
    	return state.exchangeSupportedSpotSymbols;
    }
    let exchangeSupportedSpotSymbols = JSON.parse(localStorage.getItem('iobots-exchangeSupportedSpotSymbols'));
    if (exchangeSupportedSpotSymbols) {
    	state.exchangeSupportedSpotSymbols = exchangeSupportedSpotSymbols;
    }
    	return state.exchangeSupportedSpotSymbols;
  	},
	exchangeSupportedFuturesSymbols: state => {
    if (state.exchangeSupportedFuturesSymbols) {
    	return state.exchangeSupportedFuturesSymbols;
    }
    let exchangeSupportedFuturesSymbols = JSON.parse(localStorage.getItem('iobots-exchangeSupportedFuturesSymbols'));
    if (exchangeSupportedFuturesSymbols) {
    	state.exchangeSupportedFuturesSymbols = exchangeSupportedFuturesSymbols;
    }
    	return state.exchangeSupportedFuturesSymbols;
  	},
  	exchangeSupportedSwapUsdtSymbols: state => {
    if (state.exchangeSupportedSwapUsdtSymbols) {
    	return state.exchangeSupportedSwapUsdtSymbols;
    }
    let exchangeSupportedSwapUsdtSymbols = JSON.parse(localStorage.getItem('iobots-exchangeSupportedSwapUsdtSymbols'));
    if (exchangeSupportedSwapUsdtSymbols) {
    	state.exchangeSupportedSwapUsdtSymbols = exchangeSupportedSwapUsdtSymbols;
    }
    	return state.exchangeSupportedSwapUsdtSymbols;
  	},
  	countryCode: state => {
  		if (state.countryCode) {
	    	return state.countryCode;
	    }
    	let countryCode = localStorage.getItem('iobots-countryCode');
    	if (countryCode) {
    		state.countryCode = countryCode;
    	}
  		return state.countryCode;
  	},
  	ip: state => {
  		if (state.ip) {
	    	return state.ip;
	    }
    	let ip = localStorage.getItem('iobots-ip');
    	if (ip) {
    		state.ip = ip;
    	}
  		return state.ip;
  	},
  	webSocketDataIndex: state => {
  		return state.webSocketDataIndex;
  	},
  	webSocketData: state => {
  		return state.webSocketData;
  	},
  	renkoMultipliers: state => {
  		return state.renkoMultipliers;
	},
	spotSummary: state => {
		if (state.spotSummary) {
	    	return state.spotSummary;
		}
		let spotSummary = JSON.parse(localStorage.getItem('iobots-spotSummary'));
    	if (spotSummary) {
    		state.spotSummary = spotSummary;
    	}
  		return state.spotSummary;
	},
	usdtSummary: state => {
		if (state.usdtSummary) {
	    	return state.usdtSummary;
		}
		let usdtSummary = JSON.parse(localStorage.getItem('iobots-usdtSummary'));
    	if (usdtSummary) {
    		state.usdtSummary = usdtSummary;
    	}
  		return state.usdtSummary;
	},
	coinSummary: state => {
		if (state.coinSummary) {
			return state.coinSummary;
		}
		let coinSummary = JSON.parse(localStorage.getItem('iobots-coinSummary'));
		if (coinSummary) {
			state.coinSummary = coinSummary;
		}
		return state.coinSummary;
	},
	networkStatus: state => {
		return state.networkStatus;
	},
	renkoSymbols: state => {
		if (state.renkoSymbols) {
			return state.renkoSymbols;
		}
		let renkoSymbols = JSON.parse(localStorage.getItem('iobots-renkoSymbols'));
		if (renkoSymbols) {
			state.renkoSymbols = renkoSymbols;
		}
		return state.renkoSymbols;
	},
	renkoDecimalNumbers: state => {
		if (state.renkoDecimalNumbers) {
			return state.renkoDecimalNumbers;
		}
		let renkoDecimalNumbers = JSON.parse(localStorage.getItem('iobots-renkoDecimalNumbers'));
		if (renkoDecimalNumbers) {
			state.renkoDecimalNumbers = renkoDecimalNumbers;
		}
		return state.renkoDecimalNumbers;
	},
	assetInfo: state => {
		let assetInfo = JSON.parse(localStorage.getItem('iobots-assetInfo'));
		if (assetInfo) {
			state.assetInfo = assetInfo;
		}
		return state.assetInfo;
	},
	currencies: state => {
		let currencies = JSON.parse(localStorage.getItem('iobots-currencies'));
		if (currencies) {
			state.currencies = currencies;
		}
		return state.currencies;
	},
	strategyColors: state => {
		let strategyColors = JSON.parse(localStorage.getItem('iobots-strategyColors'));
		if (strategyColors) {
			state.strategyColors = strategyColors;
		}
		return state.strategyColors;
	},
	signalIntervals: state => {
		let signalIntervals = JSON.parse(localStorage.getItem('iobots-signalIntervals'));
		if (signalIntervals) {
			state.signalIntervals = signalIntervals;
		}
		return state.signalIntervals;
	},
	timeZones: state => {
  		return state.timeZones;
	},
	signalPage: state =>{
		return state.signalPage;
	},
	mobileBtnPage: state =>{
		return state.mobileBtnPage;
	},
	portfolioRoeHistoryChart: state => {
		return state.portfolioRoeHistoryChart;
	},
	tradingTypePage: state =>{
		return state.tradingTypePage;
	},
	version: state =>{
		return state.version;
	},
	marketTypeCryptoCurrencies: state =>{
		return state.marketTypeCryptoCurrencies;
	},
	defaultExchange: state =>{
		return state.defaultExchange;
	},
}

const actions = {
	exchangesHandler(context, payload){
		context.commit('exchangesHandler', payload);
	},
	tradingCategoriesHandler(context, payload){
		context.commit('tradingCategoriesHandler', payload);
	},
	orderCategoriesHandler(context, payload){
		context.commit('orderCategoriesHandler', payload);
	},
	orderStrategiesHandler(context, payload){
		context.commit('orderStrategiesHandler', payload);
	},
	strategiesHandler(context, payload){
		context.commit('strategiesHandler', payload);
	},
	orderSidesHandler(context, payload){
		context.commit('orderSidesHandler', payload);
	},
	signalSymbolsHandler(context, payload){
		context.commit('signalSymbolsHandler', payload);
	},
	signalDecimalNumbersHandler(context, payload){
		context.commit('signalDecimalNumbersHandler', payload);
	},
	spotSymbolsHandler(context, payload){
		context.commit('spotSymbolsHandler', payload);
	},
	spotSymbolInfosHandler(context, payload){
		context.commit('spotSymbolInfosHandler', payload);
	},
	spotDecimalNumbersHandler(context, payload){
		context.commit('spotDecimalNumbersHandler', payload);
	},
	futuresSymbolsHandler(context, payload){
		context.commit('futuresSymbolsHandler', payload);
	},
	futuresDecimalNumbersHandler(context, payload){
		context.commit('futuresDecimalNumbersHandler', payload);
	},
	futuresBaseCurrenciesHandler(context, payload){
		context.commit('futuresBaseCurrenciesHandler', payload);
	},
	futuresSymbolInfosHandler(context, payload){
		context.commit('futuresSymbolInfosHandler', payload);
	},
	swapUsdtSymbolsHandler(context, payload){
		context.commit('swapUsdtSymbolsHandler', payload);
	},
	swapUsdtSymbolInfosHandler(context, payload){
		context.commit('swapUsdtSymbolInfosHandler', payload);
	},
	marketPriceHandler(context, payload){
		context.commit('marketPriceHandler', payload);
	},
	responsiveHandler(context, payload){
		context.commit('responsiveHandler', payload);
	},
	contractFaceValueHandler(context, payload){
		context.commit('contractFaceValueHandler', payload);
	},
	exchangeSupportedSpotSymbolsHandler(context, payload){
  		context.commit('exchangeSupportedSpotSymbolsHandler', payload);
  	},
  	exchangeSupportedFuturesSymbolsHandler(context, payload){
  		context.commit('exchangeSupportedFuturesSymbolsHandler', payload);
  	},
  	exchangeSupportedSwapUsdtSymbolsHandler(context, payload){
  		context.commit('exchangeSupportedSwapUsdtSymbolsHandler', payload);
  	},
  	countryCodeHandler(context, payload){
  		context.commit('countryCodeHandler', payload);
  	},
  	ipHandler(context, payload){
  		context.commit('ipHandler', payload);
  	},
  	webSocketDataHandler(context, payload){
  		context.commit('webSocketDataHandler', payload);
  	},
  	renkoMultipliersHandler(context, payload){
  		context.commit('renkoMultipliersHandler', payload);
	},
	spotSummaryHandler(context, payload){
		context.commit('spotSummaryHandler', payload);
	},
	usdtSummaryHandler(context, payload){
		context.commit('usdtSummaryHandler', payload);
	},
	coinSummaryHandler(context, payload){
		context.commit('coinSummaryHandler', payload);
	},
	networkStatusHandler(context, payload){
		context.commit('networkStatusHandler', payload);
	},
	assetInfoHandler(context, payload){
		context.commit('assetInfoHandler', payload);
	},
	renkoSymbolsHandler(context, payload){
		context.commit('renkoSymbolsHandler', payload);
	},
	renkoDecimalNumbersHandler(context, payload){
		context.commit('renkoDecimalNumbersHandler', payload);
	},
	currenciesHandler(context, payload){
		context.commit('currenciesHandler', payload);
	},
	strategyColorsHandler(context, payload){
		context.commit('strategyColorsHandler', payload);
	},
	signalIntervalsHandler(context, payload){
		context.commit('signalIntervalsHandler', payload);
	},
	signalPageHandler(context, payload){
		context.commit('signalPageHandler', payload);
	},
	mobileBtnPageHandler(context, payload){
		context.commit('mobileBtnPageHandler', payload);
	},
	portfolioRoeHistoryChartHandler(context, payload){
		context.commit('portfolioRoeHistoryChartHandler', payload);
	},
	tradingTypePageHandler(context, payload){
		context.commit('tradingTypePageHandler', payload);
	},
	versionHandler(context, payload){
		context.commit('versionHandler', payload);
	},
	marketTypeCryptoCurrenciesHandler(context, payload){
		context.commit('marketTypeCryptoCurrenciesHandler', payload);
	},
	defaultExchangeHandler(context, payload){
		context.commit('defaultExchangeHandler', payload);
	},
}

const mutations = {
	exchangesHandler(state, exchanges){
		localStorage.setItem('iobots-exchanges', JSON.stringify(exchanges));
		state.exchanges = exchanges;
	},
	tradingCategoriesHandler(state, tradingCategories){
		localStorage.setItem('iobots-tradingCategories', JSON.stringify(tradingCategories));
		state.tradingCategories = tradingCategories;
	},
	orderCategoriesHandler(state, orderCategories){
		localStorage.setItem('iobots-orderCategories', JSON.stringify(orderCategories));
		state.orderCategories = orderCategories;
	},
	orderStrategiesHandler(state, orderStrategies){
		localStorage.setItem('iobots-orderStrategies', JSON.stringify(orderStrategies));
		state.orderStrategies = orderStrategies;
	},
	strategiesHandler(state, strategies){
		localStorage.setItem('iobots-strategies', JSON.stringify(strategies));
		state.strategies = strategies;
	},
	orderSidesHandler(state, orderSides){
		localStorage.setItem('iobots-orderSides', JSON.stringify(orderSides));
		state.orderSides = orderSides;
	},
	signalSymbolsHandler(state, signalSymbols){
		localStorage.setItem('iobots-signalSymbols', JSON.stringify(signalSymbols));
		state.signalSymbols = signalSymbols;
	},
	signalDecimalNumbersHandler(state, signalDecimalNumbers){
		localStorage.setItem('iobots-signalDecimalNumbers', JSON.stringify(signalDecimalNumbers));
		state.signalDecimalNumbers = signalDecimalNumbers;
	},
	spotSymbolsHandler(state, spotSymbols){
		localStorage.setItem('iobots-spotSymbols', JSON.stringify(spotSymbols));
		state.spotSymbols = spotSymbols;
	},
	spotSymbolInfosHandler(state, spotSymbolInfos){
		localStorage.setItem('iobots-spotSymbolInfos', JSON.stringify(spotSymbolInfos));
		state.spotSymbolInfos = spotSymbolInfos;
	},
	spotDecimalNumbersHandler(state, spotDecimalNumbers){
		localStorage.setItem('iobots-spotDecimalNumbers', JSON.stringify(spotDecimalNumbers));
		state.spotDecimalNumbers = spotDecimalNumbers;
	},
	futuresSymbolsHandler(state, futuresSymbols){
		localStorage.setItem('iobots-futuresSymbols', JSON.stringify(futuresSymbols));
		state.futuresSymbols = futuresSymbols;
	},
	futuresDecimalNumbersHandler(state, futuresDecimalNumbers){
		localStorage.setItem('iobots-futuresDecimalNumbers', JSON.stringify(futuresDecimalNumbers));
		state.futuresDecimalNumbers = futuresDecimalNumbers;
	},
	futuresBaseCurrenciesHandler(state, futuresBaseCurrencies){
		state.futuresBaseCurrencies = futuresBaseCurrencies;
	},
	futuresSymbolInfosHandler(state, futuresSymbolInfos){
		localStorage.setItem('iobots-futuresSymbolInfos', JSON.stringify(futuresSymbolInfos));
		state.futuresSymbolInfos = futuresSymbolInfos;
	},
	swapUsdtSymbolsHandler(state, swapUsdtSymbols){
		localStorage.setItem('iobots-swapUsdtSymbols', JSON.stringify(swapUsdtSymbols));
		state.swapUsdtSymbols = swapUsdtSymbols;
	},
	swapUsdtSymbolInfosHandler(state, swapUsdtSymbolInfos){
		localStorage.setItem('iobots-swapUsdtSymbolInfos', JSON.stringify(swapUsdtSymbolInfos));
		state.swapUsdtSymbolInfos = swapUsdtSymbolInfos;
	},
	marketPriceHandler(state, marketPrice){
		// 转为字符串是为了复制一个对象
		let marketPriceString = JSON.stringify(state.marketPrice);
		state.lastMarketPrice = JSON.parse(marketPriceString);
		// console.log(state.lastMarketPrice)
		// 先置空在赋值，为了刷新页面
		state.marketPrice = null;
		state.marketPrice = marketPrice;
	},
	responsiveHandler(state, responsive){
		state.responsive = responsive;
	},
	contractFaceValueHandler(state, contractFaceValue){
		localStorage.setItem('iobots-contractFaceValue', JSON.stringify(contractFaceValue));
		state.contractFaceValue = contractFaceValue;
	},
	exchangeSupportedSpotSymbolsHandler(state, exchangeSupportedSpotSymbols){
  		localStorage.setItem('iobots-exchangeSupportedSpotSymbols', JSON.stringify(exchangeSupportedSpotSymbols));
  		state.exchangeSupportedSpotSymbols = exchangeSupportedSpotSymbols;
  	},
  	exchangeSupportedFuturesSymbolsHandler(state, exchangeSupportedFuturesSymbols){
  		localStorage.setItem('iobots-exchangeSupportedFuturesSymbols', JSON.stringify(exchangeSupportedFuturesSymbols));
  		state.exchangeSupportedFuturesSymbols = exchangeSupportedFuturesSymbols;
  	},
  	exchangeSupportedSwapUsdtSymbolsHandler(state, exchangeSupportedSwapUsdtSymbols){
  		localStorage.setItem('iobots-exchangeSupportedSwapUsdtSymbols', JSON.stringify(exchangeSupportedSwapUsdtSymbols));
  		state.exchangeSupportedSwapUsdtSymbols = exchangeSupportedSwapUsdtSymbols;
  	},
  	countryCodeHandler(state, countryCode){
  		localStorage.setItem('iobots-countryCode', countryCode);
		state.countryCode = countryCode;
	},
	ipHandler(state, ip){
		localStorage.setItem('iobots-ip', ip);
		state.ip = ip;
	},
	webSocketDataHandler(state, webSocketData){
		state.webSocketData = webSocketData;
		state.webSocketDataIndex += 1;
	},
	renkoMultipliersHandler(context, renkoMultipliers){
		state.renkoMultipliers = renkoMultipliers;
	},
	spotSummaryHandler(state, spotSummary){
		localStorage.setItem('iobots-spotSummary', JSON.stringify(spotSummary));
		state.spotSummary = spotSummary;
	},
	usdtSummaryHandler(state, usdtSummary){
		localStorage.setItem('iobots-usdtSummary', JSON.stringify(usdtSummary));
		state.usdtSummary = usdtSummary;
	},
	coinSummaryHandler(state, coinSummary){
		localStorage.setItem('iobots-coinSummary', JSON.stringify(coinSummary));
		state.coinSummary = coinSummary;
	},
	networkStatusHandler(state, networkStatus){
		state.networkStatus = networkStatus;
	},
	assetInfoHandler(state, assetInfo){
		localStorage.setItem('iobots-assetInfo', JSON.stringify(assetInfo));
		state.assetInfo = assetInfo;
	},
	renkoSymbolsHandler(state, renkoSymbols){
		localStorage.setItem('iobots-renkoSymbols', JSON.stringify(renkoSymbols));
		state.renkoSymbols = renkoSymbols;
	},
	renkoDecimalNumbersHandler(state, renkoDecimalNumbers){
		localStorage.setItem('iobots-renkoDecimalNumbers', JSON.stringify(renkoDecimalNumbers));
		state.renkoDecimalNumbers = renkoDecimalNumbers;
	},
	currenciesHandler(state, currencies){
		localStorage.setItem('iobots-currencies', JSON.stringify(currencies));
		state.currencies = currencies;
	},
	strategyColorsHandler(state, strategyColors){
		localStorage.setItem('iobots-strategyColors', JSON.stringify(strategyColors));
		state.strategyColors = strategyColors;
	},
	signalIntervalsHandler(state, signalIntervals){
		localStorage.setItem('iobots-signalIntervals', JSON.stringify(signalIntervals));
		state.signalIntervals = signalIntervals;
	},
	signalPageHandler(state, signalPage){
		state.signalPage = signalPage;
	},
	tradingTypePageHandler(state, tradingTypePage){
		state.tradingTypePage = tradingTypePage;
	},
	mobileBtnPageHandler(state, mobileBtnPage){
		state.mobileBtnPage = mobileBtnPage;
	},
	portfolioRoeHistoryChartHandler(state, portfolioRoeHistoryChart){
		state.portfolioRoeHistoryChart = portfolioRoeHistoryChart;
	},
	versionHandler(state, version){
		state.version = version;
	},
	marketTypeCryptoCurrenciesHandler(state, marketTypeCryptoCurrencies){
		state.marketTypeCryptoCurrencies = marketTypeCryptoCurrencies;
	},
	defaultExchangeHandler(state, defaultExchange){
		state.defaultExchange = defaultExchange;
	},
}

export default {
	state,
	getters,
	actions,
	mutations
}
