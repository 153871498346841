<template>
  <div>
    <!-- 搜索区域 -->
    <v-col cols="12" md="7" offset-md="7" class="py-0"  v-if="responsive == 'PC'">
      <v-row dense>
        <v-col cols="12" sm="6" md="2" xs="6">
          <v-select class="fs-12" dense v-model="searchForm.exchange" :items="exchanges" :label="$t('common.exchange')" item-text="name"
            item-value="code" clearable></v-select>
        </v-col>
        <v-col cols="12" sm="6" md="2">
          <v-select class="fs-12" dense v-model="searchForm.symbol" :items="futuresSymbols" :label="$t('common.symbol')" clearable></v-select>
        </v-col>
        <v-col cols="12" sm="5" md="2">
          <v-select class="fs-12" dense v-model="searchForm.side" :items="orderSides" :label="$t('common.side')" clearable></v-select>
        </v-col>
        <v-col cols="12" sm="5" md="2">
          <v-text-field class="fs-12" dense v-model="searchForm.futuresOrderId" :label="$t('log.futuresOrderId')" clearable></v-text-field>
        </v-col>
        <v-col cols="12" sm="2" md="2">
          <v-btn icon @click="searchFuturesOrderHistory">
            <v-icon>mdi-magnify</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" class="px-5" v-if="responsive == 'mobile'">
      <v-row dense>
        <v-col cols="12">
          <div class="d-custom-flex">
             <v-select class="fs-12 mr-5" dense v-model="searchForm.exchange" :items="exchanges" :label="$t('common.exchange')" item-text="name"
            item-value="code" clearable></v-select>
            <v-select class="fs-12" dense v-model="searchForm.symbol" :items="futuresSymbols" :label="$t('common.symbol')" clearable></v-select>
          </div>
          <div class="d-custom-flex">
            <v-select class="fs-12 mr-5" dense v-model="searchForm.side" :items="orderSides" :label="$t('common.side')" clearable></v-select>
            <v-text-field class="fs-12" dense v-model="searchForm.futuresOrderId" :label="$t('log.futuresOrderId')" clearable></v-text-field>
            <v-btn class="float-right mt-2" x-small icon  @click="searchFuturesOrderHistory">
            <v-icon>mdi-magnify</v-icon>
          </v-btn>
          </div>
        </v-col>
      </v-row> 
    </v-col>
    <!-- 数据展示区域 -->
    <v-col cols="12" md="12" class="pa-0 ma-0 pa-0 px-3" v-if="responsive == 'PC'">
      <v-row dense v-if="futuresOrderLoading"> 
          <v-col cols="12">
              <v-skeleton-loader dense type="table-row-divider@30" class="mx-auto"></v-skeleton-loader>
          </v-col>
      </v-row>
      <v-data-table dense v-if="!futuresOrderLoading" :headers="futuresOrderHistoryHeaders" :items="futuresOrderHistory" :items-per-page="pageSize" hide-default-footer disable-pagination>
        <template v-slot:body="{ items }">
          <tbody>
            <tr v-for="(item,index) in items" :key="index">
              <td :class="CommonJS.formatExchangeColor(item.exchangeName)">{{ item.exchangeName }}</td>
              <td class="px-0 mx-0">{{ null == item.futuresOrderId ? item.id : item.futuresOrderId }}</td>
              <td class="px-0 mx-0" >{{ null == item.exchangeOrderId ? "--" : item.exchangeOrderId }}</td>
              <td>{{ item.symbol }}</td>
              <td :class="['open_long' == item.side || 'close_buy' == item.side  ? 'green--text':'red--text']">
                <span v-side="item.side"></span>
              </td>
              <td>{{ item.orderType }}</td>
              <td class="text-right" >{{ (item.side == 'open_short'|| item.side == 'close_sell') ? '-' : '' }}{{ formatNumber(item.size) }}</td>
              <td class="text-right" >{{ 0 == item.price ? "--" : formatNumber(item.price.toFixed(futuresDecimalNumbers[item.symbol])) }}</td>
              <td class="text-right" >{{ 0 == item.takeProfitPrice ? "--" : formatNumber(item.takeProfitPrice.toFixed(futuresDecimalNumbers[item.symbol])) }}</td>
              <td class="text-right" >{{ 0 == item.stopLossPrice1 ? "--" : formatNumber(item.stopLossPrice1.toFixed(futuresDecimalNumbers[item.symbol])) }}</td>
              <td class="text-right" :class="item.beFixedStopLossPrice ? 'grey--text' : ''">{{ 0 == item.stopLossPrice ? "--" : formatNumber(item.stopLossPrice.toFixed(futuresDecimalNumbers[item.symbol])) }}</td>
              <td class="text-right" :class="item.beFixedStopLossPrice ? '' : 'grey--text'">{{ 0 == item.fixedStopLossPrice ? "--" : formatNumber(item.fixedStopLossPrice.toFixed(futuresDecimalNumbers[item.symbol])) }}</td>
              <td class="text-right">{{ item.leverage }}</td>
              <td><span v-status="item.status"></span></td>
              <td class="px-0 mx-0">{{ item.timestamp }}</td>
              <td :style="{color: getColorByStrategy(item.strategy)}">{{ item.strategy }}</td>
              <td :class="item.bgColor">{{ item.category }}</td>
            </tr>
            <tr v-if="!futuresOrderLoading && futuresOrderHistoryTotalElements == 0">
              <td class="text-center" colspan="17">{{ $t('log.noDataAvailable')}}</td>
            </tr>
          </tbody>
        </template>
      </v-data-table>
       <v-divider v-if="!futuresOrderLoading"></v-divider>
      <v-row class="pb-5" v-if="futuresOrderHistoryTotalElements > 0">
        <v-col cols="auto" class="pl-0">
          <v-pagination color="lightred" :items-per-page="pageSize" circle prev-icon="mdi-menu-left" next-icon="mdi-menu-right" v-model="futuresOrderHistoryPageNo" :length="futuresOrderHistoryTotalPage" :total-visible="15"  @input="getFuturesOrderHistoryByPages" class="ml-2 mt-3"></v-pagination>
        </v-col>
        <v-col cols="auto" class="mt-5 pt-5">
          {{$t("log.totalNumber") + formatNumber(futuresOrderHistoryTotalElements)}}
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="12" sm="6" md="4" class="text-right mt-3">
          <v-btn outlined tile class="ma-1" small @click="exportTradeLog()" :loading="tradingLoading">
            <v-icon small class="mr-1">mdi-download</v-icon>{{$t("log.downloadTradingLogs")}}
          </v-btn>
          <v-btn outlined tile class="ma-1" small @click="downloadOkexLedger()" :loading="okexLedgerLoading">
            <v-icon small class="mr-1">mdi-download</v-icon>{{$t("log.okexLedger")}}
          </v-btn>
          <v-btn outlined tile class="ma-1" small @click="exPortorCurrent()" :loading="exportorLoading">
            <v-icon small class="mr-1">mdi-download</v-icon>{{$t("log.downloadCurrent")}}
          </v-btn>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" md="12" class="pa-0 ma-0 pa-0 px-3" v-if="responsive == 'mobile'">
      <v-simple-table dense>
        <template v-slot:default>
          <thead>
            <tr>
              <th>{{$t("log.exchange")}}</th>
              <th class="px-0 mx-0">{{$t("log.futuresOrderId")}}</th>
              <th class="px-0 mx-0">{{$t("log.exchangeOrderId")}}</th>
              <th>{{$t("log.symbol")}}</th>
              <th>{{$t("log.side")}}</th>
              <th>{{$t("log.type")}}</th>
              <th class="text-right">{{$t("log.size")}}</th>
              <th class="text-right">{{$t("log.price")}}</th>
              <th class="text-right">{{$t("log.profit")}}</th>
              <th class="text-right">{{$t("log.loss1")}}</th>
              <th class="text-right">{{$t("log.loss")}}</th>
              <th class="text-right">{{$t("log.fixedStopLoss")}}</th>
              <th class="text-right">{{$t("log.leverage")}}</th>
              <th>{{$t("log.status")}}</th>
              <th class="px-0 mx-0">{{$t("log.time")}}</th>
              <th>{{$t("common.strategy")}}</th>
              <th>{{$t("common.category")}}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="futuresOrderLoading">
              <td colspan="17">
                <v-progress-linear indeterminate></v-progress-linear>
              </td>
            </tr>
            <tr v-if="!futuresOrderLoading && futuresOrderHistoryTotalElements == 0">
              <td class="text-center" colspan="17">{{ $t('log.noDataAvailable')}}</td>
            </tr>
            <tr v-for="(item,index) in futuresOrderHistory" :key="index">
              <td :class="CommonJS.formatExchangeColor(item.exchangeName)">{{ item.exchangeName }}</td>
              <td class="px-0 mx-0">{{ null == item.futuresOrderId ? item.id : item.futuresOrderId }}</td>
              <td class="px-0 mx-0" >{{ null == item.exchangeOrderId ? "--" : item.exchangeOrderId }}</td>
              <td>{{ item.symbol }}</td>
              <td :class="['open_long' == item.side || 'close_buy' == item.side  ? 'green--text':'red--text']">
                <span v-side="item.side"></span>
              </td>
              <td>{{ item.orderType }}</td>
              <td class="text-right" >{{ (item.side == 'open_short'|| item.side == 'close_sell') ? '-' : '' }}{{ formatNumber(item.size) }}</td>
              <td class="text-right" >{{ 0 == item.price ? "--" : formatNumber(item.price.toFixed(futuresDecimalNumbers[item.symbol])) }}</td>
              <td class="text-right" >{{ 0 == item.takeProfitPrice ? "--" : formatNumber(item.takeProfitPrice.toFixed(futuresDecimalNumbers[item.symbol])) }}</td>
              <td class="text-right" >{{ 0 == item.stopLossPrice1 ? "--" : formatNumber(item.stopLossPrice1.toFixed(futuresDecimalNumbers[item.symbol])) }}</td>
              <td class="text-right" :class="item.beFixedStopLossPrice ? 'grey--text' : ''">{{ 0 == item.stopLossPrice ? "--" : formatNumber(item.stopLossPrice.toFixed(futuresDecimalNumbers[item.symbol])) }}</td>
              <td class="text-right" :class="item.beFixedStopLossPrice ? '' : 'grey--text'">{{ 0 == item.fixedStopLossPrice ? "--" : formatNumber(item.fixedStopLossPrice.toFixed(futuresDecimalNumbers[item.symbol])) }}</td>
              <td class="text-right">{{ item.leverage }}</td>
              <td><span v-status="item.status"></span></td>
              <td class="px-0 mx-0">{{ item.timestamp }}</td>
              <td :style="{color: getColorByStrategy(item.strategy)}">{{ item.strategy }}</td>
              <td :class="item.bgColor">{{ item.category }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
       <v-divider></v-divider>
      <v-row class="pb-5" v-if="futuresOrderHistoryTotalElements > 0">
        <v-col cols="auto" class="pl-0">
          <v-pagination color="lightred" circle prev-icon="mdi-menu-left" next-icon="mdi-menu-right" v-model="futuresOrderHistoryPageNo" :length="futuresOrderHistoryTotalPage" :total-visible="15"  @input="getFuturesOrderHistoryByPages" class="ml-2 mt-3"></v-pagination>
        </v-col>
        <v-col cols="auto" class="mt-5 pt-5">
          {{$t("log.totalNumber") + formatNumber(futuresOrderHistoryTotalElements)}}
        </v-col>
        
        <v-col cols="auto">
          <v-btn outlined tile class="ma-1" small @click="exportTradeLog()" :loading="tradingLoading">
            <v-icon small class="mr-1">mdi-download</v-icon>{{$t("log.downloadTradingLogs")}}
          </v-btn>
          <v-btn outlined tile class="ma-1" small @click="downloadOkexLedger()" :loading="okexLedgerLoading">
            <v-icon small class="mr-1">mdi-download</v-icon>{{$t("log.okexLedger")}}
          </v-btn>
          <v-btn outlined tile class="ma-1" small @click="exPortorCurrent()" :loading="exportorLoading">
            <v-icon small class="mr-1">mdi-download</v-icon>{{$t("log.downloadCurrent")}}
          </v-btn>
        </v-col>
      </v-row>
    </v-col>
  </div>
</template>

<script>
  import Vue from 'vue';
  import config from '@/config.js';
  import { mapGetters } from "vuex";
  import CsvExportor from "csv-exportor";
  import axios from 'axios'

  export default {
    components: {

    },
    data() {
      return {
        futuresOrderHistoryHeaders:[
          { text:this.$t("log.exchange"), sortable:false},
          { text:this.$t("log.futuresOrderId"), sortable:false},
          { text:this.$t("log.exchangeOrderId"), sortable:false},
          { text:this.$t("log.symbol"), sortable:false},
          { text:this.$t("log.side"), sortable:false},
          { text:this.$t("log.type"), sortable:false},
          { text:this.$t("log.size"), sortable:false, align:'right'},
          { text:this.$t("log.price"), sortable:false, align:'right'},
          { text:this.$t("log.profit"), sortable:false, align:'right'},
          { text:this.$t("log.loss1"), sortable:false, align:'right'},
          { text:this.$t("log.loss"), sortable:false, align:'right'},
          { text:this.$t("log.fixedStopLoss"), sortable:false, align:'right'},
          { text:this.$t("log.leverage"), sortable:false, align:'right'},
          { text:this.$t("log.status"), sortable:false},
          { text:this.$t("log.time"), sortable:false},
          { text:this.$t("common.strategy"), sortable:false},
          { text:this.$t("common.category"), sortable:false}
        ],
        pageSize:30,
        futuresOrderLoading:false,
        exportorLoading:false,
        tradingLoading:false,
        okexLedgerLoading:false,
        futuresOrderHistory:[],
        futuresOrderHistoryTotalElements:0,
        futuresOrderHistoryTotalPage:0,
        futuresOrderHistoryPageNo:1,
        searchForm:{
          exchange:null,
          symbol:null,
          side:null,
          futuresOrderId:null,
        }
      }
    },
    created (){

    },

    destroyed(){

    },
    watch:{
      
    },
    mounted() {
     this.getFuturesOrderHistoryByPages(1);
    },
    computed:{
      ...mapGetters(['user', 'exchanges', 'futuresSymbols', 'orderSides', 'futuresDecimalNumbers', 'time', 'responsive', 'strategyColors']),
    },
    methods: {
      // 搜索
      searchFuturesOrderHistory() {
        this.futuresOrderHistory = [];
        this.futuresOrderHistoryPageNo = 1;
        this.futuresOrderHistoryTotalElements = 0;
        this.futuresOrderHistoryTotalPage = 0;
        this.getFuturesOrderHistoryByPages(1);
      },
      // 分页查询期货历史交易记录
      getFuturesOrderHistoryByPages(futuresOrderHistoryPageNo) {
        this.futuresOrderLoading = true;
        this.futuresOrderHistory = [];
        let api = config.baseUrl + '/order/futures/private/history?pageNo=' + (futuresOrderHistoryPageNo - 1) +"&pageSize=" + this.pageSize;
        if (this.searchForm.exchange != null && this.searchForm.exchange != '') {
          api = api + '&exchange=' + this.searchForm.exchange;
        }
        if (this.searchForm.symbol != null && this.searchForm.symbol != '') {
          api = api + '&symbol=' + this.searchForm.symbol;
        }
        if (this.searchForm.side != null && this.searchForm.side != '') {
          api = api + '&side=' + this.searchForm.side;
        }
        if (this.searchForm.futuresOrderId != null && this.searchForm.futuresOrderId != '') {
          api = api + '&futuresOrderId=' + this.searchForm.futuresOrderId;
        }
        this.$http.get(api).then(response => {
          let data = response.data;
          if (data.code == 200) {
            this.futuresOrderHistory = data.result.futuresOrderHistory;
            // 将交易所id转为名字
            for (let i = 0, len = this.futuresOrderHistory.length; i < len; i++) {
              this.futuresOrderHistory[i].exchangeName = this.getExchangeNameById(this.futuresOrderHistory[i].exchangeId);
              this.futuresOrderHistory[i].timestamp = this.formatDate(this.futuresOrderHistory[i].timestamp);
              switch (this.futuresOrderHistory[i].category) {
                case 'IOB':
                  this.futuresOrderHistory[i].bgColor = 'palered--text';
                  break;
                case 'TA':
                  this.futuresOrderHistory[i].bgColor = 'palegreen--text';
                  break;
                case 'MR':
                  this.futuresOrderHistory[i].bgColor = 'paleyellow--text';
                  break;
                case 'TP':
                  this.futuresOrderHistory[i].bgColor = 'red--text';
                  break;
                default:
                  this.futuresOrderHistory[i].bgColor = '';
                  break;
              }
            }
            this.futuresOrderHistoryTotalElements = data.result.totalElements;
            this.futuresOrderHistoryTotalPage = Math.ceil(data.result.totalElements / this.pageSize);
            this.futuresOrderLoading = false;
          } else {
            this.futuresOrderHistory = [];
            this.futuresOrderHistoryTotalElements = 0;
            this.futuresOrderLoading = false;
          }
        })
      },
      // 下载的方法
      exPortorCurrent(){
        this.exportorLoading = true;
        var heads = ["id","exchangeName","futuresOrderId","exchangeOrderId","symbol","side","orderType","price","size","renkoEntryMultiplier","renkoExitMultiplier",
          "takeProfitPrice","stopLossPrice1","stopLossPrice",
          "fixedStopLossPrice","beFixedStopLossPrice","trailingStopLevel1","trailingStopLevel2","trailingStopLevel3",
          "priceLevel2","priceLevel3","leverage","strategy","category","status","timestamp","sendOrderStartTimestamp","sendOrderEndTimestamp",
          "takeProfitStartTimestamp","takeProfitEndTimestamp","stopLoss1StartTimestamp","stopLoss1EndTimestamp","stopLossStartTimestamp","stopLossEndTimestamp"
          ,"fixedStopLossStartTimestamp","fixedStopLossEndTimestamp"];
        var title = ["Id","Exchange","Future Order Id","Exchange Order Id","Symbol","Side","Type","Price","Size","Renko Entry Multiplier","Renko Exit Multiplier",
          "Take Profit Price","Stop Loss Price 1","Stop Loss Price",
          "Fixed Stop Loss","Be Fixed Stop Loss Price","Trailing Stop Level 1","Trailing Stop Level 2","Trailing Stop Level 3",
          "Price Level 2","Price Level 3",":Leverage","Strategy","Category","Status","Time","Send Order Start Time","Send Order End Time",
          "Take Profit Start Time","Take Profit End Time","Stop Loss 1 Start Time","Stop Loss 1 End Time","Stop Loss Start Time","Stop Loss End Time",
          "Fixed Stop Loss Start Time","Fixed Stop Loss End Time"];
        var body =[];
        for( var i = 0 ; i < this.futuresOrderHistory.length ; i++){
          var line = [];
          // let result = heads.map(item => ({key:item, value:this.spotOrderHistory[i][item]}));
          heads.map(item=>{
            // 处理时间
            if( item.indexOf("Timestamp") != -1 ){
              if(this.futuresOrderHistory[i][item] == undefined || this.futuresOrderHistory[i][item] == null){
                line.push("--");
              }else if( this.futuresOrderHistory[i][item] == -1 ){
                line.push("-1");
              }else if( this.futuresOrderHistory[i][item] == 0 ){
                line.push("--");
              }else{
                line.push(this.formatDate(this.futuresOrderHistory[i][item]));
              }
            }
            if( item.indexOf("Timestamp") == -1 ){
              // 处理无值的
              if(this.futuresOrderHistory[i][item] == undefined){
                line.push("--");
              }else{
                if(this.futuresOrderHistory[i][item] == 0){
                  line.push("--");
                }else{
                  // size的处理
                  if(item.indexOf("size") != -1){
                    if(this.futuresOrderHistory[i]["side"] == "open_long" || this.futuresOrderHistory[i]["side"] == "close_buy"){
                      line.push(this.futuresOrderHistory[i][item]);
                    }else{
                      line.push("-"+this.futuresOrderHistory[i][item]);
                    }
                  }else{
                    line.push(this.futuresOrderHistory[i][item]);
                  }
                }
              }
            }
          })
          body.push(line);
        }
        CsvExportor.downloadCsv(body, { header: title }, 'Futures-' +this.user.username + '.csv');
        this.exportorLoading = false;
      },
      // 下载okex交易流水
      downloadOkexLedger(){
        this.okexLedgerLoading = true;
        let api = config.baseUrl + '/exchange/okex/futures/private/downloadLedger';
        axios({
          method: 'get',
          url: api,
          responseType: 'blob',
          headers:{
            Authorization: 'Bearer '+ this.$store.getters.user.token
          }
        }).then((res)=>{
          if(res.headers["content-disposition"] == undefined){
            this.okexLedgerLoading = false;
            this.$store.dispatch("snackbarMessageHandler","暂无数据");
          }else{
            const fileName = res.headers["content-disposition"].split("=")[1];
            const _res = res.data;
            let blob = new Blob([_res]);
            let downloadElement = document.createElement("a"); // a标签
            let href = window.URL.createObjectURL(blob); //创建下载的链接
            downloadElement.href = href;
            downloadElement.download = fileName; //下载后文件名
            document.body.appendChild(downloadElement);
            downloadElement.click(); //点击下载
            document.body.removeChild(downloadElement); //下载完成移除元素
            window.URL.revokeObjectURL(href); //释放掉blob对象
            this.okexLedgerLoading = false;
          }
        })
      },
      // 下载交易日志
      exportTradeLog(){
        this.tradingLoading = true;
        let api = config.baseUrl + '/order/futures/private/getTradeFile';
        // this.$http.get(api,{
        //  responseType: "arraybuffer"}).then()
        axios({
          method: 'get',
          url: api,
          // params: data,
          responseType: 'blob',
          headers:{
            Authorization: 'Bearer '+ this.$store.getters.user.token
          }
        }).then((res)=>{
          const fileName = res.headers["content-disposition"].split("=")[1];
          const _res = res.data;
          let blob = new Blob([_res]);
          let downloadElement = document.createElement("a"); // a标签
          let href = window.URL.createObjectURL(blob); //创建下载的链接
          downloadElement.href = href;
          downloadElement.download = fileName; //下载后文件名
          document.body.appendChild(downloadElement);
          downloadElement.click(); //点击下载
          document.body.removeChild(downloadElement); //下载完成移除元素
          window.URL.revokeObjectURL(href); //释放掉blob对象
          this.tradingLoading = false;
        })
      },
      // 交易所id转为交易所name
      getExchangeNameById(id) {
        return getExchangeNameById(this.exchanges, id);
      },
      // 格式化日期
      formatDate(timestamp) {
        if ('localTime' == this.time) {
          return formatTime(timestamp);
        } else {
          return formatUTCTime(timestamp);
        }
      },
      // 格式化数字
      formatNumber(num) {
        return formatNumber(num)
      },
      // 获取现货小数位
      getDecimalPlace(exchange, symbol) {
        if (exchange == null || symbol == null) {
          return 8;
        }
      },
      // 根据策略查询颜色
      getColorByStrategy(strategy){
        return this.strategyColors[strategy];
      },
    }
  }

</script>

<style>

</style>
