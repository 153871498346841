<template>
<div>
    <div class="pa-0 px-4 py-4 black">
        <v-row class="pl-6">
            <v-col cols="12" lg="1" md="12">
            </v-col>
            <v-col cols="12" lg="3" md="12">
                <span class="pointer white-hover">
                    <a :href="domain" class="del-underline">
                        <!-- <logo type="fiveLines" color="lightred"></logo> -->
                        <logo type="threeLines" color="iobots"></logo>
                    </a>
                </span>
            </v-col>
            <v-col cols="6" md="6" class="mb-2 align-self-md-center" v-if="responsive =='mobile'">
                <v-select dark v-model="languageModel" class="white-hover fs-12" :items="languageItems" prepend-inner-icon="mdi-google-translate" :menu-props="{ openOnHover: true }" item-text="text" item-value="value" dense tile hide-details @input="changeLanguage"></v-select>
            </v-col>
            <v-col cols="12" lg="1" md="12">
                <div class="white--text">{{ $t('header.index') }}</div><br />
                <div class="title_txt white-hover"><a class="fs-16" @click="goToSyntheticIndices">{{ $t('footer.syntheticIndices') }}</a></div><br />
            </v-col>
            <v-col cols="12" lg="1" md="12">
                <div class="white--text">{{ $t('header.indicators') }}</div><br />
                <div class="title_txt white-hover"><a class="fs-16" @click="goToMarketTypes">{{ $t('header.marketTypes') }}</a></div><br />
            </v-col>
            <v-col cols="12" lg="1" md="12">
                <div class="white--text">{{ $t('footer.performance') }}</div><br />
                <div class="title_txt white-hover"><a class="fs-16" @click="goToTradingSignalResults">{{ $t('footer.tradingSignalResults') }}</a></div><br />
                <div class="title_txt white-hover"><a class="fs-16" @click="goToSignalsHistory">{{ $t('footer.signalHistory') }}</a></div><br />
                <div class="title_txt white-hover"><a class="fs-16" @click="goToDefyHedgedPool">{{ $t('header.defyHedgedPool') }}</a></div><br />
                <div class="title_txt white-hover"><a class="fs-16" @click="goToQuasarFund">{{ $t('footer.quasarFund') }}</a></div><br />
                <div class="title_txt white-hover"><a class="fs-16" @click="goToModelPortfolio">{{ $t('footer.modelPortfolio') }}</a></div><br />
            </v-col>
            <v-col cols="12" lg="1" md="12">
                <div class="white--text fs-18">{{ $t('footer.trading') }}</div><br />
                <div class="title_txt white-hover"><a class="fs-16" @click="goToSpot">{{ $t('footer.spot') }}</a></div><br />
                <div class="title_txt white-hover"><a class="fs-16" @click="goToFutures">{{ $t('footer.coinSwap') }}</a></div><br />
                <div class="title_txt white-hover"><a class="fs-16" @click="goToSwapUsdt">{{ $t('footer.usdtSwap') }}</a></div><br />
            </v-col>
            <v-col cols="12" lg="1" md="12">
                <div class="white--text fs-18">{{ $t('footer.strategies') }}</div><br />
                <div class="title_txt white-hover"><a class="fs-16 pointer white-hover" @click="goToStrategy">{{ $t('footer.settings') }}</a></div><br />
            </v-col>
            <!-- API只在开发环境显示 -->
            <v-col cols="12" lg="1" md="12" v-if="environment == 'Development'">
                <div class="white--text fs-18">{{ $t('footer.service') }}</div><br />
                <div class="title_txt white-hover">
                    <span class="fs-16">
                        <a :href="apiUrl" target="_blank" class="del-underline">API</a>
                    </span>
                </div><br />
            </v-col>
        </v-row>
        <v-row class="pl-11 pb-16">
            <v-col cols="12" offset-md="1">
                <div class="fs-8 grey--text">
                    {{ version }}
                </div>
            </v-col>
        </v-row>
    </div>
    <div>
        <footer v-if="responsive =='PC'">
            <v-card flat class="px-4 py-0 my-0" tile>
                <v-row class="pl-12" dense>
                    <v-col cols="12" md="1">
                    </v-col>
                    <v-col cols="12" md="9" class="align-self-md-center primary--text">
                        <span class="mr-3 fs-8">IOB © 2020 - 2021</span>
                        <span class="title_txt white-hover">
                            <a :href="sip+'/#/about/privacy'" target="_blank" class="fs-8 mr-3 del-underline">{{$t("footer.privacyLegal")}}</a>
                        </span>
                        <span class="title_txt white-hover">
                            <a :href="iobots+'/about/terms'" target="_blank" class="fs-8 mr-3 del-underline">{{$t("footer.terms")}}</a>
                        </span>
                        <span class="title_txt white-hover">
                            <a :href="iobots" target="_blank" class="fs-8 mr-3 del-underline footer-about">ioBots</a>
                        </span>
                        <span class="title_txt white-hover">
                            <a :href="sip" target="_blank" class="fs-8 mr-3 del-underline footer-about">SiP</a>
                        </span>
                        <span class="title_txt white-hover">
                            <a :href="sip+'/#/about'" target="_blank" class="fs-8 mr-3 del-underline footer-about">{{$t("footer.about")}}</a>
                        </span>
                        <span class="title_txt white-hover">
                            <a :href="sip+'/#/ir'" target="_blank" class="fs-8 mr-3 del-underline footer-about">{{$t("footer.investors")}}</a>
                        </span>
                        <span class="title_txt white-hover">
                            <a :href="sip+'/#/about/contact'" target="_blank" class="fs-8 mr-3 del-underline footer-about">{{$t("footer.contact")}}</a>
                        </span>
                        <span class="title_txt white-hover">
                            <a :href="sip+'/#/about/frequently-asked-questions'" target="_blank" class="fs-8 mr-3 del-underline footer-about">{{$t("footer.faq")}}</a>
                        </span>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col cols="12" md="1" class="mb-2 align-self-md-center">
                        <v-select v-model="languageModel" class="white-hover fs-8" :items="languageItems" prepend-inner-icon="mdi-google-translate" :menu-props="{ openOnHover: true }" item-text="text" item-value="value" dense tile hide-details @input="changeLanguage"></v-select>
                    </v-col>
                </v-row>
            </v-card>
        </footer>
    </div>
</div>
</template>

<style scoped>

</style>

<script>
import Vue from 'vue';
import config from '@/config.js';
import { mapGetters } from "vuex";
import Logo from '@/components/common/Logo';
export default {
    data() {
        return {
            domain: config.domainName,
            iobots: config.domainName,
            environment: config.environment,
            sip: config.sip,
            // 当前的语言环境
            languageModel: this.$store.getters.locale,
            languageItems: [{
                    text: 'English',
                    value: 'en-US'
                },
                {
                    text: '简体中文',
                    value: 'zh-CN'
                }
            ],
            // 当前的颜色
            colorModel: this.$store.getters.selectedTheme.bgColor,
            colorItems: [{
                    text: this.$t('menu.upGreen'),
                    value: 'greenUpRedDown'
                },
                {
                    text: this.$t('menu.upRed'),
                    value: 'greenDownRedUp'
                }
            ],
        }
    },
    components: {
        Logo
    },
    created() {

    },
    mounted() {

    },
    computed: {
        ...mapGetters(["user", "themes", "responsive", "version", "defaultExchange"]),
        apiUrl(){
            if (config.environment == 'Development') {
                return config.baseUrl + '/swagger-ui.html';
            } else {
                return config.domainName + '/iobots/swagger-ui.html';
            }
        }
    },
    watch: {

    },
    methods: {
        // 跳转到登录页面
        gotoSignIn() {
            this.$store.dispatch("snackbarMessageHandler", this.$t("header.signInMessage"));
            this.$router.push({
                name: "signIn"
            });
            this.$store.dispatch("signalHeaderTitleHandler", 'signals');
            this.$store.dispatch("tradingHeaderTitleHandler", 'trading');
            this.$store.dispatch("performanceHeaderTitleHandler", 'performance');
        },
        // 跳转Signals
        goToSignals() {
            if (this.user.token) {
                this.goToMoreSignals();
            } else {
                this.$router.push({ name: "signals" });
                this.$store.dispatch("signalHeaderTitleHandler", 'signals');
                this.$store.dispatch("tradingHeaderTitleHandler", 'trading');
                this.$store.dispatch("performanceHeaderTitleHandler", 'performance');
            }
        },
        goToMoreSignals() {
            this.$router.push({ name: "moreSignals" });
            this.$store.dispatch("indicatorsHeaderTitleHandler", 'marketTypes');
            this.$store.dispatch("signalHeaderTitleHandler", 'signals');
            this.$store.dispatch("tradingHeaderTitleHandler", 'trading');
            this.$store.dispatch("performanceHeaderTitleHandler", 'performance');
        },
        // 跳转到SignalsHistory
        goToSignalsHistory() {
            this.$router.push({ name: "signalHistory", params: { symbol: "BTCUSD" } });
            this.$store.dispatch("indicatorsHeaderTitleHandler", 'indicators');
            this.$store.dispatch("signalHeaderTitleHandler", 'signals');
            this.$store.dispatch("tradingHeaderTitleHandler", 'trading');
            this.$store.dispatch("performanceHeaderTitleHandler", 'signalsHistory');
        },
        // 跳转到Model Portfolio
        goToModelPortfolio() {
            this.$router.push({ name: "modelPortfolio" });
            this.$store.dispatch("indicatorsHeaderTitleHandler", 'indicators');
            this.$store.dispatch("signalHeaderTitleHandler", 'signals');
            this.$store.dispatch("tradingHeaderTitleHandler", 'trading');
            this.$store.dispatch("performanceHeaderTitleHandler", 'modelPortfolio');
        },
        // 跳转到Quasar Fund
        goToQuasarFund() {
            this.$router.push({ name: "quasarFund" });
            this.$store.dispatch("indicatorsHeaderTitleHandler", 'indicators');
            this.$store.dispatch("signalHeaderTitleHandler", 'signals');
            this.$store.dispatch("tradingHeaderTitleHandler", 'trading');
            this.$store.dispatch("performanceHeaderTitleHandler", 'quasarFund');
        },
        // 跳转到Defy
        goToDefyHedgedPool(){
            this.$router.push({name: "defyHedgedPool"});
            this.$store.dispatch("indicatorsHeaderTitleHandler", 'indicators');
            this.$store.dispatch("signalHeaderTitleHandler", 'signals');
            this.$store.dispatch("tradingHeaderTitleHandler", 'trading');
            this.$store.dispatch("performanceHeaderTitleHandler", 'defyHedgedPool');
        },
        // 跳转到Spot
        goToSpot() {
            if (this.user.token) {
                if (this.user.role == "TRADER") {
                    this.$router.push({
                        name: "spotTrading",
                        params: {
                            exchangeCode: this.defaultExchange,
                            symbol: "BTC-USDT"
                        }
                    });
                    this.$store.dispatch("signalHeaderTitleHandler", 'signals');
                    this.$store.dispatch("tradingHeaderTitleHandler", 'spot');
                    this.$store.dispatch("performanceHeaderTitleHandler", 'performance');
                } else {
                    this.$store.dispatch("snackbarMessageHandler", this.$t("header.snackbarMessage"));
                }
            } else {
                this.gotoSignIn();
            }
        },
        // 跳转到goToFutures
        goToFutures() {
            if (this.user.token) {
                if (this.user.role == "TRADER") {
                    this.$router.push({
                        name: "futuresTrading",
                        params: {
                            exchangeCode: this.defaultExchange,
                            symbol: "PI_BTCUSD"
                        }
                    });
                    this.$store.dispatch("signalHeaderTitleHandler", 'signals');
                    this.$store.dispatch("tradingHeaderTitleHandler", 'futures');
                    this.$store.dispatch("performanceHeaderTitleHandler", 'performance');
                } else {
                    this.$store.dispatch("snackbarMessageHandler", this.$t("header.snackbarMessage"));
                }
            } else {
                this.gotoSignIn();
            }
        },
        // 跳转到SwapUsdt
        goToSwapUsdt() {
            if (this.user.token) {
                if (this.user.role == "TRADER") {
                    this.$router.push({
                        name: "swapUsdtTrading",
                        params: {
                            exchangeCode: this.defaultExchange,
                            symbol: "BTC-USDT-SWAP"
                        }
                    });
                    this.$store.dispatch("signalHeaderTitleHandler", 'signals');
                    this.$store.dispatch("tradingHeaderTitleHandler", 'swapUsdt');
                    this.$store.dispatch("performanceHeaderTitleHandler", 'performance');
                } else {
                    this.$store.dispatch("snackbarMessageHandler", this.$t("header.snackbarMessage"));
                }
            } else {
                this.gotoSignIn();
            }
        },
        // 跳转到账户
        goToAccount() {
            if (this.user.token) {
                this.$router.push({
                    name: 'account'
                });
            } else {
                this.gotoSignIn();
            }
        },
        // 切换语言
        changeLanguage(locale) {
            console.log(locale)
            this.$i18n.locale = locale;
            this.$store.dispatch("localeHandler", locale);
            if (this.user != null && this.user.token != null) {
                // 登录状态下，将语言环境更新到用户属性
                let api = config.baseUrl + '/user/private/updateLocale?locale=' + locale;
                this.$http.put(api).then(response => {
                    let data = response.data;
                    if (data.code == 200) {
                        // 更新成功，刷新页面
                        this.$router.go();
                    } else {
                        // 更新失败，提示信息
                        this.$store.dispatch("snackbarMessageHandler", data.message);
                    }
                }, error => {
                    this.$store.dispatch("snackbarMessageHandler", this.$t("common.oops"));
                });
            } else {
                // 未登录直接刷新
                this.$router.go();
            }
        },
        // 跳转到策略
        goToStrategy() {
            if (this.user.token) {
                if (this.user.role == "TRADER") {
                    this.$router.push({
                        name: "strategy"
                    });
                    this.initHeaderTitle();
                } else {
                    this.$store.dispatch("snackbarMessageHandler", this.$t("header.snackbarMessage"));
                }
            } else {
                this.gotoSignIn();
            }
        },
        // 初始化标题
        initHeaderTitle() {
            this.$store.dispatch("signalHeaderTitleHandler", 'signals');
            this.$store.dispatch("tradingHeaderTitleHandler", 'trading');
            this.$store.dispatch("performanceHeaderTitleHandler", 'performance');
        },
        changeTheme(bgColor) {
            let theme = null;
            for (let i = 0, len = this.themes.length; i < len; i++) {
                if (this.themes[i].bgColor == bgColor) {
                    theme = this.themes[i];
                }
            }
            this.$store.dispatch("changeThemeHandler", theme);
            this.$store.dispatch("changeColorStyleHandler", theme.bgColor);
            this.$vuetify.theme = theme.theme;
            this.$router.go();
        },
        // 跳转到MarketTypes
        goToMarketTypes(){
            this.$router.push({name: "marketTypes"});
            this.$store.dispatch("indicatorsHeaderTitleHandler", 'marketTypes');
            this.$store.dispatch("signalHeaderTitleHandler", 'signals');
            this.$store.dispatch("tradingHeaderTitleHandler", 'trading');
            this.$store.dispatch("performanceHeaderTitleHandler", 'performance');
        },
        goToSyntheticIndices(){
        this.$router.push({name: "syntheticIndices"});
        this.$store.dispatch("indicatorsHeaderTitleHandler", 'indicators');
        this.$store.dispatch("signalHeaderTitleHandler", 'signals');
        this.$store.dispatch("tradingHeaderTitleHandler", 'trading');
        this.$store.dispatch("performanceHeaderTitleHandler", 'performance');
        this.$store.dispatch("indexHeaderTitleHandler", 'syntheticIndices');
      },
      goToTradingSignalResults(){
        this.$router.push({name: "tradingSignalResults"});
        this.$store.dispatch("indicatorsHeaderTitleHandler", 'indicators');
        this.$store.dispatch("signalHeaderTitleHandler", 'signals');
        this.$store.dispatch("tradingHeaderTitleHandler", 'trading');
        this.$store.dispatch("performanceHeaderTitleHandler", 'tradingSignalResults');
      },
    }
}
</script>
