<template>
  <span>
      <span v-if="symbol == 'BTCUSD'||symbol == 'BTCUSDT'||symbol == 'PI_BTCUSD' || symbol == 'BTC-USDT-SWAP'">
        <i class="icon icon-BTC"></i>
      </span>
      <span v-if="symbol == 'ETHUSD'||symbol == 'ETHBTC'||symbol == 'ETHUSDT'||symbol == 'PI_ETHUSD' || symbol == 'ETH-USDT-SWAP'">
       <i class="icon icon-ETH"></i>
      </span>
      <span v-if="symbol == 'BCHUSD'||symbol == 'BCHBTC'||symbol == 'BCHUSDT'||symbol == 'PI_BCHUSD' || symbol == 'BCH-USDT-SWAP'">
        <i class="icon icon-BCH"></i>
      </span>
      <span v-if="symbol == 'LTCUSD'||symbol == 'LTCBTC'||symbol == 'LTCUSDT'||symbol == 'PI_LTCUSD' || symbol == 'LTC-USDT-SWAP'">
        <i class="icon icon-ltc1"></i>
      </span>
      <span v-if="symbol == 'XRPUSD'||symbol == 'XRPBTC'||symbol == 'XRPUSDT'||symbol == 'PI_XRPUSD' || symbol == 'XRP-USDT-SWAP'">
        <i class="icon icon-xrp"></i>
      </span>
      <span v-if="symbol == 'EOSUSD'||symbol == 'EOSBTC'||symbol == 'EOSUSDT'||symbol == 'PI_EOSUSD' || symbol == 'EOS-USDT-SWAP'">
        <i class="icon icon-eos"></i>
      </span>
      <span v-if="symbol == 'ETCUSD'||symbol == 'ETCBTC'||symbol == 'ETCUSDT'||symbol == 'PI_ETCUSD' || symbol == 'ETC-USDT-SWAP'">
       <i class="icon icon-ETC"></i>
      </span>
      <span v-if="symbol == 'BSVUSD'||symbol == 'BSVBTC'||symbol == 'BSVUSDT'||symbol == 'PI_BSVUSD' || symbol == 'BSV-USDT-SWAP'">
        <i class="icon icon-bsv"></i>
      </span>
      <span v-if="symbol == 'TRXUSD'||symbol == 'TRXBTC'||symbol == 'TRXUSDT'||symbol == 'PI_TRXUSD' || symbol == 'TRX-USDT-SWAP'">
        <i class="icon icon-trx"></i>
      </span>
      <span v-if="symbol == 'OKBUSDT'||symbol == 'OKBBTC'">
        <i class="icon icon-okb">
          <span class="icon-okb"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span></span>
        </i>     
      </span>
      <span v-if="symbol == 'DASHUSD'||symbol == 'DASHUSDT'||symbol == 'DASHBTC'||symbol == 'PI_DASHUSD' || symbol == 'DASH-USDT-SWAP'">
       <i class="icon icon-dash"></i>
      </span>
      <span v-if="symbol == 'NEOUSD'||symbol == 'NEOUSDT'||symbol == 'NEOBTC'||symbol == 'PI_NEOUSD' || symbol == 'NEO-USDT-SWAP'">
        <i class="icon icon-neo"></i>
      </span>
      <span v-if="symbol == 'QTUMUSD'||symbol == 'QTUMUSDT'||symbol == 'QTUMBTC'||symbol == 'PI_QTUMUSD' || symbol == 'QTUM-USDT-SWAP'">
         <i class="icon icon-qtum1"></i>
      </span>
      <span v-if="symbol == 'XTZUSD' || symbol == 'XTZUSDT'||symbol == 'XTZBTC'||symbol == 'PI_XTZMUSD'||symbol == 'XTZ-USDT-SWAP'">
        <i class="icon icon-xtz"></i>
      </span>
      <span v-if="symbol == 'ATOMUSD' || symbol == 'ATOMUSDT'||symbol == 'ATOMBTC'||symbol == 'PI_ATOMUSD'||symbol == 'ATOM-USDT-SWAP'">
        <i class="icon icon-atom"></i>
      </span>
      <span v-if="symbol == 'ADAUSD' || symbol == 'ADAUSDT'||symbol == 'ADABTC'||symbol == 'PI_ADAUSD'||symbol == 'ADA-USDT-SWAP'">
         <i class="icon icon-ada"></i>
      </span>
      <span v-if="symbol == 'XLMUSD' || symbol == 'XLMUSDT'||symbol == 'XLMBTC'||symbol == 'PI_XLMUSD'||symbol == 'XLM-USDT-SWAP'">
        <i class="icon icon-xlm"></i>
      </span>
      <span v-if="symbol == 'LINKUSD'||symbol == 'PI_LINKUSD' || symbol == 'LINK-USDT-SWAP'">
         <i class="icon icon-chainlink"></i>
      </span>
      <span v-if="symbol == 'ONTUSD' || symbol == 'PI_ONTUSD' || symbol == 'ONT-USDT-SWAP'">
        <i class="icon icon-ont1"></i>
      </span>
      <span v-if="symbol == 'ZECUSD' || symbol == 'PI_ZECUSD' || symbol == 'ZEC-USDT-SWAP'">
        <i class="icon icon-zec"></i>
      </span>
      <span v-if="symbol == 'IOSTUSD' || symbol == 'PI_IOSTUSD' || symbol == 'IOST-USDT-SWAP'">
        <i class="icon icon-iost"></i>
      </span>
      <span v-if="symbol == 'ALGOUSD' || symbol == 'PI_ALGOUSD' || symbol == 'ALGO-USDT-SWAP'">
        <i class="icon icon-algo1"></i>
      </span>
	  <span v-if="symbol == 'UNIUSD' || symbol == 'PI_UNIUSD' || symbol == 'UNI-USDT-SWAP'">
	    <i class="icon icon-uni"></i>
	  </span>
      <span v-if="symbol == 'GOLD'">
        <i class="icon icon-gold">
          <span class="icon-gold"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span><span class="path9"></span><span class="path10"></span><span class="path11"></span><span class="path12"></span><span class="path13"></span><span class="path14"></span></span>
        </i>
      </span>
      <span v-if="symbol == 'MSFT'">
        <i class="icon icon-microsoft-5">
         <span class="icon-microsoft-5"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span></span>
        </i>
      </span>
      <span v-if="symbol == 'TSLA'">
        <i class="icon-tesla">
        </i>
      </span>
      <span v-if="symbol == 'DOTUSD' || symbol == 'PI_DOTUSD' || symbol == 'DOT-USDT-SWAP'">
        <i class="icon icon-dot">
          <span class="icon-dot"><span class="path1"></span><span class="path2"></span></span>
        </i>
      </span>
      <span v-if="symbol == 'DEFY' && color == 'black'">
          <i class="icon icon-v25">
          </i>
      </span>
      <span v-if="symbol == 'DEFY' && color == 'white'">
          <span class="icon-v28"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span><span class="path9"></span><span class="path10"></span><span class="path11"></span><span class="path12"></span><span class="path13"></span><span class="path14"></span><span class="path15"></span><span class="path16"></span><span class="path17"></span><span class="path18"></span><span class="path19"></span></span>
      </span>
  </span>
</template>
<style>

</style>
<script>
  import Vue from 'vue'
  export default {
    data() {
      return {}
    },
    created() {

    },
    mounted() {

    },
    props: [
      'symbol','color'
    ],
    watch: {

    },
    methods: {

    }
  }

</script>
