const state = {
	isShowSnackbar: false,										// 是否显示提示信息
  	snackbarMessage: null,										// 提示信息
	notificationNum: 0,											// 消息通知数量
	documentTitle: 'ioBots - Trading with rule-based algos™"',	// 浏览器标题
	signalHeaderTitle: 'signals',								// 信号头部标题
	tradingHeaderTitle: 'trading',								// 交易头部标题
	performanceHeaderTitle:'performance',						// 业绩头部标题	
	indicatorsHeaderTitle: 'indicators',						// 指标头部标题
	indexHeaderTitle: 'index',									// 指数头部标题
}

const getters = {
	isShowSnackbar: state => {
		return state.isShowSnackbar;
	},
	snackbarMessage: state => {
		return state.snackbarMessage;
	},
	notificationNum: state => {
	    return state.notificationNum;
	},
	documentTitle: state => {
	    return state.documentTitle;
	},
	signalHeaderTitle: state => {
	    return state.signalHeaderTitle;
	},
	tradingHeaderTitle: state => {
	    return state.tradingHeaderTitle;
	},
	performanceHeaderTitle: state => {
	    return state.performanceHeaderTitle;
	},
	indicatorsHeaderTitle: state => {
	    return state.indicatorsHeaderTitle;
	},
	indexHeaderTitle: state => {
	    return state.indexHeaderTitle;
	},
}

const actions = {
	isShowSnackbarHandler(context, payload){
		context.commit('isShowSnackbarHandler', payload);
	},
	snackbarMessageHandler(context, payload){
		context.commit('snackbarMessageHandler', payload);
	},
    notificationNumHandler(context, payload){
  		context.commit('notificationNumHandler', payload);
  	},
  	documentTitleHandler(context, payload){
  		context.commit('documentTitleHandler', payload);
  	},
    signalHeaderTitleHandler(context, payload){
  		context.commit('signalHeaderTitleHandler', payload);
  	},
    tradingHeaderTitleHandler(context, payload){
  		context.commit('tradingHeaderTitleHandler', payload);
  	},
	performanceHeaderTitleHandler(context, payload){
  		context.commit('performanceHeaderTitleHandler', payload);
  	},
  	indicatorsHeaderTitleHandler(context, payload){
		context.commit('indicatorsHeaderTitleHandler', payload);
	},
	indexHeaderTitleHandler(context, payload){
		context.commit('indexHeaderTitleHandler', payload);
	},
}

const mutations = {
	isShowSnackbarHandler(state, isShowSnackbar){
		state.isShowSnackbar = isShowSnackbar;
	},
	snackbarMessageHandler(state, snackbarMessage){
		if(snackbarMessage != null && snackbarMessage != ''){
			state.snackbarMessage = snackbarMessage;
			state.isShowSnackbar = true;
		}
	},
	notificationNumHandler(state, notificationNum){
		state.notificationNum = notificationNum;
	},
	documentTitleHandler(state, documentTitle){
		state.documentTitle = documentTitle;
		document.title = documentTitle;
	},
	signalHeaderTitleHandler(state, signalHeaderTitle){
		state.signalHeaderTitle = signalHeaderTitle;
	},
	tradingHeaderTitleHandler(state, tradingHeaderTitle){
		state.tradingHeaderTitle = tradingHeaderTitle;
	},
	performanceHeaderTitleHandler(state, performanceHeaderTitle){
		state.performanceHeaderTitle = performanceHeaderTitle;
	},
	indicatorsHeaderTitleHandler(state, indicatorsHeaderTitle){
		state.indicatorsHeaderTitle = indicatorsHeaderTitle;
	},
	indexHeaderTitleHandler(state, indexHeaderTitle){
		state.indexHeaderTitle = indexHeaderTitle;
	}

}

export default {
	state,
	getters,
	actions,
	mutations
}
