<template>
  <div>
    <v-col cols="12" md="7" offset-md="7" class="py-0" v-if="responsive == 'PC'">
      <v-row dense>
        <v-col cols="12" sm="6" md="2" xs="6">
          <v-select class="fs-12" dense v-model="searchForm.exchange" :items="exchanges" :label="$t('common.exchange')" item-text="name"
            item-value="code" clearable></v-select>
        </v-col>
        <v-col cols="12" sm="6" md="2">
          <v-select class="fs-12" dense v-model="searchForm.symbol" :items="swapUsdtSymbols" :label="$t('common.symbol')" clearable></v-select>
        </v-col>
        <v-col cols="12" sm="5" md="2">
          <v-select class="fs-12" dense v-model="searchForm.side" :items="orderSides" :label="$t('common.side')" clearable></v-select>
        </v-col>
        <v-col cols="12" sm="5" md="2">
          <v-text-field class="fs-12" dense v-model="searchForm.swapUsdtOrderId" :label="$t('log.swapUsdtOrderId')" clearable></v-text-field>
        </v-col>
        <v-col cols="12" sm="2" md="2">
          <v-btn icon @click="searchSwapUsdtOrderHistory">
              <v-icon>mdi-magnify</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" class="px-5" v-if="responsive == 'mobile'">
      <v-row dense>
        <v-col cols="12">
          <div class="d-custom-flex">
             <v-select class="fs-12 mr-5" dense v-model="searchForm.exchange" :items="exchanges" :label="$t('common.exchange')" item-text="name"
            item-value="code" clearable></v-select>
            <v-select class="fs-12" dense v-model="searchForm.symbol" :items="swapUsdtSymbols" :label="$t('common.symbol')" clearable></v-select>
          </div>
          <div class="d-custom-flex">
            <v-select class="fs-12 mr-5" dense v-model="searchForm.side" :items="orderSides" :label="$t('common.side')" clearable></v-select>
            <v-text-field class="fs-12" dense v-model="searchForm.swapUsdtOrderId" :label="$t('log.swapUsdtOrderId')" clearable></v-text-field>
            <v-btn class="float-right mt-2" x-small icon  @click="searchSwapUsdtOrderHistory">
            <v-icon>mdi-magnify</v-icon>
          </v-btn>
          </div>
        </v-col>
      </v-row> 
    </v-col>
    <v-col cols="12" md="12" class="pa-0 ma-0 pa-0 px-3" v-if="responsive == 'PC'">
        <v-row dense v-if="swapUsdtOrderLoading"> 
            <v-col cols="12">
                <v-skeleton-loader dense type="table-row-divider@30" class="mx-auto"></v-skeleton-loader>
            </v-col>
        </v-row>
      <v-data-table dense hide-default-footer :headers="swapUsdtOrderHistoryHeaders" :items="swapUsdtOrderHistory" v-if="!swapUsdtOrderLoading" :items-per-page="pageSize" disable-pagination>
        <template v-slot:body="{ items }">
          <tbody>
          <tr v-for="(item,index) in items" :key="index">
              <td :class="CommonJS.formatExchangeColor(item.exchangeName)">{{ item.exchangeName }}</td>
              <td class="px-0 mx-0">{{ null == item.swapUsdtOrderId ? item.id : item.swapUsdtOrderId }}</td>
              <td class="px-0 mx-0">{{ null == item.exchangeOrderId ? "--" : item.exchangeOrderId }}</td>
              <td>{{ item.symbol }}</td>
              <td :class="['text-left','open_long' == item.side || 'close_buy' == item.side  ? 'green--text':'red--text']">
                <span v-side="item.side"></span>
              </td>
              <td>{{ item.orderType }}</td>
              <td class="text-right">{{ (item.side == 'open_short'|| item.side == 'close_sell') ? '-' : '' }}{{ formatNumber(item.size) }}</td>
              <td class="text-right">{{ 0 == item.price ? "--" : formatNumber(item.price.toFixed(getSwapUsdtDecimalPlace(item.exchange, item.symbol))) }}</td>
              <td class="text-right">{{ 0 == item.takeProfitPrice ? "--" : formatNumber(item.takeProfitPrice.toFixed(getSwapUsdtDecimalPlace(item.exchange, item.symbol))) }}</td>
              <td class="text-right">{{ 0 == item.stopLossPrice1 ? "--" : formatNumber(item.stopLossPrice1.toFixed(getSwapUsdtDecimalPlace(item.exchange, item.symbol))) }}</td>
              <td class="text-right" :class="item.beFixedStopLossPrice ? 'grey--text' : ''">{{ 0 == item.stopLossPrice ? "--" : formatNumber(item.stopLossPrice.toFixed(getSwapUsdtDecimalPlace(item.exchange, item.symbol))) }}</td>
              <td class="text-right" :class="item.beFixedStopLossPrice ? '' : 'grey--text'">{{ 0 == item.fixedStopLossPrice ? "--" : formatNumber(item.fixedStopLossPrice.toFixed(getSwapUsdtDecimalPlace(item.exchange, item.symbol))) }}</td>
              <td class="text-right">{{ item.leverage }}</td>
              <td><span v-status="item.status"></span></td>
              <td class="px-0 mx-0">{{ item.timestamp }}</td>
              <td :style="{color: getColorByStrategy(item.strategy)}">{{ item.strategy }}</td>
              <td :class="item.bgColor">{{ item.category }}</td>
          </tr>
          <tr v-if="!swapUsdtOrderLoading && swapUsdtOrderHistoryTotalElements == 0">
              <td class="text-center" colspan="17">{{ $t('log.noDataAvailable')}}</td>
          </tr>
          </tbody>
        </template>
      </v-data-table>
       <v-divider v-if="!swapUsdtOrderLoading"></v-divider>
      <v-row class="pb-5" v-if="swapUsdtOrderHistoryTotalElements > 0">
       <v-col cols="auto" class="ml-0 pl-0">
          <v-pagination color="lightred" circle prev-icon="mdi-menu-left" next-icon="mdi-menu-right" v-model="swapUsdtOrderHistoryPageNo"
            :length="swapUsdtOrderHistoryTotalPage" :total-visible="15" @input="getSwapUsdtOrderHistoryByPages" class="ml-2 mt-3"
            v-if="swapUsdtOrderHistoryTotalElements > 0" :items-per-page="pageSize">
          </v-pagination>
        </v-col>
        <v-col cols="auto" class="mt-5 pt-5">
          {{$t("log.totalNumber") + formatNumber(swapUsdtOrderHistoryTotalElements)}}
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="12" sm="6" md="3" class="text-right mt-3">
          <v-btn outlined tile class="ma-2" small @click="exPortorCurrent()" :loading="exportorLoading">
            <v-icon small class="mr-2">mdi-download</v-icon>{{$t("log.downloadCurrent")}}
          </v-btn>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" md="12" class="pa-0 ma-0 pa-0 px-3" v-if="responsive == 'mobile'">
      <v-simple-table dense>
        <template v-slot:default>
          <thead>
            <tr>
              <th>{{$t("log.exchange")}}</th>
              <th class="px-0 mx-0">{{$t("log.swapUsdtOrderId")}}</th>
              <th class="px-0 mx-0">{{$t("log.exchangeOrderId")}}</th>
              <th>{{$t("log.symbol")}}</th>
              <th>{{$t("log.side")}}</th>
              <th>{{$t("log.type")}}</th>
              <th class="text-right">{{$t("log.size")}}</th>
              <th class="text-right">{{$t("log.price")}}</th>
              <th class="text-right">{{$t("log.profit")}}</th>
              <th class="text-right">{{$t("log.loss1")}}</th>
              <th class="text-right">{{$t("log.loss")}}</th>
              <th class="text-right">{{$t("log.fixedStopLoss")}}</th>
              <th class="text-right">{{$t("log.leverage")}}</th>
              <th>{{$t("log.status")}}</th>
              <th class="px-0 mx-0">{{$t("log.time")}}</th>
              <th>{{$t("common.strategy")}}</th>
              <th>{{$t("common.category")}}</th>
            </tr>
          </thead>
          <tbody>
          <tr v-if="swapUsdtOrderLoading">
            <td colspan="17">
              <v-progress-linear indeterminate></v-progress-linear>
            </td>
          </tr>
          <tr v-if="!swapUsdtOrderLoading && swapUsdtOrderHistoryTotalElements == 0">
              <td class="text-center" colspan="17">{{ $t('log.noDataAvailable')}}</td>
            </tr>
          <tr v-for="(item,index) in swapUsdtOrderHistory" :key="index">
              <td :class="CommonJS.formatExchangeColor(item.exchangeName)">{{ item.exchangeName }}</td>
              <td class="px-0 mx-0">{{ null == item.swapUsdtOrderId ? item.id : item.swapUsdtOrderId }}</td>
              <td class="px-0 mx-0">{{ null == item.exchangeOrderId ? "--" : item.exchangeOrderId }}</td>
              <td>{{ item.symbol }}</td>
              <td :class="['text-left','open_long' == item.side || 'close_buy' == item.side  ? 'green--text':'red--text']">
                <span v-side="item.side"></span>
              </td>
              <td>{{ item.orderType }}</td>
              <td class="text-right">{{ (item.side == 'open_short'|| item.side == 'close_sell') ? '-' : '' }}{{ formatNumber(item.size) }}</td>
              <td class="text-right">{{ 0 == item.price ? "--" : formatNumber(item.price.toFixed(getSwapUsdtDecimalPlace(item.exchange, item.symbol))) }}</td>
              <td class="text-right">{{ 0 == item.takeProfitPrice ? "--" : formatNumber(item.takeProfitPrice.toFixed(getSwapUsdtDecimalPlace(item.exchange, item.symbol))) }}</td>
              <td class="text-right">{{ 0 == item.stopLossPrice1 ? "--" : formatNumber(item.stopLossPrice1.toFixed(getSwapUsdtDecimalPlace(item.exchange, item.symbol))) }}</td>
              <td class="text-right" :class="item.beFixedStopLossPrice ? 'grey--text' : ''">{{ 0 == item.stopLossPrice ? "--" : formatNumber(item.stopLossPrice.toFixed(getSwapUsdtDecimalPlace(item.exchange, item.symbol))) }}</td>
              <td class="text-right" :class="item.beFixedStopLossPrice ? '' : 'grey--text'">{{ 0 == item.fixedStopLossPrice ? "--" : formatNumber(item.fixedStopLossPrice.toFixed(getSwapUsdtDecimalPlace(item.exchange, item.symbol))) }}</td>
              <td class="text-right">{{ item.leverage }}</td>
              <td><span v-status="item.status"></span></td>
              <td class="px-0 mx-0">{{ item.timestamp }}</td>
              <td :style="{color: getColorByStrategy(item.strategy)}">{{ item.strategy }}</td>
              <td :class="item.bgColor">{{ item.category }}</td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>
       <v-divider></v-divider>
      <v-row class="pb-5" v-if="swapUsdtOrderHistoryTotalElements > 0">
       <v-col cols="12" class="ml-0 pl-0">
          <v-pagination color="lightred" circle prev-icon="mdi-menu-left" next-icon="mdi-menu-right" v-model="swapUsdtOrderHistoryPageNo"
            :length="swapUsdtOrderHistoryTotalPage" :total-visible="15" @input="getSwapUsdtOrderHistoryByPages" class="ml-2 mt-3"
            v-if="swapUsdtOrderHistoryTotalElements > 0">
          </v-pagination>
        </v-col>
        <v-col cols="auto" class="mt-5 pt-5">
          {{$t("log.totalNumber") + formatNumber(swapUsdtOrderHistoryTotalElements)}}
        </v-col>
        <v-col cols="auto" class="float-right mt-3">
          <v-btn outlined tile class="ma-2" small @click="exPortorCurrent()" :loading="exportorLoading">
            <v-icon small class="mr-2">mdi-download</v-icon>{{$t("log.downloadCurrent")}}
          </v-btn>
        </v-col>
      </v-row>
    </v-col>
  </div>
</template>

<script>
  import Vue from 'vue';
  import config from '@/config.js';
  import { mapGetters } from "vuex";
  import CsvExportor from "csv-exportor";

  export default {
    components: {},
    data() {
      return {
        swapUsdtOrderHistoryHeaders:[
          { text:this.$t("log.exchange"), sortable:false},
          { text:this.$t("log.swapUsdtOrderId"), sortable:false},
          { text:this.$t("log.exchangeOrderId"), sortable:false},
          { text:this.$t("log.symbol"), sortable:false},
          { text:this.$t("log.side"), sortable:false},
          { text:this.$t("log.type"), sortable:false},
          { text:this.$t("log.size"), sortable:false, align:'right'},
          { text:this.$t("log.price"), sortable:false, align:'right'},
          { text:this.$t("log.profit"), sortable:false, align:'right'},
          { text:this.$t("log.loss1"), sortable:false, align:'right'},
          { text:this.$t("log.loss"), sortable:false, align:'right'},
          { text:this.$t("log.fixedStopLoss"), sortable:false, align:'right'},
          { text:this.$t("log.leverage"), sortable:false, align:'right'},
          { text:this.$t("log.status"), sortable:false},
          { text:this.$t("log.time"), sortable:false},
          { text:this.$t("common.strategy"), sortable:false},
          { text:this.$t("common.category"), sortable:false}
        ],  
        pageSize:30,
        exportorLoading:false,
        swapUsdtOrderLoading:false,
        swapUsdtOrderHistory:[],
        swapUsdtOrderHistoryTotalElements:0,
        swapUsdtOrderHistoryTotalPage:0,
        swapUsdtOrderHistoryPageNo:1,
        searchForm:{
          exchange:null,
          symbol:null,
          side:null,
          swapUsdtOrderId:null,
        },
      }
    },
    created (){

    },

    destroyed(){

    },
    watch:{
      
    },
    mounted() {
     this.getSwapUsdtOrderHistoryByPages(1)
    },
    computed:{
      ...mapGetters(['darkMode','exchanges','swapUsdtSymbolInfos','swapUsdtSymbols','orderSides','time','responsive', 'strategyColors']),
    },
    methods: {
      // 搜索SwapUsdt
      searchSwapUsdtOrderHistory() {
        this.swapUsdtOrderHistory = [];
        this.swapUsdtOrderHistoryPageNo = 1;
        this.swapUsdtOrderHistoryTotalElements = 0;
        this.swapUsdtOrderHistoryTotalPage = 0;
        this.getSwapUsdtOrderHistoryByPages(1);
      },
      // 分页查询USDT永续合约历史交易记录
      getSwapUsdtOrderHistoryByPages(swapUsdtOrderHistoryPageNo) {
        this.swapUsdtOrderLoading = true;
        let api = config.baseUrl + '/order/swap/usdt/private/history?pageNo=' + (swapUsdtOrderHistoryPageNo - 1) + "&pageSize=" + this.pageSize;
        if (this.searchForm.exchange != null && this.searchForm.exchange != '') {
          api = api + '&exchange=' + this.searchForm.exchange;
        }
        if (this.searchForm.symbol != null && this.searchForm.symbol != '') {
          api = api + '&symbol=' + this.searchForm.symbol;
        }
        if (this.searchForm.side != null && this.searchForm.side != '') {
          api = api + '&side=' + this.searchForm.side;
        }
        if (this.searchForm.swapUsdtOrderId != null && this.searchForm.swapUsdtOrderId != '') {
          api = api + '&swapUsdtOrderId=' + this.searchForm.swapUsdtOrderId;
        }
        this.$http.get(api).then(response => {
          let data = response.data;
          if (data.code == 200) {
            this.swapUsdtOrderHistory = data.result.swapUsdtOrderHistory;
            // 将交易所code转为名字
            for (let i = 0, len = this.swapUsdtOrderHistory.length; i < len; i++) {
              this.swapUsdtOrderHistory[i].exchangeName = this.getExchangeNameByCode(this.swapUsdtOrderHistory[i].exchange);
              this.swapUsdtOrderHistory[i].timestamp = this.formatDate(this.swapUsdtOrderHistory[i].timestamp);
              switch (this.swapUsdtOrderHistory[i].category) {
                case 'IOB':
                  this.swapUsdtOrderHistory[i].bgColor = 'palered--text';
                  break;
                case 'TA':
                  this.swapUsdtOrderHistory[i].bgColor = 'palegreen--text';
                  break;
                case 'MR':
                  this.swapUsdtOrderHistory[i].bgColor = 'paleyellow--text';
                  break;
                case 'TP':
                  this.swapUsdtOrderHistory[i].bgColor = 'red--text';
                  break;
                default:
                  this.swapUsdtOrderHistory[i].bgColor = '';
                  break;
              }
            }
            this.swapUsdtOrderHistoryTotalElements = data.result.totalElements;
            this.swapUsdtOrderHistoryTotalPage = Math.ceil(data.result.totalElements / this.pageSize);
            this.swapUsdtOrderLoading = false;
          } else {
            this.swapUsdtOrderHistory = [];
            this.swapUsdtOrderHistoryTotalElements = 0;
            this.swapUsdtOrderLoading = false;
          }
        })
      },
      exPortorCurrent(){
      this.exportorLoading = true;
      var heads = ["id","exchangeName","swapUsdtOrderId","exchangeOrderId","symbol","side","orderType","price","size","renkoEntryMultiplier","renkoExitMultiplier",
          "takeProfitPrice","stopLossPrice1","stopLossPrice",
          "fixedStopLossPrice","beFixedStopLossPrice","trailingStopLevel1","trailingStopLevel2","trailingStopLevel3",
          "priceLevel2","priceLevel3","leverage","strategy","category","status","timestamp","sendOrderStartTimestamp","sendOrderEndTimestamp",
          "takeProfitStartTimestamp","takeProfitEndTimestamp","stopLoss1StartTimestamp","stopLoss1EndTimestamp","stopLossStartTimestamp","stopLossEndTimestamp"
          ,"fixedStopLossStartTimestamp","fixedStopLossEndTimestamp"];
        var title = ["Id","Exchange","Swap USDT Order Id","Exchange Order Id","Symbol","Side","Type","Price","Size","Renko Entry Multiplier","Renko Exit Multiplier",
          "Take Profit Price","Stop Loss Price 1","Stop Loss Price",
          "Fixed Stop Loss","Be Fixed Stop Loss Price","Trailing Stop Level 1","Trailing Stop Level 2","Trailing Stop Level 3",
          "Price Level 2","Price Level 3",":Leverage","Strategy","Category","Status","Time","Send Order Start Time","Send Order End Time",
          "Take Profit Start Time","Take Profit End Time","Stop Loss 1 Start Time","Stop Loss 1 End Time","Stop Loss Start Time","Stop Loss End Time",
          "Fixed Stop Loss Start Time","Fixed Stop Loss End Time"];
        var body =[];
        for( var i = 0 ; i < this.swapUsdtOrderHistory.length ; i++){
          var line = [];
          heads.map(item=>{
            // 处理时间
            if( item.indexOf("Timestamp") != -1 ){
              if(this.swapUsdtOrderHistory[i][item] == undefined || this.swapUsdtOrderHistory[i][item] == null){
                line.push("--");
              }else if( this.swapUsdtOrderHistory[i][item] == -1 ){
                line.push("-1");
              }else if( this.swapUsdtOrderHistory[i][item] == 0 ){
                line.push("--");
              }else{
                line.push(this.formatDate(this.swapUsdtOrderHistory[i][item]));
              }
            }
            if( item.indexOf("Timestamp") == -1 ){
              // 处理无值的
              if(this.swapUsdtOrderHistory[i][item] == undefined){
                line.push("--");
              }else{
                if(this.swapUsdtOrderHistory[i][item] == 0){
                  line.push("--");
                }else{
                  // size的处理
                  if(item.indexOf("size") != -1){
                    if(this.swapUsdtOrderHistory[i]["side"] == "open_long" || this.swapUsdtOrderHistory[i]["side"] == "close_buy"){
                      line.push(this.swapUsdtOrderHistory[i][item]);
                    }else{
                      line.push("-"+this.swapUsdtOrderHistory[i][item]);
                    }
                  }else{
                    line.push(this.swapUsdtOrderHistory[i][item]);
                  }
                }
              }
            }
          })
          body.push(line);
        }
        CsvExportor.downloadCsv(body, { header: title }, 'SwapUSDT-' +this.user.username + '.csv');
        this.exportorLoading = false;
      },
      // 交易所code转为交易所name
      getExchangeNameByCode(code) {
        return getExchangeNameByCode(this.exchanges, code);
      },
      // 交易所id转为交易所name
      getExchangeNameById(id) {
        return getExchangeNameById(this.exchanges, id);
      },
      // 格式化日期
      formatDate(timestamp) {
        if ('localTime' == this.time) {
          return formatTime(timestamp);
        } else {
          return formatUTCTime(timestamp);
        }
      },
      // 格式化数字
      formatNumber(num) {
        return formatNumber(num)
      },
      // 获取USDT永续合约小数位
      getSwapUsdtDecimalPlace(exchange, symbol) {
        if (exchange == null || symbol == null) {
          return 8;
        }
        let currentExchangeSwapUsdtSymbolInfos = this.swapUsdtSymbolInfos[exchange];
        for (let i = 0, len = currentExchangeSwapUsdtSymbolInfos.length; i < len; i++) {
          if (currentExchangeSwapUsdtSymbolInfos[i].symbol == symbol) {
            return currentExchangeSwapUsdtSymbolInfos[i].priceDecimalPlace;
          }
        }
        return 8;
      },
      // 根据策略查询颜色
      getColorByStrategy(strategy){
        return this.strategyColors[strategy];
      },
    }
  }

</script>

<style>

</style>
