import { themes } from "./data";

const state = {
	darkMode: true,
	themes,
	selectedTheme: themes[0]
}

const getters = {
	darkMode: state => {
		let darkMode = localStorage.getItem('iobots-darkMode');
		if (darkMode == 0) {
			state.darkMode = false;
		} else if (darkMode == 1) {
			state.darkMode = true;
		} else if( darkMode == null){
			state.darkMode = true;
			localStorage.setItem('iobots-darkMode', 1);
		}
		localStorage.setItem('iobots-darkMode', 1);
		return state.darkMode;
	},
	themes: state => {
		return state.themes;
	},
	selectedTheme: state => {
		return state.selectedTheme;
	}
}

const actions = {
	darkModeHandler(context, payload) {
		context.commit('darkModeHandler', payload);
	},
	changeThemeHandler(context, payload) {
		context.commit('changeThemeHandler', payload);
	},
	changeColorStyleHandler(context, payload) {
		context.commit('changeColorStyleHandler', payload);
	}
}

const mutations = {
	darkModeHandler(state, darkMode){
		state.darkMode = darkMode;
		localStorage.setItem('iobots-darkMode', darkMode ? 1 : 0);
	},
	changeThemeHandler(state, theme) {
		state.selectedTheme = theme;
	},
	changeColorStyleHandler(state, colorStyle) {
		for(let i = 0; i < state.themes.length; i++){
			if (colorStyle == state.themes[i].bgColor) {
				state.selectedTheme = state.themes[i];
				localStorage.setItem('iobots-colorStyle', state.themes[i].bgColor);
				return;
			}
		}
		state.selectedTheme = state.themes[0];
		localStorage.setItem('iobots-colorStyle', state.themes[0].bgColor);
	}
}

export default {
	state,
	getters,
	actions,
	mutations
}