/**
 * [切换环境]
 * @Date 2019/08/01
 * @Author [Louis, Renly, Shirley]
 * @Param {[String]} 环境
 */
// let data = getParamConfig('dev');						// 开发环境
// let data = getParamConfig('devUseTest');				// 开发环境
// let data = getParamConfig('test');					// 测试环境
let data = getParamConfig('prod');					// 生产环境

let config = {
	baseUrl: data.baseUrl,
	webSocketUrl: data.webSocketUrl,
	domainName: data.domainName,
	sip: data.sip,
	fi: data.fi,
	environment: data.environment
}

function getParamConfig(env){
	let data = {
		baseUrl: '',
		webSocketUrl: '',
		domainName: '',
		sip: '',
		fi: '',
		environment: ''
	};
	switch (env) {
		case 'dev':
			data.baseUrl = "http://127.0.0.1:8080/dev";
			data.webSocketUrl = "ws://127.0.0.1:3000/ws";
			data.domainName = "http://127.0.0.1:8080";
			data.sip = 'http://118.126.113.35';
			data.fi = 'https://testnet.iob.fi';
			data.environment = 'Development';
			break;
		case 'devUseTest':
			data.baseUrl = "https://api-testnet.iobots.pro/iobots";
			data.webSocketUrl = "ws://18.179.20.48/ws";
			data.domainName = "http://127.0.0.1:8080";
			data.sip = 'http://118.126.113.35';
			data.fi = 'https://testnet.iob.fi';
			data.environment = 'Development';
			break;
		case 'test':
			data.baseUrl = "https://api-testnet.iobots.pro/iobots";
			data.webSocketUrl = "wss://testnet.iobots.pro/ws";
			data.domainName = "http://testnet.iobots.pro";
			data.sip = 'http://118.126.113.35';
			data.fi = 'https://testnet.iob.fi';
			data.environment = 'Test';
			break;
		case 'prod':
			data.baseUrl = "https://api.iobots.pro/iobots";
			data.webSocketUrl = "wss://api.iobots.pro/ws";
			data.domainName = "https://iobots.pro";
			data.sip = "https://iob.community";
			data.fi = 'https://iob.fi';
			data.environment = '';
			break;
		default:
			data.baseUrl = "http://127.0.0.1:3000";
			data.webSocketUrl = "ws://127.0.0.1:3000/ws";
			data.domainName = "http://127.0.0.1:8080";
			data.sip = 'http://118.126.113.35';
			data.fi = 'https://testnet.iob.fi';
			data.environment = 'Development';
			break;
	}
	return data;
}

export default config
