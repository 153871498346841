import Vue from 'vue';
import Router from 'vue-router';

// import Page404 from '@/pages/common/Page404.vue'
// import Page500 from '@/pages/common/Page500.vue'
// import Terms from '@/pages/common/terms.vue'
// import Signals from "@/pages/home/Signals.vue";
// import MoreSignals from "@/pages/home/MoreSignals.vue";
// import SignalHistory from "@/pages/home/SignalHistory.vue";
// import Trading from '@/pages/exchange/Trading';
// import FuturesTrading from '@/pages/exchange/FuturesTrading';
// import Huobi from '@/pages/exchange/Exchange_Huobi';
// import Home from '@/pages/home/Home.vue'
// import SignIn from '@/pages/user/SignIn.vue'
// import Config from '@/pages/user/Config.vue'
// import Logs from '@/pages/user/Logs'

const Page404 = () => import('@/pages/common/Page404.vue')
const Page500 = () => import('@/pages/common/Page500.vue')
const Terms = () => import('@/pages/common/Terms.vue')
const Home = () => import('@/pages/home/Home.vue')
// const Signals = () => import('@/pages/signal/Signals.vue')
// const MoreSignals = () => import('@/pages/signal/MoreSignals.vue')
const ModelPortfolio = () => import('@/pages/signal/ModelPortfolio.vue')
const SignalHistory = () => import('@/pages/signal/SignalHistory.vue')
const QuasarFund = () => import('@/pages/performance/QuasarFund.vue')
const SpotTrading = () => import('@/pages/trading/SpotTrading')
const FuturesTrading = () => import('@/pages/trading/FuturesTrading')
const SwapUsdtTrading = () => import('@/pages/trading/SwapUsdtTrading')
const SignIn = () => import('@/pages/user/SignIn.vue')
const Config = () => import('@/pages/user/Config.vue')
const Account = () => import('@/pages/user/Account.vue')
// const Logs = () => import('@/pages/user/Logs')
const Assets = () => import('@/pages/user/Assets')
// const BatchOrder = () => import('@/pages/trading/BatchOrder')
const Strategy = () => import('@/pages/strategy/Strategy')
const RenkoCharts = () => import('@/pages/chart/RenkoCharts')
const EnableGA = () => import('@/pages/ga/EnableGA')
const CloseGA = () => import('@/pages/ga/CloseGA')
const MarketTypes = () => import('@/pages/performance/MarketTypes')
const DefyHedgedPool = () => import('@/pages/performance/DefyHedgedPool')
const TradingSignalResults = () => import('@/pages/performance/TradingSignalResults')
const SyntheticIndices = () => import('@/pages/performance/SyntheticIndices')
const HistoricalPortfolioResults = () => import('@/pages/performance/HistoricalPortfolioResults')
const TechnicalAnalysisCharts = () => import('@/pages/chart/TechnicalAnalysisCharts')

import Logs from '@/pages/user/Logs'

Vue.use(Router)

export default new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    // 切换页面滚动到页面顶部
    scrollBehavior (to, from, savedPosition) {
        return { x: 0, y: 0 }
    },
    routes: [
        // { path: '/', name: 'home', component: Home, meta: { keepAlive: true } },
        { path: '/', name: 'home', component: MarketTypes, meta: { keepAlive: true } },
        { path: '/', name:'marketTypes', component: MarketTypes, meta: { keepAlive: true } },
        { path: '*', name:'page404', component: Page404, meta: { keepAlive: true } },
        { path: '*', name:'page500', component: Page500, meta: { keepAlive: true } },
        // { path: '/signals', name:'signals', component: Signals, meta: { keepAlive: true } },
        // { path: '/signals/more', name:'moreSignals', component: MoreSignals, meta: { keepAlive: true } },
        { path: '/signals/portfolio', name:'modelPortfolio', component: ModelPortfolio, meta: { keepAlive: true } },
        { path: '/signal/history/:symbol', name:'signalHistory', component: SignalHistory, meta: { keepAlive: true } },
        { path: '/performance/quasar', name:'quasarFund', component: QuasarFund, meta: { keepAlive: true } },
        { path: '/user/sign-in', name: 'signIn', component: SignIn, meta: { keepAlive: true } },
        { path: '/user/config', name: 'config', component: Config, meta: { keepAlive: true } },
        { path: '/user/logs', name: 'logs', component: Logs, meta: { keepAlive: false } },
        { path: '/user/assets', name:'assets', component:Assets, meta: { keepAlive: false } },
        { path: '/user/account', name:'account', component:Account, meta: { keepAlive: true } },
        { path: '/trading/spot/:exchangeCode/:symbol', name: 'spotTrading', component: SpotTrading, meta: { keepAlive: false } },
        { path: '/trading/futures/:exchangeCode/:symbol', name: 'futuresTrading', component: FuturesTrading, meta: { keepAlive: false } },
        { path: '/trading/swap/usdt/:exchangeCode/:symbol', name: 'swapUsdtTrading', component: SwapUsdtTrading, meta: { keepAlive: false } },
		{ path: '/about/terms', name:'terms', component: Terms, meta: { keepAlive: true } },
        // { path: '/order/batch', name:'batchOrder', component: BatchOrder, meta: { keepAlive: true } },
        { path: '/strategy', name:'strategy', component: Strategy, meta: { keepAlive: true } },
        { path: '/strategy/charts/renko/:currency', name:'renkoCharts', component: RenkoCharts, meta: { keepAlive: true } },
        { path: '/user/ga/enable', name:'enableGA', component: EnableGA, meta: { keepAlive: true } },
        { path: '/user/ga/close', name:'closeGA', component: CloseGA, meta: { keepAlive: true } },
        { path: '/market-types', name:'marketTypes', component: MarketTypes, meta: { keepAlive: true } },
        { path: '/performance/defy', name:'defyHedgedPool', component: DefyHedgedPool, meta: { keepAlive: true } },
        { path: '/performance/results/signal', name:'tradingSignalResults', component: TradingSignalResults, meta: { keepAlive: true } },
        { path: '/index/synthetic-indices', name:'syntheticIndices', component: SyntheticIndices, meta: { keepAlive: true } },
        { path: '/performance/results/portfolio', name:'historicalPortfolioResults', component: HistoricalPortfolioResults, meta: { keepAlive: true } },
        { path: '/charts/analysis', name:'technicalAnalysisCharts', component: TechnicalAnalysisCharts, meta: { keepAlive: true } }
    ]
})

const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
