import Vue from 'vue'

let Status = {
  	//转换时间
  	formatSide: function(status){
      switch(status){
        case 'unsubmitted': return window.vm.$t('status.unsubmitted');
        case 'deleted': return window.vm.$t('status.deleted');
        case 'submitted': return window.vm.$t('status.submitted');
        case 'canceled': return window.vm.$t('status.canceled');
        case 'partially_canceled': return window.vm.$t('status.partiallyCanceled');
        case 'partially_filled': return window.vm.$t('status.partiallyFilled');
        case 'partially_unfilled': return window.vm.$t('status.partiallyUnfilled');
        case 'fully_filled': return window.vm.$t('status.fullyFilled');
        case 'edited': return window.vm.$t('status.edited');
        case 'splited': return window.vm.$t('status.splited');
        case 'stop_loss': return window.vm.$t('status.stopLoss');
        case 'take_profit': return window.vm.$t('status.takeProfit');
        case 'open': return window.vm.$t('status.open');
        case 'closed': return window.vm.$t('status.closed');
        default : return status;
      }
  	}
}

Vue.directive('status', {
  bind: function(el, binding, vnode){
    let timestamp = new Date().getTime();
    // 给vnode更新key，用于数据更新时重新渲染改节点元素
    vnode.key = timestamp;
    el.innerHTML = Status.formatSide(binding.value);
  },
  unbind: function(el){

  }
});

export default Status
