const state = {
	user: {},
	role: null,
	locale: 'en-US',
	time: 'localTime',
	totalAssets: 0
}

const getters = {
	user: state => {
		if (state.user.token == null) {
			let user = JSON.parse(localStorage.getItem('iobots-user'));
			if (user) {
				state.user = user;
			}
		}
		return state.user;
	},
	role: state => {
		let role = JSON.parse(localStorage.getItem('iobots-role'));
		if (role) {
			state.role = role;
		}
		return state.role;
	},
	locale: state => {
		let locale = localStorage.getItem('iobots-locale');
		if (locale != null) {
			state.locale = locale;
		} else {
			localStorage.setItem('iobots-locale', 'en-US');
			state.locale = 'en-US';
		}
		return state.locale;
	},
	time: state => {
		let time = localStorage.getItem('iobots-time');
		if (time != null) {
			state.time = time;
		} else {
			localStorage.setItem('iobots-time', 'localTime');
			state.time = 'localTime';
		}
		return state.time;
	},
	totalAssets: state => {
		let time = localStorage.getItem('iobots-totalAssets');
		if (totalAssets != null) {
			state.totalAssets = totalAssets;
		} else {
			localStorage.setItem('iobots-totalAssets', 0);
			state.totalAssets = 0;
		}
		return state.totalAssets;
	}
}

const actions = {
	userHandler(context, payload){
		context.commit('userHandler', payload);
	},
	roleHandler(context, payload){
		context.commit('roleHandler', payload);
	},
	localeHandler(context, payload){
		context.commit('localeHandler', payload);
	},
	timeZoneHandler(context, payload){
		context.commit('timeZoneHandler', payload);
	},
	timeHandler(context, payload){
		context.commit('timeHandler', payload);
	},
    readIndexHandler(context, payload){
  	  context.commit('readIndexHandler', payload);
    },
    totalAssetsHandler(context, payload){
  	  context.commit('totalAssetsHandler', payload);
    }
}

const mutations = {
	userHandler(state, user){
		localStorage.setItem('iobots-user', JSON.stringify(user));
		state.user = user;
		if (state.user != null) {
			localStorage.setItem("iobots-locale", user.locale);
			state.locale = user.locale;
		}
	},
	roleHandler(state, role){
		localStorage.setItem('iobots-role', JSON.stringify(role));
		state.role = role;
	},
	localeHandler(state, locale){
		localStorage.setItem("iobots-locale", locale);
		state.locale = locale;
		if (state.user != null) {
			state.user.locale = locale;
		}
	},
	timeZoneHandler(state, timeZone){
		let time = 'localTime';
		if (timeZone == 'UTC') {
			time = 'UTCTime';
		} else if (timeZone == 'LT') {
			time = 'localTime';
		}
		localStorage.setItem("iobots-time", time);
		state.time = time;
		if (state.user != null) {
			state.user.timeZone = timeZone;
		}
	},
	timeHandler(state, time){
		localStorage.setItem("iobots-time", time);
		state.time = time;
	},
    readIndexHandler(state, readIndex){
  	  state.user.readIndex = readIndex;
    },
    totalAssetsHandler(state, totalAssets){
    	localStorage.setItem("iobots-totalAssets", totalAssets);
		state.totalAssets = totalAssets;
    }
}

export default {
	state,
	getters,
	actions,
	mutations
}