// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from "vue";
import App from "./App";
import router from "./router";

// --------------------------------
import 'babel-polyfill';
// 缓存
import Vuex from "vuex";
// 组件
import vuetify from "@/plugins/vuetify";

// 多语言
import VueI18n from "vue-i18n";
// 缓存
import { store } from "./store/store";
// http
import VueResource from "vue-resource";
// 时间组件
import Datetime from 'vue-datetime'; // https://vuejsexamples.com/mobile-friendly-datetime-picker-for-vue/
// 时间设置组件
import { Settings } from 'luxon';
// 复制组件
import VueClipboard from 'vue-clipboard2';
// 实例化的时候添加Container,解决模态框(弹窗)组件失效问题
VueClipboard.config.autoSetContainer = true;
// 无限下滑
import InfiniteLoading from 'vue-infinite-loading';
// UUID
import UUID from 'vue-uuid';


/**
 * 颜色选择器
 * https://caohenghu.github.io/vue-colorpicker/
 * https://vuejsexamples.com/color-picker-with-vue-js/
 */

// JS
// 初始化数据
import init from '@/store/init.js';

// 自定义事件指令
import time from '@/directives/time.js';
import role from '@/directives/role.js';
import side from '@/directives/side.js';
import status from '@/directives/status.js';
import week from '@/directives/week.js';
import ledgerType from '@/directives/ledgerType.js';
import headerTitle from '@/directives/headerTitle.js';
import signalInterval from '@/directives/signalInterval.js';


/**
 * vue-datetime组件链接:
 * https://github.com/mariomka/vue-datetime?ref=madewithvuejs.com
 * https://mariomka.github.io/vue-datetime/
 *
 */
// CSS
import 'vue-datetime/dist/vue-datetime.css'
// import "../static/css/global.css";

// include all css files
import '@/lib/vuetifyCss'

// Highcharts
import Highcharts from 'highcharts/highstock';
import HighchartsMore from 'highcharts/highcharts-more';
import HighchartsDrilldown from 'highcharts/modules/drilldown';
import Highcharts3D from 'highcharts/highcharts-3d.src.js';
import HighchartsExporting from 'highcharts/modules/exporting';
import HighchartsNoData from 'highcharts/modules/no-data-to-display';

// Y轴可拖动
import Scalable from '../public/js/scalable-yaxis.js';
// CommonJS
import CommonJS from '../static/js/common';

// scalableYAxis(Highcharts);

HighchartsMore(Highcharts)
HighchartsDrilldown(Highcharts);
Highcharts3D(Highcharts);
HighchartsExporting(Highcharts);
HighchartsNoData(Highcharts);

Vue.config.productionTip = false;

Vue.prototype.CommonJS = CommonJS;

Vue.use(VueI18n);
Vue.use(Vuex);
Vue.use(VueResource);
Vue.use(Datetime);
Vue.use(VueClipboard);
Vue.use(UUID);
Vue.use(init);
// Vue.use(Vuetify, {
//   theme: store.getters.selectedTheme.theme,
//   iconfont: 'mdi'
// });

function getLanguage() {
  // 将选择的语言存在localStorage中
  let locale = localStorage.getItem("iobots-locale");
  if (locale) {
    // 日期组件的多语言
    Settings.defaultLocale = locale;
    return locale;
  } else {
    return "en-US";
  }
}

const language = getLanguage();
Vue.locale = () => {};
const i18n = new VueI18n({
  locale: language,
  messages: {
    "en-US": require("@/assets/lang/en-US.json"),
    "zh-CN": require("@/assets/lang/zh-CN.json")
  }
});

window.vm = new Vue({
  i18n,
  router,
  store,
  vuetify,
  components: { App, InfiniteLoading },
  created: function() {
    let token = this.$store.getters.user.token;
    Vue.http.interceptors.push((request, next) => {
      // 接口每次请求会跨域携带cookie
      // request.credentials = true;
      // 前端迁移到 Cloudflare 则不需要跨域携带cookie
      request.credentials = false;
      request.headers.set('locale-language', language);
      if(token){
        // 请求headers携带参数
        request.headers.set('Authorization', 'Bearer '+ token);
        if(request.url.indexOf('kraken.com') > -1){
          request.headers.set('Authorization', 'Authorization');
        }
      }
      next((response) => {
        if(response.data != null && response.data.code == 500){
           // this.$router.push({name:'page500'});
           this.$store.dispatch('snackbarMessageHandler', response.data.message);
        }
        return response;
      });
    });
    this.init();
  },
  render: h => h(App),
  components: { App }
}).$mount('#app')

/* eslint-disable no-new */
// window.vm = new Vue({
//   el: "#app",
//   i18n,
//   store,
//   router,
//    vuetify,
//   components: { App, InfiniteLoading },
//   template: "<App/>",
//   created: function() {
//     let token = this.$store.getters.user.token;
//     Vue.http.interceptors.push((request, next) => {
//       // 接口每次请求会跨域携带cookie
//       request.credentials = true;
//       if(token){
//         // 请求headers携带参数
//         request.headers.set('Authorization', 'Bearer '+ token);
//         if(request.url.indexOf('kraken.com') > -1){
//           request.headers.set('Authorization', 'Authorization');
//         }
//       }
//       next((response) => {
//         if(response.data.code == 500){
//            // this.$router.push({name:'page500'});
//            this.$store.dispatch('snackbarMessageHandler', response.data.message);
//         }
//         return response;
//       });
//     });
//     this.init();
//   }
// });
// setup some stuff
// connectWS();
