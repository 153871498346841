export default {
    // 格式化英式日期
    formatBritishDate: function (time) {
        let timezone = localStorage.getItem("iobots-time");
        var date = new Date(time);
        if (timezone == 'UTCTime') {
            var year = date.getUTCFullYear();
            var month = (date.getUTCMonth() + 1) < 10 ? ('0' + (date.getUTCMonth() + 1)) : (date.getUTCMonth() + 1);
            var day = (date.getUTCDate() < 10) ? '0' + date.getUTCDate() : date.getUTCDate();
            return month + "/" + day + "/" + year;
        } else {
            var year = date.getFullYear();
            var month = (date.getMonth() + 1) < 10 ? ("0" + (date.getMonth() + 1)) : (date.getMonth() + 1);
            var day = (date.getDate() < 10) ? ("0" + date.getDate()) : date.getDate();
            return month + '/' + day + '/' + year;
        }
    },
    // 数字三位加逗号
    formatNumber: function(num) {
        if (num == null) return '';
        if (num == NaN || num == 'NaN') return '';
        if (num == 'undefined') return '';
        if (num == '--') return '--';
        let number = num + '';
        let numberArray = number.split('.');
        let integerPart = numberArray[0];
        let decimalPart = numberArray.length > 1 ? '.' + numberArray[1] : '';
        let rgx = /(\d+)(\d{3})/;
        while (rgx.test(integerPart)) {
            integerPart = integerPart.replace(rgx, '$1' + ',' + '$2');
        }
        return integerPart + decimalPart;
    },
    // 模糊匹配
    likeMatch: function(str1, str2){
        if (str1 == null) return false;
        if (str2 == null) return true;
        return str1.toLowerCase().indexOf(str2.toLowerCase()) > -1;
    },
    // 格式化交易所的颜色
    formatExchangeColor(exchange){
        if (exchange == null) {
            return "";
        }
        exchange = exchange.toLowerCase();
        switch(exchange){
            case 'kraken': 
                return 'kraken-text-color';
            case 'okex': 
                return 'okex-text-color';
            case 'binance': 
                return 'binance-text-color';
            case 'ftx': 
                return 'ftx-text-color';
            default: 
                return "";
        }
    },
    // 判断字符串不为null且长度大于0返回true
    hasLength(str){
        return str != null && str.length > 0;
    }
}