import Vue from 'vue'

let Week = {
  	//转换时间
  	formatWeek: function(dayOfWeek){
      switch(dayOfWeek){
        case 0: return window.vm.$t('week.sunday');
        case 1: return window.vm.$t('week.monday');
        case 2: return window.vm.$t('week.tuesday');
        case 3: return window.vm.$t('week.wednesday');
        case 4: return window.vm.$t('week.thursday');
        case 5: return window.vm.$t('week.friday');
        case 6: return window.vm.$t('week.saturday');
        default : return '';
      }
  	}
}

Vue.directive('week', {
  bind: function(el, binding, vnode){
    let timestamp = new Date().getTime();
    // 给vnode更新key，用于数据更新时重新渲染改节点元素
    vnode.key = timestamp;
    el.innerHTML = Week.formatWeek(binding.value);
  },
  unbind: function(el){

  }
});

export default Week
