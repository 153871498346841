<template>
  <div>
    <v-col cols="12" md="7" offset-md="8" class="py-0" v-if="responsive == 'PC'">
      <v-row dense>
        <v-col cols="12" sm="6" md="2" xs="6">
          <v-select class="fs-12" dense v-model="ledgerSearchForm.exchange" :items="exchanges" :label="$t('common.exchange')" item-text="name" item-value="code" clearable></v-select>
        </v-col>
        <v-col cols="12" sm="6" md="2">
          <v-select class="fs-12" dense v-model="ledgerSearchForm.category" :items="tradingCategories" item-text="text" item-value="value" :label="$t('common.category')" clearable></v-select>
        </v-col>
        <v-col cols="12" sm="5" md="2">
          <v-select class="fs-12" dense v-model="ledgerSearchForm.currency" :items="currencies" :label="$t('log.currency')" clearable></v-select>
        </v-col>
        <v-col cols="12" sm="2" md="2">
          <v-btn icon @click="searchLedger">
            <v-icon>mdi-magnify</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-col>
    <!-- 手机 -->
    <v-col cols="12" class="px-5" v-if="responsive == 'mobile'">
      <v-row dense>
        <v-col cols="12">
          <div class="d-custom-flex">
            <v-select class="fs-12 mr-5" dense v-model="ledgerSearchForm.exchange" :items="exchanges" :label="$t('common.exchange')" item-text="name" item-value="code" clearable></v-select>
            <v-select class="fs-12" dense v-model="ledgerSearchForm.category" :items="tradingCategories" item-text="text" item-value="value" :label="$t('common.category')" clearable></v-select>
          </div>
          <div class="d-custom-flex">
            <v-select class="fs-12 mr-3" dense v-model="ledgerSearchForm.currency" :items="currencies" :label="$t('log.currency')" clearable></v-select>
            <v-btn class="float-right mt-2" x-small icon  @click="searchLedger">
            <v-icon>mdi-magnify</v-icon>
          </v-btn>
          </div>
        </v-col>
      </v-row> 
    </v-col>
    <v-col cols="12" md="12" class="pa-0 ma-0 pa-0 px-3" v-if="responsive == 'PC'">
      <v-row dense v-if="ledgerHistoryLoading"> 
          <v-col cols="12">
              <v-skeleton-loader dense type="table-row-divider@30" class="mx-auto" ></v-skeleton-loader>
          </v-col>
      </v-row>
      <v-data-table dense :headers="ledgerHistoriesHeaders" :items="ledgerHistories" hide-default-footer :items-per-page="pageSize" v-if="!ledgerHistoryLoading" disable-pagination>
        <template v-slot:body="{ items }">
          <tbody>
            <tr v-for="(item,index) in items" :key="index">
              <!-- <td class="px-0 mx-0">{{ item.id }}</td> -->
              <td :class="CommonJS.formatExchangeColor(item.exchange)">{{ getExchangeNameByCode(item.exchange) }}</td>
              <td>{{ item.category.toUpperCase() }}</td>
              <td class="text-right" :class=" item.amount < 0 ? 'red--text' : ''">{{ formatNumber(item.amount) }}{{ item.category == 'swap-usdt' ? ' USDT' : " "+item.currency }}</td>
              <td class="text-right">{{ formatNumber(item.size) }}</td>
              <td class="text-right">{{ formatNumber(item.fee) }}</td>
              <td>{{ item.currency }}</td>
              <td>{{ item.instrument == null ? '--' :item.instrument }}</td>
              <td><span v-ledger-type="item.type"></span></td>
              <td>{{ CommonJS.hasLength(item.ledgerId) ? item.ledgerId : '--' }}</td>
              <td>{{ CommonJS.hasLength(item.orderId) ? item.orderId : '--' }}</td>
              <td>{{ formatDate(item.timestamp) }}</td>
            </tr>
            <tr v-if="!ledgerHistoryLoading && ledgerHistoryTotalElements == 0">
              <td class="text-center" colspan="17">{{ $t('signalHistory.noDataAvailable')}}</td>
            </tr>
          </tbody>
        </template>
      </v-data-table>
       <v-divider v-if="!ledgerHistoryLoading"></v-divider>
        <v-row class="pb-5" v-if="ledgerHistoryTotalElements > 0">
          <v-col cols="auto" class="pl-0">
            <v-pagination color="lightred" circle prev-icon="mdi-menu-left" next-icon="mdi-menu-right" v-model="ledgerHistoryPageNo"
              :length="ledgerHistoryTotalPage" :total-visible="15" @input="getLedgerHistory" class="ml-2 mt-3"
              v-if="ledgerHistoryTotalElements > 0" :items-per-page="pageSize">
            </v-pagination>
          </v-col>
          <v-col cols="auto" class="mt-5 pt-5">
            {{$t("log.totalNumber") + formatNumber(ledgerHistoryTotalElements)}}
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="12" sm="6" md="3" class="text-right mt-3">
            <v-btn outlined tile class="ma-2" small @click="exPortorCurrent()" :loading="exportorLoading">
              <v-icon small class="mr-2">mdi-download</v-icon>{{$t("log.downloadCurrent")}}
            </v-btn>
          </v-col>
        </v-row>
    </v-col>
    <!-- 手机 -->
    <v-col cols="12" md="12" class="pa-0 ma-0 pa-0 px-3" v-if="responsive == 'mobile'">
      <v-simple-table dense>
        <template v-slot:default>
          <thead>
            <tr>
              <!-- <th class="px-0 mx-0">{{$t("log.id")}}</th> -->
              <th>{{$t("common.exchange")}}</th>
              <th>{{$t("common.category")}}</th>
              <th class="text-right">{{$t("log.amount")}}</th>
              <th class="text-right">{{$t("log.size")}}</th>
              <th class="text-right">{{$t("log.fee")}}</th>
              <th>{{$t("log.currency")}}</th>
              <th>{{$t("log.symbol")}}</th>
              <th>{{$t("log.type")}}</th>
              <th>{{$t("log.ledgerId")}}</th>
              <th>{{$t("log.orderId")}}</th>
              <th>{{$t("log.time")}}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="ledgerHistoryLoading">
              <td colspan="17">
                <v-progress-linear indeterminate ></v-progress-linear>
              </td>
            </tr>
            <tr v-if="!ledgerHistoryLoading && ledgerHistoryTotalElements == 0">
              <td class="text-center" colspan="17">{{ $t('signalHistory.noDataAvailable')}}</td>
            </tr>
            <tr v-for="(item,index) in ledgerHistories" :key="index">
              <!-- <td class="px-0 mx-0">{{ item.id }}</td> -->
              <td :class="CommonJS.formatExchangeColor(item.exchange)">{{ getExchangeNameByCode(item.exchange) }}</td>
              <td>{{ item.category.toUpperCase() }}</td>
              <td class="text-right" :class=" item.amount < 0 ? 'red--text' : ''">{{ formatNumber(item.amount) }}{{ item.category == 'swap-usdt' ? ' USDT' : " "+item.currency }}</td>
              <td class="text-right">{{ formatNumber(item.size) }}</td>
              <td class="text-right">{{ formatNumber(item.fee) }}</td>
              <td>{{ item.currency }}</td>
              <td>{{ item.instrument == null ? '--' :item.instrument }}</td>
              <td><span v-ledger-type="item.type"></span></td>
              <td>{{ CommonJS.hasLength(item.ledgerId) ? item.ledgerId : '--' }}</td>
              <td>{{ CommonJS.hasLength(item.orderId) ? item.orderId : '--' }}</td>
              <td>{{ formatDate(item.timestamp) }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
       <v-divider></v-divider>
      <v-row class="pb-5" v-if="ledgerHistoryTotalElements > 0">
        <v-col cols="12" class="pl-0">
          <v-pagination color="lightred" circle prev-icon="mdi-menu-left" next-icon="mdi-menu-right" v-model="ledgerHistoryPageNo"
            :length="ledgerHistoryTotalPage" :total-visible="15" @input="getLedgerHistory" class="ml-2 mt-3"
            v-if="ledgerHistoryTotalElements > 0">
          </v-pagination>
        </v-col>
        <v-col cols="auto" class="mt-5 pt-5">
          {{$t("log.totalNumber") + formatNumber(ledgerHistoryTotalElements)}}
        </v-col>
        <v-col cols="auto" class="float-right mt-3">
          <v-btn outlined tile class="ma-2" small @click="exPortorCurrent()" :loading="exportorLoading">
            <v-icon small class="mr-2">mdi-download</v-icon>{{$t("log.downloadCurrent")}}
          </v-btn>
        </v-col>
      </v-row>
    </v-col>
  </div>
</template>

<script>
  import Vue from 'vue';
  import config from '@/config.js';
  import { mapGetters } from "vuex";
  import CsvExportor from "csv-exportor";

  export default {
    components: {

    },
    data() {
      return {
        ledgerHistoriesHeaders:[
          // { text:this.$t("log.id"), sortable:false},
          { text:this.$t("common.exchange"), sortable:false},
          { text:this.$t("common.category"), sortable:false},
          { text:this.$t("log.amount"), sortable:false, align:'right'},
          { text:this.$t("log.size"), sortable:false, align:'right'},
          { text:this.$t("log.fee"), sortable:false, align:'right'},
          { text:this.$t("log.currency"), sortable:false},
          { text:this.$t("log.symbol"), sortable:false},
          { text:this.$t("log.type"), sortable:false},
          { text:this.$t("log.ledgerId"), sortable:false},
          { text:this.$t("log.orderId"), sortable:false},
          { text:this.$t("log.time"), sortable:false}
        ],
        pageSize:30,
        exportorLoading:false,
        ledgerHistoryLoading:false,
        ledgerHistoryPageNo:1,
        ledgerHistoryTotalElements:0,
        ledgerHistoryTotalPage:0,
        ledgerHistories:[],
        ledgerSearchForm:{
          exchange:null,
          category:null,
          currency:null,
        },
        currencies:[],
      }
    },
    created (){

    },

    destroyed(){

    },
    watch:{
      
    },
    mounted() {
     this.getLedgerHistory();
     this.getBaseCurrency();
    },
    computed:{
      ...mapGetters(['darkMode','exchanges','tradingCategories','swapUsdtSymbols','time','responsive']),
    },
    methods: {
      // 获取资金流水历史LedgerHistory
      getLedgerHistory() {
        this.ledgerHistoryLoading = true;
        this.ledgerHistories = [];
        var last = "";
        if (this.ledgerSearchForm.exchange != null) {
          last = last + "&exchange=" + this.ledgerSearchForm.exchange;
        }
        if (this.ledgerSearchForm.category != null) {
          last = last + "&category=" + this.ledgerSearchForm.category;
        }
        if (this.ledgerSearchForm.currency != null) {
          last = last + "&currency=" + this.ledgerSearchForm.currency;
        }
        let api = config.baseUrl + "/ledger/private/getLedgerHistory?pageNo=" + this.ledgerHistoryPageNo + "&pageSize=" + this.pageSize + last;
        this.$http.get(api).then(res => {
          let data = res.data;
          if (data.code == 200) {
            this.ledgerHistories = data.result.ledgers;
            this.ledgerHistoryTotalElements = data.result.total;
            this.ledgerHistoryTotalPage = Math.ceil(this.ledgerHistoryTotalElements / this.pageSize);
            this.ledgerHistoryLoading = false;
          } else {
            this.ledgerHistoryTotalElements = 0;
            this.ledgerHistoryTotalPage = 0;
            this.ledgerHistoryLoading = false;
          }
        })
      },// 添加条件的查询
      searchLedger() {
        this.ledgerHistoryPageNo = 1;
        this.getLedgerHistory();
        this.ledgerHistoryTotalElements = 0;
        this.ledgerHistoryTotalPage = 0;
      },
      exPortorCurrent(){
        this.exportorLoading = true;
        var heads = ["id","exchange","category","amount","size","fee","currency","instrument","type","ledgerId","orderId","time"];
        var title = ["Id","Exchange","Category","Amount","Size","Fee","Currency","Instrument","Type","LedgerId","OrderId","Time"];
        var body =[];
        for( var i = 0 ; i < this.ledgerHistories.length ; i++){
          var line = [];
          heads.map(item=>{
            // 处理时间
            if( item.indexOf("Timestamp") != -1 ){
              if(this.spotOrderHistory[i][item] == undefined || this.spotOrderHistory[i][item] == null){
                line.push("--");
              }else if( this.spotOrderHistory[i][item] == -1 ){
                line.push("-1");
              }else if( this.spotOrderHistory[i][item] == 0 ){
                line.push("--");
              }else{
                line.push(this.formatDate(this.spotOrderHistory[i][item]));
              }
            }
            if( item.indexOf("Timestamp") == -1 || item == "timestamp"){
              // 处理无值的
              if(this.spotOrderHistory[i][item] == undefined){
                line.push("--");
              }else{
                if(this.spotOrderHistory[i][item] == 0){
                  line.push("--");
                }else{
                  // size的处理
                  if(item.indexOf("size") != -1){
                    if(this.spotOrderHistory[i]["side"] == "open_long" || this.spotOrderHistory[i]["side"] == "close_buy"){
                      line.push(this.spotOrderHistory[i][item]);
                    }else{
                      line.push("-"+this.spotOrderHistory[i][item]);
                    }
                  }else{
                    line.push(this.spotOrderHistory[i][item]);
                  }
                }
              }
            }
          })
          body.push(line);
        }
        CsvExportor.downloadCsv(body, { header: title }, 'Ledger-' +this.user.username + '.csv');
        this.exportorLoading = false;
      },
      // 交易所code转为交易所name
      getExchangeNameByCode(code) {
        return getExchangeNameByCode(this.exchanges, code);
      },
      // 格式化日期
      formatDate(timestamp) {
        if ('localTime' == this.time) {
          return formatTime(timestamp);
        } else {
          return formatUTCTime(timestamp);
        }
      },
      // 格式化数字
      formatNumber(num) {
        return formatNumber(num)
      },
      // 获取基础货币
      getBaseCurrency(){
        this.currencies = [];
        for(var i = 0 ; i < this.swapUsdtSymbols.length ; i++){
          this.currencies.push(this.swapUsdtSymbols[i].replace(/-USDT-SWAP/,""));
        }
      },
    }
  }

</script>

<style>

</style>
