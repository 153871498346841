import greenUpRedDown from "../../../themes/green-up-red-down";
import greenDownRedUp from "../../../themes/green-down-red-up";

// themes
export const themes = [
   {
      id: 1,
      bgColor: "greenUpRedDown",
      theme: greenUpRedDown
   },
   {
      id: 2,
      bgColor: "greenDownRedUp",
      theme: greenDownRedUp
   }
]