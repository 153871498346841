<template>
<div>
    <v-bottom-navigation fixed v-if="responsive == 'mobile'"  grow v-model="actived" shift left :class="[darkMode?'grey darken-3':'']">
        <v-btn x-small @click="goToHome">
            <span>{{$t("menu.home")}}</span>
            <v-icon size="22" >icon icon-h</v-icon>
        </v-btn>
        <!-- <v-btn x-small @click="goToSignals">
            <span>{{$t("menu.signals")}}</span>
            <v-icon size="20">icon-signals</v-icon>
        </v-btn> -->
        <!-- <v-btn x-small @click="goToQuasarFund" >
            <span>{{$t("menu.performance")}}</span>
            <v-icon size="20">icon icon-uniE938</v-icon>
        </v-btn> -->
        <v-btn x-small @click="goToMarketType" >
            <span>{{$t("header.indicators")}}</span>
            <v-icon size="20">mdi-shape-plus</v-icon>
        </v-btn>
        <v-btn x-small @click="goToSwapUsdt">
            <span>{{$t("menu.trading")}}</span>
            <v-icon size="20">icon icon-iobots1</v-icon>
        </v-btn>
        <v-btn x-small @click="goToStrategy">
            <span>{{$t("menu.strategy")}}</span>
            <v-icon size="20">icon icon-s1</v-icon>
        </v-btn>
    </v-bottom-navigation>
</div>
</template>

<style>

</style>

<script>
import Vue from 'vue'
import config from '@/config.js';
import {
    mapGetters
} from "vuex";
export default {
    data() {
        return {
        }
    },
    created() {

    },
    mounted() {

    },
    computed: {
        ...mapGetters(["responsive", "darkMode", "defaultExchange"]),
        actived:{
            	get () {
            		return this.$store.getters.mobileBtnPage;
		        },
		        set (val) {
                    // console.log(val);
		        }
            }
    },
    watch: {

    },
    methods: {
        // 跳转首页
        goToHome(){
            this.$store.dispatch("mobileBtnPageHandler",0);
            this.$router.push({name:"home"});
        },
        // 跳转Signals
        goToSignals() {
            this.$store.dispatch("mobileBtnPageHandler",1);
            if (this.user.token) {
                this.goToMoreSignals();
            } else {
                this.$router.push({
                    name: "signals"
                });
            }
        },
        goToMoreSignals() {
            this.$store.dispatch("mobileBtnPageHandler",1);
            this.$router.push({ name: "moreSignals" });
        },
        // 跳转到MarketType
        goToMarketType(){
            this.$store.dispatch("mobileBtnPageHandler",1);
            this.$router.push({ name: "marketTypes" });
        },
        // 跳转到Quasar Fund
        goToQuasarFund() {
            this.$store.dispatch("mobileBtnPageHandler",1);
            this.$router.push({ name: "signalHistory", params: { symbol: "BTCUSD" } });
            this.$store.commit('signalPageHandler', this.$t("menu.signalsHistory"));
            // this.$router.push({ name: "quasarFund"});
            // this.$store.commit('signalPageHandler', this.$t("menu.quasarFund"));
            // if (this.user.token) {
            //     this.$router.push({
            //         name: "quasarFund"
            //     });
            //     this.$store.commit('signalPageHandler', this.$t("menu.performance"))
            // } else {
            //     this.gotoSignIn();
            // }
        },
        // 跳转到USDT永续合约
        goToSwapUsdt() {
            if (this.user.token) {
                if (this.user.role == "TRADER") {
                    this.$router.push({
                        name: "swapUsdtTrading",
                        params: {
                            exchangeCode: this.defaultExchange,
                            symbol: "BTC-USDT-SWAP"
                        }
                    });
                    this.$store.dispatch("mobileBtnPageHandler",0);
                }else{
                    this.$store.dispatch("snackbarMessageHandler", this.$t("header.snackbarMessage"));
                }
            } else {
                this.gotoSignIn();
            }
        },
        // 跳转到goToFutures
        goToFutures() {
            if (this.user.token) {
                if (this.user.role == "TRADER") {
                    this.$router.push({
                    name: "futuresTrading",
                    params: {
                        exchangeCode: this.defaultExchange,
                        symbol: "PI_BTCUSD"
                    }
                    });
                    this.$store.dispatch("mobileBtnPageHandler",0);
                } else {
                    this.$store.dispatch("snackbarMessageHandler", this.$t("header.snackbarMessage"));
                }
            } else {
                this.gotoSignIn();
            }
        },
        // 跳转到策略
        goToStrategy() {
            if (this.user.token) {
                if (this.user.role == "TRADER") {
                    this.$store.dispatch("mobileBtnPageHandler",3);
                    this.$router.push({
                        name: "strategy"
                    });
                }else{
                    this.$store.dispatch("snackbarMessageHandler", this.$t("header.snackbarMessage"));
                }
            } else {
                this.gotoSignIn();
            }

        },
        // 跳转到登录页面
        gotoSignIn() {
            this.$store.dispatch("mobileBtnPageHandler",0);
            this.$store.dispatch("snackbarMessageHandler", this.$t("header.signInMessage"));
            this.$router.push({
                name: "signIn"
            });
        }

    },
}
</script>
