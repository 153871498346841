<template>
  <v-card class="h-100">
    <!-- MDI图标：http://code.meta-platform.com/assets/mdi/preview.html -->
    <!-- ***************************************************************************************** -->
    <!-- 用户 -->
    <v-list class="pa-1" dense>
      <v-list-item dense>
        <v-row v-show="!isLogin" class="align-center pointer mt-3" @click="routerPage('signIn')" dense>
          <v-list-item-avatar slot="activator" class="fs-28">
          <!--  <v-btn icon class="ma-0 fs-24"> -->
             <!-- <v-icon medium>mdi-account-circle</v-icon> -->
			  <i class="mdi mdi-account-circle"></i>
          <!--  </v-btn> -->
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>
              <a>{{$t("menu.signin")}}</a>
            </v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon>
            <v-icon class="right flex-b" color="grey" @click.stop="$emit('update:isShowMenuSidebar', !isShowMenuSidebar)">mdi-close</v-icon>
          </v-list-item-icon>
        </v-row>
        <v-row v-show="isLogin" class="align-center" dense>
          <v-list-item-avatar color="primary" class="pointer" @click="routerPage('account')">
            <img v-if="user != null && user.avatar != null" :src="user.avatar" />
            <span v-else class="white--text">
              {{ user.username != null ? user.username.substring(0, 1).toLocaleUpperCase() : '' }}
            </span>
          </v-list-item-avatar>
          <v-list-item-content class="pointer" @click="routerPage('account')">
            <v-list-item-title>
              <span>@{{ user.username }}</span>
            </v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon>
            <v-icon class="right flex-b" color="grey" @click.stop="$emit('update:isShowMenuSidebar', !isShowMenuSidebar)">mdi-close</v-icon>
          </v-list-item-icon>
        </v-row>
      </v-list-item>
    </v-list>
    <v-divider v-show="responsive == 'mobile' && ((isLogin && user != null && user.role == 'TRADER') || !isLogin) "></v-divider>
    <!-- <v-divider v-show="isLogin && user != null && user.role == 'TRADER'"></v-divider> -->
    <!-- <v-list v-show="isLogin" class="pa-1 pt-0 mt-0 mx-3" dense>
      <div class="font-12">
        <span>UID: </span>
        <span>{{ user.id }}</span>
      </div>
    </v-list> -->
    <!-- ***************************************************************************************** -->
    <v-list class="pt-0" dense v-if="responsive =='PC'">
      <v-divider></v-divider>
      <!-- SyntheticIndices -->
      <v-list-item @click="goToSyntheticIndices">
        <v-list-item-icon class="ml-2 fs-20">
         <!-- <v-icon small>mdi-calendar-text</v-icon> -->
		 <i class="mdi mdi-calendar-text"></i>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{$t("header.syntheticIndices")}}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <!-- 信号 -->
      <v-divider></v-divider>
      <!-- <v-list-item @click="goToMoreSignals">
        <v-list-item-icon class="ml-2"> 
          <v-icon small>icon-signals</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{$t("menu.signals")}}</v-list-item-title>
        </v-list-item-content>
      </v-list-item> -->
      <!-- 市场类型 -->
      <v-list-item @click="goToMarketTypes">
        <v-list-item-icon class="ml-2 fs-20">
		  <i class="mdi mdi-shape-plus"></i>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{$t("menu.marketTypes")}}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
      <!-- TradingSignalResults -->
      <v-list-item @click="goToTradingSignalResults">
        <v-list-item-icon class="ml-2 fs-20">
		  <i class="mdi mdi-factory"></i>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{$t("header.tradingSignalResults")}}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <!-- 信号历史 -->
      <v-list-item @click="goToSignalsHistory">
        <v-list-item-icon class="ml-2 fs-20">
		  <i class="mdi mdi-sort-variant"></i>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{$t("menu.signalsHistory")}}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <!-- Defy Hedged Pool-->
      <v-list-item @click="goToDefyHedgedPool">
        <v-list-item-icon class="ml-2">
          <icon symbol="DEFY" color="white" v-if="!darkMode"></icon>
          <icon symbol="DEFY" color="black" v-if="darkMode"></icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{$t("header.defyHedgedPool")}}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <!-- Quasar Fund-->
      <v-list-item @click="goToQuasarFund">
        <v-list-item-icon class="ml-2 fs-20">
		  <i class="icon icon-uniE938"></i>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{$t("header.quasarFund")}}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <!-- 投资组合 -->
      <v-list-item @click="goToModelPortfolio">
        <v-list-item-icon class="ml-2 fs-20">
		  <i class="mdi mdi-table-large"></i>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{$t("menu.modelPortfolio")}}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <!-- 资产管理 -->
      <v-list-item @click="gotoTechnicalAnalysisCharts" v-if="user != null && user.role == 'TRADER'" v-show="isLogin">
        <v-list-item-icon class="ml-2 fs-20">
		    <i class="mdi mdi-wallet"></i>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Technical Analysis Charts</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <!-- HistoricalPortfolioResults -->
      <!-- <v-list-item @click="goToHistoricalPortfolioResults">
        <v-list-item-icon class="ml-2">
          <v-icon small>mdi-view-module</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{$t("header.historicalPortfolioResults")}}</v-list-item-title>
        </v-list-item-content>
      </v-list-item> -->
      <v-divider v-show="!isLogin"></v-divider>
      <v-divider v-if="user != null && user.role == 'TRADER'"></v-divider>
      <!-- 资产管理 -->
      <v-list-item @click="gotoAssets" v-if="user != null && user.role == 'TRADER'" v-show="isLogin">
        <v-list-item-icon class="ml-2 fs-20">
		 <i class="mdi mdi-wallet"></i>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{$t("menu.assets")}}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <!-- 现货 -->
      <v-list-item @click="gotoSpot" v-if="user != null && user.role == 'TRADER'" v-show="isLogin">
        <v-list-item-icon class="ml-2 fs-20">
		  <i class="mdi mdi-chart-areaspline"></i>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{$t("menu.spot")}}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <!-- 期货 -->
      <v-list-item @click="gotoFutures" v-if="user != null && user.role == 'TRADER'" v-show="isLogin">
        <v-list-item-action class="ml-2 fs-20">
		  <i class="mdi mdi-chart-histogram"></i>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{$t("menu.futures")}}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <!-- USDT永续合约 -->
      <v-list-item @click="gotoSwapUsdt" v-if="user != null && user.role == 'TRADER'" v-show="isLogin">
        <v-list-item-icon class="ml-2 fs-20">
		  <i class="mdi mdi-chart-bar"></i>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{$t("menu.swapUsdt")}}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <!-- 批量订单 -->
      <!-- <v-list-item @click="gotoBatchOrder" v-if="user != null && user.role == 'TRADER'" v-show="isLogin">
        <v-list-item-icon class="ml-2">
          <v-icon small>mdi-credit-card-multiple</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{$t("menu.batchOrder")}}</v-list-item-title>
        </v-list-item-content>
      </v-list-item> -->
      <!-- 交易日志 -->
      <v-list-item @click="gotoLogs" v-if="user != null && user.role == 'TRADER'" v-show="isLogin">
        <v-list-item-icon class="ml-2 fs-20">
		  <i class="mdi mdi-file-document-box"></i>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{$t("menu.logs")}}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider v-if="user != null && user.role == 'TRADER'"></v-divider>
      <!-- 设置APIKEY按钮 -->
      <v-list-item @click="gotoConfig" v-if="user != null && user.role == 'TRADER'" v-show="isLogin">
        <v-list-item-icon class="ml-2 fs-20">
		  <i class="mdi mdi-key-variant"></i>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{$t("menu.config")}}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <!-- 策略管理 -->
      <v-list-item @click="gotoStrategy" v-if="user != null && user.role == 'TRADER'" v-show="isLogin">
        <v-list-item-icon class="ml-2 fs-20">
		  <i class="icon icon-s1"></i>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{$t("menu.strategy")}}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <!-- 图表 -->
      <v-list-item @click="gotoRenkoCharts" v-if="user != null && user.role == 'TRADER'" v-show="isLogin">
        <v-list-item-icon class="ml-2 fs-20">
		  <i class="mdi mdi-checkerboard"></i>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ $t('menu.renkoCharts') }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <!-- 手机版右边菜单 -->
     <v-list class="pt-0" flat dense v-if="responsive =='mobile'">
      <!-- 设置APIKEY按钮 -->
      <v-list-item @click="gotoConfig" v-if="user != null && user.role == 'TRADER'" v-show="isLogin">
        <v-list-item-icon class="ml-2 fs-20">
		  <i class="mdi mdi-key-variant"></i>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{$t("menu.config")}}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
     </v-list>
    <v-divider v-show="isLogin"></v-divider>
    <!-- ***************************************************************************************** -->
    <!-- 黑暗模式编辑 -->
  <!--  <v-list class="pt-0" dense>
      <v-list-item class="pt-0 pointer">
        <v-list-item-icon class="ml-2">
          <v-icon small>mdi-brightness-4</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ $t('menu.darkTheme') }}</v-list-item-title>
        </v-list-item-content>
        <v-list-item-action>
          <v-switch dense class="ml-5" color="primary" v-model="darkModeModel"></v-switch>
        </v-list-item-action>
      </v-list-item>
    </v-list> -->
    <!-- ***************************************************************************************** -->
    <!-- 语言选择 -->
    <v-list class="pt-0" dense>
      <v-list-group>
        <template v-slot:activator>
          <v-list-item-icon class="ml-2 fs-20">
			<i class="mdi mdi-google-translate"></i>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ $t('menu.language') + $t('menu.locale') }}</v-list-item-title>
          </v-list-item-content>
        </template>
        <v-list-item>
          <v-radio-group v-model="languageModel" class="ml-12 pl-3" dense hide-details>
            <v-radio color="primary" label="English" value="en-US"></v-radio>
            <v-radio color="primary" label="中文（简体）" value="zh-CN"></v-radio>
          </v-radio-group>
        </v-list-item>
      </v-list-group>
    </v-list>
    <!-- ***************************************************************************************** -->
    <!-- 本地时间和UTC时间 -->
    <v-list class="pt-0" dense>
      <v-list-group>
        <template v-slot:activator>
          <v-list-item-icon class="ml-2 fs-20">
			<i class="mdi mdi-clock-outline"></i>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-if="'localTime' == timeModel">{{ $t('menu.time') + $t('menu.localTime')}}</v-list-item-title>
            <v-list-item-title v-if="'localTime' != timeModel">{{ $t('menu.time') +$t('menu.utcTime')}}</v-list-item-title>
          </v-list-item-content>
        </template>
        <v-list-item>
          <div>
            <v-radio-group v-model="timeModel" class="ml-12 pl-3" dense hide-details>
              <v-radio color="primary" :label="$t('menu.localTime')" value="localTime"></v-radio>
              <v-radio color="primary" :label="$t('menu.utcTime')" value="UTCTime"></v-radio>
            </v-radio-group>
          </div>
        </v-list-item>
        <!-- <v-list-item>
            <v-radio-group v-model="timeModel" class="ml-12">
              <v-radio color="primary" :label="$t('menu.utcTime')" value="UTCTime"></v-radio>
            </v-radio-group>
          </v-list-item> -->
      </v-list-group>
    </v-list>
    <!-- **************************************颜色*************************************************** -->
    <!-- <v-list class="pt-0" dense>
      <v-list-group>
        <template slot="activator">
          <v-list-item-icon class="ml-2">
            <v-icon small>mdi-palette</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ $t('menu.color')}}</v-list-item-title>
          </v-list-item-content>
        </template>
        <v-list-item>
          <v-radio-group v-model="colorModel" class="ml-12 pl-3" dense hide-details>
            <v-radio color="primary" :label="$t('menu.greenUpRedDown')" value="greenUpRedDown" @click="changeTheme('greenUpRedDown')"></v-radio>
            <v-radio color="primary" :label="$t('menu.greenDownRedUp')" value="greenDownRedUp" @click="changeTheme('greenDownRedUp')"></v-radio>
          </v-radio-group>
        </v-list-item>
      </v-list-group>
    </v-list> -->
    <!-- **************************Send FeedBack******************* -->
    <v-list class="pt-0" dense>
      <v-list-item @click="gotoSendFeedback">
        <v-list-item-icon class="ml-2 fs-20">
		  <i class="mdi mdi-comment-text-outline"></i>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{$t("menu.sendFeedback")}}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider v-show="isLogin"></v-divider>
      <!-- 退出按钮 -->
      <v-list-item @click="routerPage('signOut')" v-show="isLogin">
        <v-list-item-icon class="ml-2 fs-20">
		  <i class="mdi mdi-exit-to-app"></i>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{$t("menu.signOut")}}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <!-- ***************************************************************************************** -->
    <!-- 			<v-list class="pt-0 mb-5 d-none">
              <v-list-group>
                  <v-list-tile slot="activator" class="">
                    <v-list-tile-action class="ml-2">
                        <v-icon>fas fa-palette</v-icon>
                      </v-list-tile-action>
                      <v-list-tile-content>
                        <v-list-tile-title>{{ $t('menu.style') }}</v-list-tile-title>
                      </v-list-tile-content>
                </v-list-tile>
              <v-list-tile class="ml-2">
                  <v-avatar size="24" v-for="theme in themes" :key="theme.id" :color="theme.bgColor" class="pointer mr-2" @click="changeTheme(theme)"></v-avatar>
                </v-list-tile>
              </v-list-group>
          </v-list> -->
  </v-card>
</template>
<style scoped>
	/* .v-application a {
	    color: #FEE108!important;
	} */
	.v-list :hover>div{
	    color: #FEE108!important;
	}
	.v-list-item--link:before{
		color: #FEE108!important;
    background-color:rgb(255, 255, 255) ;
	}
	.theme--dark .v-navigation-drawer :hover {
	    background-color: #212121;
	}
</style>
<script>
  import Vue from 'vue'
  import config from '@/config.js'
  import { mapGetters } from "vuex";
  import Icon from '@/components/Icon'
  export default {
    data() {
      return {
        languageModel: this.$store.getters.locale,
        darkModeModel: this.$store.getters.darkMode,
        timeModel: this.$store.getters.time,
        colorModel: this.$store.getters.selectedTheme.bgColor,
        sip: config.sip,
      }
    },
    props: {
      isShowMenuSidebar: Boolean
    },
    components: {
      Icon
    },
    computed: {
      ...mapGetters(["darkMode", "themes", "selectedTheme", "user", "responsive", "defaultExchange" ]),
      isLogin() {
        if (this.user.token) {
          return true;
        } else {
          return false;
        }
      }
    },
    created() {
      // 数字格式化
      Vue.filter('NumFormat', function(value) {
        if (!value) return '0.00';
        var intPart = Number(value).toFixed(0); //获取整数部分
        intPart = intPart > value ? intPart - 1 : intPart; // 若四舍五入进了1则减去1
        var intPartFormat = intPart.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,'); //将整数部分逢三一断
        var floatPart = ".00"; //预定义小数部分
        var value2Array = value.split(".");
        //=2表示数据有小数位
        if (value2Array.length == 2) {
          floatPart = value2Array[1].toString(); //拿到小数部分
          if (floatPart.length == 1) { //补0,实际上用不着
            return intPartFormat + "." + floatPart + '0';
          } else {
            return intPartFormat + "." + floatPart;
          }
        } else {
          return intPartFormat + floatPart;
        }
      })
    },
    mounted() {

    },
    watch: {
      darkModeModel(newVal, oldVal) {
        this.emitDarkMode(newVal);
      },
      languageModel(newVal, oldVal) {
        this.changeLanguage(newVal);
      },
      timeModel(v) {
        this.changeTime(v);
      }
    },
    methods: {
      // 初始化标题
      initHeaderTitle(){
        this.$store.dispatch("signalHeaderTitleHandler", 'signals');
        this.$store.dispatch("tradingHeaderTitleHandler", 'trading');
      },
      setSignalHeaderTitle(msg){
        this.$store.dispatch("signalHeaderTitleHandler", msg);
        this.$store.dispatch("tradingHeaderTitleHandler", 'trading');
      },
      setTradingHeaderTitle(msg){
        this.$store.dispatch("signalHeaderTitleHandler", 'signals');
        this.$store.dispatch("tradingHeaderTitleHandler", msg);
      },
      // 跳转技术分析图表
      gotoTechnicalAnalysisCharts(){
        this.$router.push({
          name: "technicalAnalysisCharts"
        });
      },
      // 跳转资产管理
      gotoAssets() {
        this.$router.push({
          name: "assets"
        });
        this.initHeaderTitle();
      },
      // 设置apiKey
      gotoConfig() {
        this.$router.push({
          name: "config"
        });
        this.initHeaderTitle();
      },
      // 跳转到策略
      gotoStrategy(){
        this.$router.push({
          name: "strategy"
        });
        this.initHeaderTitle();
      },
      // 跳转到Renko图表
      gotoRenkoCharts(){
        this.$router.push({
          name: "renkoCharts",
          params: {
            currency: "BTC"
          }
        });
        this.initHeaderTitle();
      },
      // 跳转Signals
      goToMoreSignals() {
        this.$router.push({
          name: "moreSignals"
        });
        this.initHeaderTitle();
      },
      // 跳转到MarketTypes
      goToMarketTypes(){
        this.$router.push({name: "marketTypes"});
        this.$store.dispatch("indicatorsHeaderTitleHandler", 'marketTypes');
        this.$store.dispatch("signalHeaderTitleHandler", 'signals');
        this.$store.dispatch("tradingHeaderTitleHandler", 'trading');
        this.$store.dispatch("performanceHeaderTitleHandler", 'performance');
      },
      // 跳转到SignalsHistory
      goToSignalsHistory() {
        this.$router.push({ name: "signalHistory", params: { symbol: "BTCUSD" } });
        this.setSignalHeaderTitle('signalsHistory');
        this.$store.dispatch("indicatorsHeaderTitleHandler", 'indicators');
        this.$store.dispatch("performanceHeaderTitleHandler", 'signalsHistory');
      },
      // 跳转到Logs
      gotoLogs() {
        this.$router.push({ name: "logs" });
        this.initHeaderTitle();
      },
      // 跳转到Spot
      gotoSpot() {
        if (this.user.token) {
          if (this.user.role == 'TRADER') {
            this.$router.push({
              name: "spotTrading",
              params: {
                exchangeCode: this.defaultExchange,
                symbol: "BTC-USDT"
              }
            });
            this.setTradingHeaderTitle('spot');
          } else {
            this.$store.dispatch('snackbarMessageHandler', this.$t("header.snackbarMessage"));
          }
        } else {
          this.gotoSignIn();
        }
      },
      // 跳转到goToFutures
      gotoFutures() {
        if (this.user.token) {
          if (this.user.role == 'TRADER') {
            this.$router.push({
              name: "futuresTrading",
              params: {
                exchangeCode: this.defaultExchange,
                symbol: "PI_BTCUSD"
              }
            });
            this.setTradingHeaderTitle('futures');
          } else {
            this.$store.dispatch('snackbarMessageHandler', this.$t("header.snackbarMessage"));
          }
        } else {
          this.gotoSignIn();
        }
      },
      // 跳转到USDT永续合约
      gotoSwapUsdt() {
        if (this.user.token) {
          if (this.user.role == 'TRADER') {
            this.$router.push({
              name: "swapUsdtTrading",
              params: {
                exchangeCode: this.defaultExchange,
                symbol: "BTC-USDT-SWAP"
              }
            });
            this.setTradingHeaderTitle('swapUsdt');
          } else {
            this.$store.dispatch('snackbarMessageHandler', this.$t("header.snackbarMessage"));
          }
        } else {
          this.gotoSignIn();
        }
      },
      gotoBatchOrder() {
        if (this.user.token) {
          if (this.user.role == 'TRADER') {
            this.$router.push({
              name: "batchOrder"
            });
          } else {
            this.$store.dispatch('snackbarMessageHandler', this.$t("header.snackbarMessage"));
          }
          this.initHeaderTitle();
        } else {
          this.gotoSignIn();
        }
      },
      //退出
      signOut() {
        // localStorage.clear();
        localStorage.removeItem("iobots-user");
        localStorage.removeItem("iobots-role");
        localStorage.removeItem("iobots-coinSummary");
        localStorage.removeItem("iobots-usdtSummary");
        localStorage.removeItem("iobots-assetInfo");
        this.$router.push({
          name: 'home'
        });
        this.$router.go();
      },
      gotoSipProfile() {
        window.open(this.sip + '/#/user/profile/@' + this.user.username, "_blank");
      },
      gotoSendFeedback() {
        window.open(this.sip + '/#/request/new', "_blank");
      },
      //路由到页面
      routerPage(name) {
        switch (name) {
          case "account":
            this.$router.push({
              name: 'account'
            });
            break;
          case "profile":
            this.gotoSipProfile();
            break;
          case "signOut":
            this.signOut();
            break;
          case "signIn":
            this.$router.push({
              name: 'signIn'
            });
          default:
            break;
        }
      },
      //切换语言
      changeLanguage(locale) {
        this.$i18n.locale = locale;
        this.$store.dispatch("localeHandler", locale);
        if (this.user != null && this.user.token != null) {
          // 登录状态下，将语言环境更新到用户属性
          let api = config.baseUrl + '/user/private/updateLocale?locale=' + locale;
          this.$http.put(api).then(response => {
            let data = response.data;
            if (data.code == 200) {
              // 更新成功，刷新页面
              this.$router.go();
            } else {
              // 更新失败，提示信息
              this.$store.dispatch("snackbarMessageHandler", data.message);
            }
          }, error => {
            this.$store.dispatch("snackbarMessageHandler", this.$t("common.oops"));
          });
        } else {
          // 未登录直接刷新
          this.$router.go();
        }
      },
      // 切换时区
      changeTime(time) {
        this.$store.dispatch("timeHandler", time);
        if (this.user != null && this.user.token != null) {
          // 登录状态下，将时区环境更新到用户属性
          let timeZone = 'LT';
          if (time == 'UTCTime') {
            timeZone = 'UTC';
          } else if (time == 'localTime') {
            timeZone = 'LT';
          }
          let api = config.baseUrl + '/user/private/updateTimeZone?timeZone=' + timeZone;
          this.$http.put(api).then(response => {
            let data = response.data;
            if (data.code == 200) {
              // 更新成功，刷新页面
              this.$router.go();
            } else {
              // 更新失败，提示信息
              this.$store.dispatch("snackbarMessageHandler", data.message);
            }
          }, error => {
            this.$store.dispatch("snackbarMessageHandler", this.$t("common.oops"));
          });
        } else {
          // 未登录直接刷新
          this.$router.go();
        }
      },
      emitDarkMode(darkMode) {
        this.$vuetify.theme.dark = darkMode == true ? true : false;
        this.$store.dispatch("darkModeHandler", darkMode);
        localStorage.setItem('iobots-darkMode', darkMode ? 1 : 0);
      },
      changeTheme(bgColor) {
        let theme = null;
        for (let i = 0, len = this.themes.length; i < len; i++) {
          if (this.themes[i].bgColor == bgColor) {
            theme = this.themes[i];
          }
        }
        this.$store.dispatch("changeThemeHandler", theme);
        this.$store.dispatch("changeColorStyleHandler", theme.bgColor);
        this.$vuetify.theme = theme.theme;
        this.$router.go();
      },
      // 跳转到登录页面
      gotoSignIn() {
        this.$store.dispatch("snackbarMessageHandler", this.$t("header.signInMessage"));
        this.$router.push({
          name: "signIn"
        });
        this.initHeaderTitle();
      },
      // 跳转到Model Portfolio
      goToModelPortfolio(){
        this.$router.push({name: "modelPortfolio"});
        this.setSignalHeaderTitle('modelPortfolio');
        this.$store.dispatch("indicatorsHeaderTitleHandler", 'indicators');
        this.$store.dispatch("performanceHeaderTitleHandler", 'modelPortfolio');
      },
      // 跳转到QuasarFund
      goToQuasarFund(){
        this.$router.push({name: "quasarFund"});
        this.$store.dispatch("indicatorsHeaderTitleHandler", 'indicators');
        this.$store.dispatch("signalHeaderTitleHandler", 'signals');
        this.$store.dispatch("tradingHeaderTitleHandler", 'trading');
        this.$store.dispatch("performanceHeaderTitleHandler", 'quasarFund');
      },
      // 跳转到DEFY
      goToDefyHedgedPool(){
        this.$router.push({name: "defyHedgedPool"});
        this.$store.dispatch("indicatorsHeaderTitleHandler", 'indicators');
        this.$store.dispatch("signalHeaderTitleHandler", 'signals');
        this.$store.dispatch("tradingHeaderTitleHandler", 'trading');
        this.$store.dispatch("performanceHeaderTitleHandler", 'defyHedgedPool');
      },
      goToHistoricalPortfolioResults(){
        this.$router.push({name: "historicalPortfolioResults"});
        this.$store.dispatch("indicatorsHeaderTitleHandler", 'indicators');
        this.$store.dispatch("signalHeaderTitleHandler", 'signals');
        this.$store.dispatch("tradingHeaderTitleHandler", 'trading');
        this.$store.dispatch("performanceHeaderTitleHandler", 'historicalPortfolioResults');
      },
      goToSyntheticIndices(){
        this.$router.push({name: "syntheticIndices"});
        this.$store.dispatch("indicatorsHeaderTitleHandler", 'indicators');
        this.$store.dispatch("signalHeaderTitleHandler", 'signals');
        this.$store.dispatch("tradingHeaderTitleHandler", 'trading');
        this.$store.dispatch("performanceHeaderTitleHandler", 'syntheticIndices');
      },
      goToTradingSignalResults(){
        this.$router.push({name: "tradingSignalResults"});
        this.$store.dispatch("indicatorsHeaderTitleHandler", 'indicators');
        this.$store.dispatch("signalHeaderTitleHandler", 'signals');
        this.$store.dispatch("tradingHeaderTitleHandler", 'trading');
        this.$store.dispatch("performanceHeaderTitleHandler", 'tradingSignalResults');
      },
    }
  }
</script>
