<template>
    <div>
        <v-dialog v-model="disconnectionWarningDialog" persistent no-click-animation max-width="500">
            <v-card>
                <v-card-title class="grey lighten-2">
                    <span class="black--text">{{ $t('websocket.disconnection') }}</span>
                </v-card-title>
                <v-container>
                    <div class="fs-16">
                        {{ $t('websocket.disconnectionMessage') }}
                    </div>
                </v-container>
                <v-divider></v-divider>
                <v-card-actions class="mr-5 px-5">
                    <v-spacer></v-spacer>
                    <v-btn small tile dark color="lightred" class="black--text" @click="refresh">
                        {{ $t('common.ok') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<style>

</style>
<script>
    import Vue from 'vue';
    import config from '@/config.js';
    import { mapGetters } from "vuex";
    export default {
        data(){
            return {
                // CONNECTING：值为0，表示正在连接。
                // OPEN：值为1，表示连接成功，可以通信了。
                // CLOSING：值为2，表示连接正在关闭。
                // CLOSED：值为3，表示连接已经关闭，或者打开连接失败。
                // WebSocket对象
                websocket: null,
                // 心跳机制定时器
                heartbeatTimer: null,
                // 重连次数
                reconnectTimes: 0,
                // 断线警告窗口
                disconnectionWarningDialog: false
            }
        },
        computed: {
            ...mapGetters(['user', 'webSocketData', 'networkStatus'])
        },
        created(){
            this.initWebSocket();
        },
        mounted(){
            this.heartbeatTimer = setInterval(() => {
                this.heartbeat();
            }, 30000);
        },
        watch:{
            networkStatus(newVal, oldVal){
                // 网络重新连接时，重连WS
                if (newVal) {
                    this.initWebSocket();
                }
            }
        },
        methods: {
            // 心跳
            heartbeat(){
                 let data = {
                    event: 'heartbeat'
                };
                this.websocketSend(JSON.stringify(data));
            },
            // 初始化websocket
            initWebSocket(){
                this.websocket = null;
                let url = config.webSocketUrl;
                if (url == null || url == '') {
                    return;
                }
                this.websocket = new WebSocket(url);
                this.websocket.onmessage = this.websocketOnMessage;
                this.websocket.onerror = this.websocketOnError;
                this.websocket.onopen = this.websocketOnOpen;
                this.websocket.onclose = this.websocketOnClose;
            },
            // 连接建立之后执行send方法发送数据
            websocketOnOpen(){
                let data = {
                    event: 'subscribe',
                    token: 'Bearer '+ this.user.token
                };
                this.reconnectTimes = 0;
                this.websocketSend(JSON.stringify(data));
            },
            // 收到消息，解析数据
            websocketOnMessage (e) {
                this.count = 0;
                let message = e.data;
                let obj = JSON.parse(message);
                if (obj.event == 'HEARTBEAT') {
                    // 遇到心跳检测不处理
                    return;
                }
                let data = this.webSocketData;
                if (data == null) {
                    data = {};
                }
                if (obj.code == 200) {
                    data[obj.event] = obj.data;
                } else if (obj.code == 204) {
                    data[obj.event] = {
                        totalElements: 0,
                        orderList: []
                    };
                }
                this.$store.dispatch('webSocketDataHandler', data);
            },
            // 收到错误
            websocketOnError(){

            },
            // 关闭连接
            websocketOnClose (e) {
                this.reconnect();
            },
            // 数据发送
            websocketSend (data) {
                if (this.websocket != null && this.websocket.readyState == this.websocket.OPEN) {
                    if (this.networkStatus) {
                        this.websocket.send(data)
                    }
                }
            },
            // 重连
            reconnect(){
                if (this.reconnectTimes > 40) {
                    // 40次后不再自动重连
                    this.disconnectionWarningDialog = true;
                    return;
                }
                this.reconnectTimes++;
                this.websocket = null;
                setTimeout(() => {
                    if(this.networkStatus){
                        this.initWebSocket();
                    }
                }, 5000);
            },
            // 刷新
            refresh(){
                this.disconnectionWarningDialog = false;
                this.$router.go(0);
            }
        },
        // 销毁事件
        destroyed(){
            // 离开路由之后断开websocket连接
            if (this.websocket) {
                this.websocket.close();
                this.websocket = null;
            }
        }
    }
</script>
