<template>
 <div>
    <v-card class="h-90"> 
    <!-- MDI图标：http://code.meta-platform.com/assets/mdi/preview.html -->
    <!-- ***************************************************************************************** -->
    <!-- 信号 -->
	<div class="pl-5 pt-3">
	  <a :href="iobots" class="del-underline black--text">
	   <logo type="twoLines" color="mobileSolid"></logo> 
	  </a>
	</div>
    <!-- <v-list class="pt-5 pb-0" dense>
      <v-list-group>
        <template v-slot:activator>
          <v-list-item-icon class="ml-1">
            <v-icon size="20">icon-signals</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{$t("menu.signals")}}</v-list-item-title>
          </v-list-item-content>
        </template>
        <v-list-item @click="goToSignals">
            <v-list-item-content class="ml-16">
            <v-list-item-title>{{$t("menu.signals")}}</v-list-item-title>
            </v-list-item-content>
        </v-list-item>
        <v-list-item @click="goToSignalsHistory">
            <v-list-item-content class="ml-16">
            <v-list-item-title>{{$t("menu.signalsHistory")}}</v-list-item-title>
            </v-list-item-content>
        </v-list-item>
        <v-list-item @click="goToModelPortfolio">
            <v-list-item-content class="ml-16">
            <v-list-item-title>{{$t("menu.modelPortfolio")}}</v-list-item-title>
            </v-list-item-content>
        </v-list-item>
      </v-list-group>
    </v-list>
    <v-divider></v-divider> -->
    <v-divider></v-divider>
    <!-- 指数 -->
    <v-list class="py-0" dense flat>
      <v-list-group>
        <template v-slot:activator>
          <v-list-item-icon class="ml-1">
            <v-icon size="18">mdi-calendar-text</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{$t("header.index")}}</v-list-item-title>
          </v-list-item-content>
        </template>
        <!-- 综合指数 -->
        <v-list-item @click="goToSyntheticIndices">
            <v-list-item-content class="ml-15">
            <v-list-item-title>{{$t("header.syntheticIndices")}}</v-list-item-title>
            </v-list-item-content>
        </v-list-item>
      </v-list-group>
    </v-list>
    <v-divider></v-divider>
    <!-- 指标 -->
    <v-list class="py-0" dense>
      <v-list-group>
        <template v-slot:activator>
          <v-list-item-icon class="ml-1">
            <v-icon size="18">mdi-shape-plus</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{$t("header.indicators")}}</v-list-item-title>
          </v-list-item-content>
        </template>
        <!-- 市场类型 -->
        <v-list-item @click="goToMarketTypes">
            <v-list-item-content class="ml-15">
            <v-list-item-title>{{$t("header.marketTypes")}}</v-list-item-title>
            </v-list-item-content>
        </v-list-item>
      </v-list-group>
    </v-list>
    <v-divider></v-divider>
   <!-- 基金 -->
    <v-list class="py-0" dense flat>
      <v-list-group>
        <template v-slot:activator>
          <v-list-item-icon class="ml-1">
            <v-icon size="18">icon icon-uniE938</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{$t("menu.performance")}}</v-list-item-title>
          </v-list-item-content>
        </template>
        <v-list-item @click="goToTradingSignalResults">
            <v-list-item-content class="ml-15">
            <v-list-item-title>{{$t("header.tradingSignalResults")}}</v-list-item-title>
            </v-list-item-content>
        </v-list-item>
        <!-- 信号历史 -->
        <v-list-item @click="goToSignalsHistory">
            <v-list-item-content class="ml-15">
            <v-list-item-title>{{$t("menu.signalsHistory")}}</v-list-item-title>
            </v-list-item-content>
        </v-list-item>
        <!-- quasar 基金 -->
        <v-list-item @click="goToDefyHedgedPool">
            <v-list-item-content class="ml-15">
            <v-list-item-title>{{$t("menu.defyHedgedPool")}}</v-list-item-title>
            </v-list-item-content>
        </v-list-item>
        <!-- quasar 基金 -->
        <v-list-item @click="goToQuasarFund">
            <v-list-item-content class="ml-15">
            <v-list-item-title>{{$t("menu.quasarFund")}}</v-list-item-title>
            </v-list-item-content>
        </v-list-item>
        <!-- 投资组合 -->
        <v-list-item @click="goToModelPortfolio">
            <v-list-item-content class="ml-15">
            <v-list-item-title>{{$t("menu.modelPortfolio")}}</v-list-item-title>
            </v-list-item-content>
        </v-list-item>
        <!-- HistoricalPortfolioResults -->
        <!-- <v-list-item @click="goToHistoricalPortfolioResults">
            <v-list-item-content class="ml-15">
            <v-list-item-title>{{$t("header.historicalPortfolioResults")}}</v-list-item-title>
            </v-list-item-content>
        </v-list-item> -->
        <!-- SyntheticIndices -->
        <!-- <v-list-item @click="goToSyntheticIndices">
            <v-list-item-content class="ml-15">
            <v-list-item-title>{{$t("header.syntheticIndices")}}</v-list-item-title>
            </v-list-item-content>
        </v-list-item> -->
        <!-- TradingSignalResults -->
      </v-list-group>
    </v-list>
    <v-divider></v-divider>
    <!-- 交易 -->
    <v-list class="py-0" dense flat>
      <v-list-group>
        <template v-slot:activator>
          <v-list-item-icon class="ml-1">
            <v-icon size="19">icon icon-iobots1</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{$t("menu.trading")}}</v-list-item-title>
          </v-list-item-content>
        </template>
        <!-- 资产 -->
        <v-list-item @click="gotoAssets">
          <v-list-item-content class="ml-15">
            <v-list-item-title>{{$t("menu.assets")}}</v-list-item-title>
          </v-list-item-content>
      </v-list-item>
         <!-- 现货交易 -->
        <v-list-item @click="goToSpot">
            <v-list-item-content class="ml-15">
            <v-list-item-title>{{$t("menu.spot")}}</v-list-item-title>
            </v-list-item-content>
        </v-list-item>
         <!-- 期货 -->
        <v-list-item @click="goToFutures">
            <v-list-item-content class="ml-15">
            <v-list-item-title>{{$t("menu.futures")}}</v-list-item-title>
            </v-list-item-content>
        </v-list-item>
        <!-- USDT永续合约 -->
      <v-list-item @click="goToSwapUsdt">
        <v-list-item-content class="ml-15">
          <v-list-item-title>{{$t("menu.swapUsdt")}}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <!-- 日志 -->
      <v-list-item @click="gotoLogs">
          <v-list-item-content class="ml-15">
            <v-list-item-title>{{$t("menu.logs")}}</v-list-item-title>
          </v-list-item-content>
      </v-list-item>
      </v-list-group>
    </v-list>
    <v-divider v-show="!isLogin"></v-divider>
    <v-divider v-if="user != null && user.role == 'TRADER'"></v-divider>
    <!-- 策略 -->
    <v-list class="py-0" dense>
      <v-list-group>
        <template v-slot:activator>
          <v-list-item-icon class="ml-1">
            <v-icon size="18">icon icon-s1</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{$t("menu.strategy")}}</v-list-item-title>
          </v-list-item-content>
        </template>
         <!-- 策略管理 -->
        <v-list-item @click="goToStrategy">
            <v-list-item-content class="ml-15">
            <v-list-item-title>{{$t("menu.strategy")}}</v-list-item-title>
            </v-list-item-content>
        </v-list-item>
         <!-- 图表 -->
        <v-list-item @click="gotoRenkoCharts">
            <v-list-item-content class="ml-15">
            <v-list-item-title>{{$t("menu.renkoCharts")}}</v-list-item-title>
            </v-list-item-content>
        </v-list-item>
      </v-list-group>
    </v-list>
    <v-divider></v-divider>
    <!-- 策略 -->
    <v-list class="py-0" dense>
       <!-- 图表 -->
       <a :href="fi" target="_blank" class="mr-3 del-underline">
          <v-list-item dense>
            <v-list-item-icon class="ml-1">
                <v-icon size="18" v-if="!darkMode">icon icon-1</v-icon>
                <v-icon size="18" v-if="darkMode">icon icon-w</v-icon>
              </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                iob.fi
              </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
           <v-divider></v-divider>
      </a>
    </v-list>
  </v-card>
   <footer class="fs-12 text-center" v-if="responsive =='mobile'">
     <v-divider></v-divider>
      <v-card  flat class="pa-0 px-3 py-3 my-1">
          <span class="mr-3">IOB © 2020</span>
          <a :href="sip+'/#/about/privacy'" target="_blank" class="mr-3 del-underline">{{$t("footer.privacyLegal")}}</a>
          <a :href="iobots+'/about/terms'"  target="_blank" class="mr-3 del-underline">{{$t("footer.terms")}}</a>
      </v-card>
    </footer>
 </div>
</template>
<style scoped>
	.v-list :hover>div{
	    color: #FEE108!important;
	}
	.v-list-item--link:before{
		color: #FEE108!important;
	}
	.v-list-item .v-list-item--active .v-list-item--link:before{
		color: #FEE108!important;
	}
	.theme--dark .v-navigation-drawer :hover {
	    background-color: #212121;
	}
</style>
<script>
  import Vue from 'vue'
  import config from '@/config.js'
  import Logo from '@/components/common/Logo';
  import { mapGetters } from "vuex";
  export default {
    data() {
      return {
        sip: config.sip,
        fi: config.fi,
        iobots: config.domainName,
        bottomNav: 4
      }
    },
    props: {
      isShowMenuSidebar: Boolean,
      isShowMenuSideLeftbar: Boolean
    },
    computed: {
      ...mapGetters(["user", "responsive", "darkMode", "defaultExchange"]),
      isLogin() {
        if (this.user.token) {
          return true;
        } else {
          return false;
        }
      }
    },
	components:{ Logo },
    created() {
    },
    mounted() {

    },
    watch: {
    },
    methods: {
      // 初始化标题
      initHeaderTitle(){
        this.$store.dispatch("signalHeaderTitleHandler", 'signals');
        this.$store.dispatch("tradingHeaderTitleHandler", 'trading');
      },
      setSignalHeaderTitle(msg){
        this.$store.dispatch("signalHeaderTitleHandler", msg);
        this.$store.dispatch("tradingHeaderTitleHandler", 'trading');
      },
      setTradingHeaderTitle(msg){
        this.$store.dispatch("signalHeaderTitleHandler", 'signals');
        this.$store.dispatch("tradingHeaderTitleHandler", msg);
      },
      // 跳转资产管理
      gotoAssets() {
        if (this.user.token) {
              this.$router.push({name: 'assets' });
          } else {
            this.gotoSignIn();
          }
      },
      // 跳转到策略
      goToStrategy(){
        if (this.user.token) {
            if (this.user.role == "TRADER") {
                this.$store.dispatch("mobileBtnPageHandler",4);
                this.$router.push({ name: "strategy" });
                this.initHeaderTitle();
            } else {
                this.$store.dispatch("snackbarMessageHandler", this.$t("header.snackbarMessage"));
            }
        } else {
          this.gotoSignIn();
        }
                  
      },
      // 跳转到Renko图表
      gotoRenkoCharts(){
        if (this.user.token) {
            if (this.user.role == "TRADER") {
                this.$store.dispatch("mobileBtnPageHandler",4);
                this.$router.push({
                  name: "renkoCharts",
                  params: {
                    currency: "BTC"
                  }
                });
            } else {
              this.$store.dispatch("snackbarMessageHandler", this.$t("header.snackbarMessage"));
            }
        } else {
          this.gotoSignIn();
        }
      },
      // 跳转Signals
      goToSignals() {
        this.$store.dispatch("mobileBtnPageHandler",1);
          if (this.user.token) {
              this.goToMoreSignals();
          } else {
              this.$router.push({ name: "signals" });
          }
      },
      goToMoreSignals() {
        this.$store.dispatch("mobileBtnPageHandler",1);
          this.$router.push({ name: "moreSignals" });
      },
      // 跳转到MarketTypes(
      goToMarketTypes(){
        this.$router.push({name: "marketTypes"});
        this.$store.dispatch("signalHeaderTitleHandler", 'signals');
        this.$store.dispatch("tradingHeaderTitleHandler", 'trading');
        this.$store.dispatch("performanceHeaderTitleHandler", 'marketTypes');
      },
      // 跳转到SignalsHistory
      goToSignalsHistory() {
        this.$router.push({ name: "signalHistory", params: { symbol: "BTCUSD" } });
        this.$store.dispatch("mobileBtnPageHandler", 1);
        this.$store.dispatch('signalPageHandler', this.$t("menu.signalsHistory"))
      },
      // 跳转到Logs
      gotoLogs(){
          if (this.user.token) {
              if (this.user.role == "TRADER") {
                  this.$router.push({ name: "logs" });
                  this.initHeaderTitle();
              } else {
                  this.$store.dispatch("snackbarMessageHandler", this.$t("header.snackbarMessage"));
              }
          } else {
              this.gotoSignIn();
          }
      },
      // 跳转到Spot
      goToSpot() {
        if (this.user.token) {
            if (this.user.role == "TRADER") {
                this.$router.push({ name: "spotTrading", params: { exchangeCode: this.defaultExchange, symbol: "BTC-USDT" } });
                this.$store.dispatch("signalHeaderTitleHandler", 'signals');
                this.$store.dispatch("tradingHeaderTitleHandler", 'spot');
                this.$store.dispatch("performanceHeaderTitleHandler", 'performance');
            } else {
                this.$store.dispatch("snackbarMessageHandler", this.$t("header.snackbarMessage"));
            }
        } else {
            this.gotoSignIn();
        }
      },
      // 跳转到goToFutures
      goToFutures() {
          if (this.user.token) {
              if (this.user.role == "TRADER") {
                  this.$router.push({ name: "futuresTrading", params: { exchangeCode: this.defaultExchange, symbol: "PI_BTCUSD" } });
                  this.$store.dispatch("signalHeaderTitleHandler", 'signals');
                  this.$store.dispatch("tradingHeaderTitleHandler", 'futures');
                  this.$store.dispatch("performanceHeaderTitleHandler", 'performance');
              } else {
                  this.$store.dispatch("snackbarMessageHandler", this.$t("header.snackbarMessage"));
              } 
          } else {
              this.gotoSignIn();
          }
      },
      // 跳转到USDT永续合约
      goToSwapUsdt() {
          if (this.user.token) {
              if (this.user.role == "TRADER") {
                  this.$router.push({ name: "swapUsdtTrading", params: { exchangeCode: this.defaultExchange, symbol: "BTC-USDT-SWAP" } });
                  this.$store.dispatch("signalHeaderTitleHandler", 'signals');
                  this.$store.dispatch("tradingHeaderTitleHandler", 'swapUsdt');
                  this.$store.dispatch("performanceHeaderTitleHandler", 'performance');
                  this.$store.dispatch("mobileBtnPageHandler",0);
              } else {
                  this.$store.dispatch("snackbarMessageHandler", this.$t("header.snackbarMessage"));
              }
          } else {
            this.gotoSignIn();
          }
      },
      //退出
      signOut() {
        // localStorage.clear();
        localStorage.removeItem("iobots-user");
        localStorage.removeItem("iobots-role");
        localStorage.removeItem("iobots-coinSummary");
        localStorage.removeItem("iobots-usdtSummary");
        localStorage.removeItem("iobots-assetInfo");
        this.$router.push({
          name: 'home'
        });
        this.$router.go();
      },
      //路由到页面
      routerPage(name) {
        switch (name) {
          case "account":
            this.$router.push({
              name: 'account'
            });
            break;
          case "profile":
            this.gotoSipProfile();
            break;
          case "signOut":
            this.signOut();
            break;
          case "signIn":
            this.$router.push({
              name: 'signIn'
            });
          default:
            break;
        }
      },
      // 跳转到Quasar Fund
      goToQuasarFund(){
        this.$router.push({name: "quasarFund"});
        this.$store.dispatch("mobileBtnPageHandler", 1);
        this.$store.dispatch('signalPageHandler', this.$t("menu.quasarFund"))
      },
      // 跳转到DEFY
      goToDefyHedgedPool(){
        this.$router.push({name: "defyHedgedPool"});
        this.$store.dispatch("mobileBtnPageHandler", 1);
        this.$store.dispatch('signalPageHandler', this.$t("menu.defyHedgedPool"))
      },
      // 跳转到登录页面
      gotoSignIn() {
        this.$store.dispatch( "snackbarMessageHandler", this.$t("header.signInMessage"));
        this.$router.push({ name: "signIn" });
      },
      // 跳转到Model Portfolio
      goToModelPortfolio(){
        this.$router.push({name: "modelPortfolio"});
        this.$store.dispatch("mobileBtnPageHandler", 1);
        this.$store.dispatch('signalPageHandler', this.$t("menu.modelPortfolio"))
      },
      goToHistoricalPortfolioResults(){
        this.$router.push({name: "historicalPortfolioResults"});
        this.$store.dispatch("indicatorsHeaderTitleHandler", 'indicators');
        this.$store.dispatch("signalHeaderTitleHandler", 'signals');
        this.$store.dispatch("tradingHeaderTitleHandler", 'trading');
        this.$store.dispatch("performanceHeaderTitleHandler", 'historicalPortfolioResults');
      },
      goToSyntheticIndices(){
        this.$router.push({name: "syntheticIndices"});
        this.$store.dispatch("indicatorsHeaderTitleHandler", 'indicators');
        this.$store.dispatch("signalHeaderTitleHandler", 'signals');
        this.$store.dispatch("tradingHeaderTitleHandler", 'trading');
        this.$store.dispatch("performanceHeaderTitleHandler", 'syntheticIndices');
      },
      goToTradingSignalResults(){
        this.$router.push({name: "tradingSignalResults"});
        this.$store.dispatch("indicatorsHeaderTitleHandler", 'indicators');
        this.$store.dispatch("signalHeaderTitleHandler", 'signals');
        this.$store.dispatch("tradingHeaderTitleHandler", 'trading');
        this.$store.dispatch("performanceHeaderTitleHandler", 'tradingSignalResults');
      },
    }
  }
</script>
