import Vue from 'vue';
import { mapGetters } from "vuex";
import config from '../config.js';
import axios from 'axios';
import $ from 'jquery';

// 参考https://cn.vuejs.org/v2/guide/plugins.html
export default{
	install(Vue){
		// 1. 添加全局方法或属性
		Vue.myGlobalMethod = function () {
		    // 逻辑...
		}

		// 2. 添加全局资源
		Vue.directive('my-directive', {
		    bind (el, binding, vnode, oldVnode) {
		    	// 逻辑...
			}
		    // ...
		})

		// 3. 注入组件选项
		Vue.mixin({
		    created: function () {

		    },
		    computed:{
	            ...mapGetters(['user', 'selectedTheme', 'time'])
	      	},
		    methods: {
		    	// 定义加载数据的函数
		    	// 查询当前登录的用户对象
		    	getMe(){
		    		if (this.user != null && this.user.token != null) {
		    			let api = config.baseUrl + '/user/private/getMe';
			             this.$http.get(api).then(response => {
			                let data = response.data;
			                if (data.code == 200) {
			                    this.$store.dispatch('userHandler', data.result);
			                    // locale不能从mapGetters取值，为什么？
			                    // 我也不知道，反正就一取值报错
			                    let locale = this.$store.getters.locale;
			                    // 是否需要刷新
			                    let isRefresh = false;
			                    if (data.result.locale != locale) {
			                    	isRefresh = true;
			                    	this.$store.dispatch('localeHandler', data.result.locale);
			                    }
			                    let timeZone = data.result.timeZone;
			                    let time = 'localTime';
								if (timeZone == 'UTC') {
									time = 'UTCTime';
								} else if (timeZone == 'LT') {
									time = 'localTime';
								}
			                    if (time != this.time) {
			                    	isRefresh = true;
			                    	this.$store.dispatch('timeZoneHandler', data.result.timeZone);
			                    }
			                    if (isRefresh) {
			                    	this.$router.go(0);
			                    }
			                } else {
			                    this.$store.dispatch('userHandler', []);
			                    this.$store.dispatch('localeHandler', 'en-US');
			                    this.$store.dispatch('timeZoneHandler', 'LT');
			                }
			             }, error => {
			                this.$store.dispatch('userHandler', []);
			                this.$store.dispatch('localeHandler', 'en-US');
			                this.$store.dispatch('timeZoneHandler', 'LT');
			             });
		    		}
		    	},
		    	// 查询支持的交易所集合
		    	getExchanges(){
		    		let api = config.baseUrl + '/exchange/public/getExchanges';
		            this.$http.get(api).then(response => {
		                let data = response.data;
		                if (data.code == 200) {
		                	// 把未启用的交易所删除
		                	for (let i = data.result.length - 1; i >= 0; i--) {
		                		if (!data.result[i].enabled) {
						            data.result.splice(i, 1);
						        }
		                	}
		                  this.$store.dispatch('exchangesHandler', data.result);
		                } else {
		                  this.$store.dispatch('exchangesHandler', []);
		                }
		            }, error => {
		              this.$store.dispatch('exchangesHandler', []);
		            });
		    	},
		    	// 交易类别：spot/futures
		    	getTradingCategories(){
		    		let api = config.baseUrl + '/exchange/public/getTradingCategories';
		            this.$http.get(api).then(response => {
		                let data = response.data;
		                if (data.code == 200) {
		                	let arr = new Array();
		                  	for (let i = 0, len = data.result.length; i < len; i++) {
		                  		arr[i] = {
		                  			text: data.result[i].toUpperCase(),
		                  			value: data.result[i]
		                  		}
		                  	}
		                  this.$store.dispatch('tradingCategoriesHandler', arr);
		                } else {
		                  this.$store.dispatch('tradingCategoriesHandler', []);
		                }
		            }, error => {
		              this.$store.dispatch('tradingCategoriesHandler', []);
		            });
		    	},
		    	// 查询支持的信号的符号集合
		    	getSignalSymbols(){
		    		let api = config.baseUrl + '/symbol/public/getSignalSymbols';
		            this.$http.get(api).then(response => {
		                let data = response.data;
		                if (data.code == 200) {
		                  this.$store.dispatch('signalSymbolsHandler', data.result.symbols);
		                  this.$store.dispatch('signalDecimalNumbersHandler', data.result.decimalNumbers);
		                } else {
		                  this.$store.dispatch('signalSymbolsHandler', []);
		                  this.$store.dispatch('signalDecimalNumbersHandler', []);
		                  if(data.code != 204){
		                  	this.$store.dispatch('snackbarMessageHandler', data.message);
		                  }
		                }
		                
		            }, error => {
		              this.$store.dispatch('signalSymbolsHandler', []);
		              this.$store.dispatch('signalDecimalNumbersHandler', []);
		            });
		    	},
		    	// 查询支持的现货符号集合
		    	getSpotSymbols(){
		    		let api = config.baseUrl + '/symbol/public/getSpotSymbols';
		            this.$http.get(api).then(response => {
		                let data = response.data;
		                if (data.code == 200) {
		                  this.$store.dispatch('spotSymbolsHandler', data.result.symbols);
		                  this.$store.dispatch('exchangeSupportedSpotSymbolsHandler', data.result.exchange);
		                  this.$store.dispatch('spotSymbolInfosHandler', data.result.symbolInfos);
		                } else {
		                  this.$store.dispatch('spotSymbolsHandler', []);
		                  this.$store.dispatch('exchangeSupportedSpotSymbolsHandler', []);
		                  this.$store.dispatch('spotSymbolInfosHandler', []);
		                  if(data.code != 204){
		                  	this.$store.dispatch('snackbarMessageHandler', data.message);
		                  }
		                }
		            }, error => {
		              	this.$store.dispatch('spotSymbolsHandler', []);
		              	this.$store.dispatch('exchangeSupportedSpotSymbolsHandler', []);
		              	this.$store.dispatch('spotSymbolInfosHandler', []);

		            });
		    	},
		    	// 查询支持的期货符号集合
		    	getFuturesSymbols(){
		    		let api = config.baseUrl + '/symbol/public/getFuturesSymbols';
		            this.$http.get(api).then(response => {
		                let data = response.data;
		                if (data.code == 200) {
		                  this.$store.dispatch('futuresSymbolsHandler', data.result.symbols);
		                  this.$store.dispatch('futuresDecimalNumbersHandler', data.result.decimalNumbers);
		                  this.$store.dispatch('futuresBaseCurrenciesHandler', data.result.baseCurrencies);
		                  this.$store.dispatch('exchangeSupportedFuturesSymbolsHandler', data.result.exchange);
						  this.$store.dispatch('futuresSymbolInfosHandler', data.result.symbolInfos);
		                } else {
		                  this.$store.dispatch('futuresSymbolsHandler', []);
		                  this.$store.dispatch('futuresDecimalNumbersHandler', []);
		                  this.$store.dispatch('futuresBaseCurrenciesHandler', []);
		                  this.$store.dispatch('exchangeSupportedFuturesSymbolsHandler', []);
						  this.$store.dispatch('futuresSymbolInfosHandler', []);
		                  if(data.code != 204){
		                  	this.$store.dispatch('snackbarMessageHandler', data.message);
		                  }
		                }
		            }, error => {
		              this.$store.dispatch('futuresSymbolsHandler', []);
		              this.$store.dispatch('futuresDecimalNumbersHandler', []);
		              this.$store.dispatch('futuresBaseCurrenciesHandler', []);
		              this.$store.dispatch('exchangeSupportedFuturesSymbolsHandler', []);
					  this.$store.dispatch('futuresSymbolInfosHandler', []);
		            });
		    	},
		    	// 查询支持的永续合约USDT符号集合
		    	getSwapUsdtSymbols(){
		    		let api = config.baseUrl + '/symbol/public/getSwapUsdtSymbols';
		            this.$http.get(api).then(response => {
		                let data = response.data;
		                if (data.code == 200) {
		                	this.$store.dispatch('swapUsdtSymbolsHandler', data.result.symbols);
		                  	this.$store.dispatch('exchangeSupportedSwapUsdtSymbolsHandler', data.result.exchange);
		                  	this.$store.dispatch('swapUsdtSymbolInfosHandler', data.result.symbolInfos);
		                } else {
		                  	this.$store.dispatch('swapUsdtSymbolsHandler', []);
		                  	this.$store.dispatch('exchangeSupportedSwapUsdtSymbolsHandler', []);
		                  	this.$store.dispatch('swapUsdtSymbolInfosHandler', []);
		                  	if(data.code != 204){
		                  		this.$store.dispatch('snackbarMessageHandler', data.message);
		                  	}
		                }
		            }, error => {
		            	this.$store.dispatch('swapUsdtSymbolsHandler', []);
	                  	this.$store.dispatch('exchangeSupportedSwapUsdtSymbolsHandler', []);
	                  	this.$store.dispatch('swapUsdtSymbolInfosHandler', []);
		            });
		    	},
				// 查询订单类别
				getOrderCategories(){
					let api = config.baseUrl + '/trading/public/getOrderCategories';
					this.$http.get(api).then(response => {
						let data = response.data;
						if (data.code == 200) {
							this.$store.dispatch('orderCategoriesHandler', data.result);
						} else {
							this.$store.dispatch('orderCategoriesHandler', []);
							if(data.code != 204){
		                  		this.$store.dispatch('snackbarMessageHandler', data.message);
		                  	}
						}
					}, error => {
						this.$store.dispatch('orderCategoriesHandler', []);
					});
				},
				// 查询订单策略
				getOrderStrategies(){
					let api = config.baseUrl + '/trading/public/getOrderStrategies';
					this.$http.get(api).then(response => {
						let data = response.data;
						if (data.code == 200) {
							this.$store.dispatch('orderStrategiesHandler', data.result);
						} else {
							this.$store.dispatch('orderStrategiesHandler', []);
							if(data.code != 204){
			                  	this.$store.dispatch('snackbarMessageHandler', data.message);
			                }
						}
					}, error => {
						this.$store.dispatch('orderStrategiesHandler', []);
					});
				},
				// 查询策略
				getStrategies(){
					if (this.user != null && this.user.token != null) {
						let api = config.baseUrl + '/strategy/private/strategies/trading';
						this.$http.get(api).then(response => {
							let data = response.data;
							if (data.code == 200) {
								this.$store.dispatch('strategiesHandler', data.result);
							} else {
								this.$store.dispatch('strategiesHandler', []);
								if(data.code != 204){
				                  	this.$store.dispatch('snackbarMessageHandler', data.message);
				                }
							}
						}, error => {
							this.$store.dispatch('strategiesHandler', []);
						});
					}
				},
		    	// 初始化颜色风格
		    	initStyle(){
					let colorStyle = localStorage.getItem("iobots-colorStyle");
					if (colorStyle) {
						this.$store.dispatch("changeColorStyleHandler", colorStyle);
						this.$vuetify.theme = this.$store.getters.selectedTheme.theme;
					}else{
						this.$store.dispatch("changeColorStyleHandler", "greenUpRedDown");
						this.$vuetify.theme = this.$store.getters.selectedTheme.theme;
					}
		    	},
				// 查询合约面值
				getContractFaceValue(){
					let api = config.baseUrl + '/symbol/public/getContractFaceValue';
					this.$http.get(api).then(response => {
						let data = response.data;
						if (data.code == 200) {
							this.$store.dispatch('contractFaceValueHandler', data.result);
						} else {
							this.$store.dispatch('contractFaceValueHandler', []);
							if(data.code != 204){
		                  		this.$store.dispatch('snackbarMessageHandler', data.message);
		                  	}
						}
					}, error => {
						this.$store.dispatch('contractFaceValueHandler', []);
					});
				},
		    	// 初始化自适应屏幕
		    	initResponsive(){
					var ua = navigator.userAgent,
			        isWindowsPhone = /(?:Windows Phone)/.test(ua),
			        isSymbian = /(?:SymbianOS)/.test(ua) || isWindowsPhone,
			        isAndroid = /(?:Android)/.test(ua),
					isFireFox = /(?:Firefox)/.test(ua),
					isIPad = (ua.indexOf("iPad") > 0),  
					isChrome = /(?:Chrome|CriOS)/.test(ua), 
					// isSmallScreen = (windows.screen.width <= 1336 && windows.screen.width > 736),  
					isSmallScreen = (document.body.clientWidth <= 1200),  
					isTablet = /(?:iPad|PlayBook)/.test(ua) || (isAndroid && !/(?:Mobile)/.test(ua)) || (isFireFox && /(?:Tablet)/.test(ua)),
					isPhone = /(?:iPhone)/.test(ua) && !isTablet,
					isPC = !isPhone && !isAndroid && !isSymbian && !isSmallScreen;
			        if ((isAndroid || isPhone) && document.body.clientWidth < 700 ) {
				    	this.$store.dispatch('responsiveHandler', 'mobile');
				    } else if (isTablet || isIPad || isSmallScreen) {
				    	this.$store.dispatch('responsiveHandler', 'mobile');
				    } else if(isPC) {
				    	this.$store.dispatch('responsiveHandler', 'PC');
				    } else {
				    	this.$store.dispatch('responsiveHandler', 'mobile');
					}
					// console.log(document.body.clientWidth)
			    },
				// 查询消息通知数量
				getNewNotificationNum(){
				  if (this.user != null && this.user.token != null) {
					this.$http.get(config.baseUrl + '/notification/private/getNewNotificationNum').then(response => {
					  let data = response.data;
					  if (data.code == 200) {
						this.$store.dispatch('notificationNumHandler', data.result);
					  }
					});
				  }
				},
				// 获取当前国家代码
				getCountryCode(){
					var ip = null;
					let _this = this;
					$.getScript("https://pv.sohu.com/cityjson?ie=utf-8",function(data){  
							ip = returnCitySN["cip"];
							_this.$store.dispatch('ipHandler', ip);
							let api = "https://iob.community/iob/visit/open/getCountryCode?ip="+ip;
							axios.get(api).then(response=>{
								let data = response.data
								if(0 == data.resCode){
									_this.$store.dispatch('countryCodeHandler', data.resMsg);
								}
							})
					});
			    },
			    // 监听网络连接状态
	            checkNetworkStatus() {
	                let that = this;
	                window.addEventListener('online',  function(){
	                    that.$store.dispatch('networkStatusHandler', true);
	                    
	                });
	                window.addEventListener('offline', function(){
	                    that.$store.dispatch('networkStatusHandler', false);
	                });
				},
				// 查询Renko的符号集合
				getRenkoSymbols(){
					let api = config.baseUrl + '/symbol/public/getRenkoSymbols';
					this.$http.get(api).then(response => {
							let data = response.data;
							if (data.code == 200) {
								this.$store.dispatch('renkoSymbolsHandler', data.result.symbols);
								this.$store.dispatch('renkoDecimalNumbersHandler', data.result.decimalNumbers);
							} else {
								this.$store.dispatch('renkoSymbolsHandler', []);
								this.$store.dispatch('renkoDecimalNumbersHandler', []);
								if(data.code != 204){
		                  			this.$store.dispatch('snackbarMessageHandler', data.message);
		                  		}
							}
					}, error => {
						this.$store.dispatch('renkoSymbolsHandler', []);
						this.$store.dispatch('renkoDecimalNumbersHandler', []);
					});
				},
				// 查询所有货币集合
				getCurrencies(){
					let api = config.baseUrl + '/symbol/public/getCurrencies';
					this.$http.get(api).then(response => {
						let data = response.data;
						if (data.code == 200) {
							this.$store.dispatch('currenciesHandler', data.result);
						} else {
							this.$store.dispatch('currenciesHandler', []);
							if(data.code != 204){
		                  		this.$store.dispatch('snackbarMessageHandler', data.message);
		                  	}
						}
					}, error => {
						this.$store.dispatch('currenciesHandler', []);
					});
				},
				// 查询策略颜色
				getStrategyColors(){
					if (this.user != null && this.user.token != null) {
						let api = config.baseUrl + '/strategy/private/colors';
						this.$http.get(api).then(response => {
							let data = response.data;
							if (data.code == 200) {
								this.$store.dispatch('strategyColorsHandler', data.result);
							} else {
								this.$store.dispatch('strategyColorsHandler', []);
								if(data.code != 204){
		                  			this.$store.dispatch('snackbarMessageHandler', data.message);
		                  		}
							}
						}, error => {
							this.$store.dispatch('strategyColorsHandler', []);
						});
					}
				},
				// 查询信号周期
				getSignalIntervals(){
					let api = config.baseUrl + '/signal/public/intervals';
					this.$http.get(api).then(response => {
						let data = response.data;
						if (data.code == 200) {
							this.$store.dispatch('signalIntervalsHandler', data.result);
						} else {
							this.$store.dispatch('signalIntervalsHandler', []);
							if(data.code != 204){
		                  		this.$store.dispatch('snackbarMessageHandler', data.message);
		                  	}
						}
					}, error => {
						this.$store.dispatch('signalIntervalsHandler', []);
					});
				},
				// 查询投资组合历史数据图表
				getPortfolioHistoryLineChart(){
					let api = config.baseUrl + '/signal/public/getPortfolioResult';
			        this.$http.get(api).then(res => {
			          let data = res.data;
			          if (data.code == 200) {
			            var roeArr = [];
			            roeArr.push(Number((data.result.day30Total.roe * 100).toFixed(2)))
			            roeArr.push(Number((data.result.day60Total.roe * 100).toFixed(2)))
			            roeArr.push(Number((data.result.day90Total.roe * 100).toFixed(2)))
			            roeArr.push(Number((data.result.day180Total.roe * 100).toFixed(2)))
			            roeArr.push(Number((data.result.day360Total.roe * 100).toFixed(2)))
			            roeArr.push(Number((data.result.sinceInceptionTotal.roe * 100).toFixed(2)))
			            let date = ["30 Days","60 Days","1 Quarter","6 Months","1 Year","Since Inception"];
			            let portfolioRoeHistoryChart = { 'date': date, 'data': roeArr };
			            this.$store.dispatch('portfolioRoeHistoryChartHandler', portfolioRoeHistoryChart);
			          }
			        });
				},
				// 查询系统版本号
				getVersion(){
					let api = config.baseUrl + '/system/public/version/iobots';
			        this.$http.get(api).then(res => {
			          let data = res.data;
			          if (data.code == 200) {
			            this.$store.dispatch('versionHandler', data.result);
			          }
			        });
				},
				// 查询MarketType的货币
				getMarketTypeCryptoCurrencies(){
					let api = config.baseUrl + '/symbol/public/currencies/markettype/crypto';
			        this.$http.get(api).then(res => {
			          	let data = res.data;
			          	if (data.code == 200) {
							this.$store.dispatch('marketTypeCryptoCurrenciesHandler', data.result);
						} else {
							this.$store.dispatch('marketTypeCryptoCurrenciesHandler', []);
							if(data.code != 204){
		                  		this.$store.dispatch('snackbarMessageHandler', data.message);
		                  	}
						}
			        });
				}
		  	}
		})
		// 4. 添加实例方法
		Vue.prototype.init = function () {
		    // 在这里加载所需要的初始化数据，放入缓存以便使用
		    // 加载用户信息
		    this.getMe();
		    // 查询支持的交易所
		    this.getExchanges();
		    // 查询交易类别
		    this.getTradingCategories();
		    // 查询支持的信号的符号集合
		    this.getSignalSymbols();
		    // 查询支持的现货符号集合
		    this.getSpotSymbols();
		    // 查询支持的期货符号集合
		    this.getFuturesSymbols();
		    // 查询支持的永续USDT合约符号集合
		    this.getSwapUsdtSymbols();
	        // 查询订单类别
	        this.getOrderCategories();
	        // 查询订单策略
	        this.getOrderStrategies();
	        // 查询策略
	        this.getStrategies();
	        // 查询合约面值
	        this.getContractFaceValue();
		    // 初始化加载颜色
		    this.initStyle();
		    // 初始化自适应屏幕
		    this.initResponsive();
	        // 查询消息通知数量
	        this.getNewNotificationNum();
	        // 获取当前国家代码
	        this.getCountryCode();
	        // 监听网络连接状态
			this.checkNetworkStatus();
			// 查询Renko的符号集合
			this.getRenkoSymbols();
			// 查询所有货币集合
			this.getCurrencies();
			// 查询策略颜色
			this.getStrategyColors();
			// 查询信号周期
			this.getSignalIntervals();
			// 查询投资组合历史数据图表
			this.getPortfolioHistoryLineChart();
			// 查询系统版本号
			this.getVersion();
			// 查询MarketType的货币
			this.getMarketTypeCryptoCurrencies();
		}
	}
}
