import Vue from 'vue'

let SignalInterval = {
    //转换标题
    format: function(signalInterval){
      try {
        switch(signalInterval){
          case 'daily': return window.vm.$t('signalInterval.daily');
          case 'weekly': return window.vm.$t('signalInterval.weekly');
          default : return signalInterval;
        }
      } catch(error) {
        return signalInterval;
      }
    }
}

Vue.directive('signalInterval', {
  bind: function(el, binding, vnode){
    let timestamp = new Date().getTime();
    // 给vnode更新key，用于数据更新时重新渲染改节点元素
    vnode.key = timestamp;
    el.innerHTML = SignalInterval.format(binding.value);
  },
  unbind: function(el){

  }
});

export default SignalInterval
