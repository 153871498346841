<template>
  <div class="right-hidden">
    <!-- 第一行 是否已登录 -->
    <template>
      <v-card flat tile>
        <v-container v-if="!isLogin">
          <v-col cols="12" class="pa-1">
            <div class="lightred--text fs-16 font-weight-bold">
              <span>{{$t("right.signinHints")}}</span>
            </div>
          </v-col>
          <v-col cols="8" class="pa-1">
            <span>
              <a
                :href="SIP+'#/register'"
                target="_blank"
                class="del-underline"
              >{{$t("right.signup")}}</a>
              <span>&nbsp;/&nbsp;</span>
              <a :href="iobots+'/user/sign-in'" class="mr-3 del-underline">{{$t("right.login")}}</a>
            </span>
          </v-col>
        </v-container>
      </v-card>
    </template>

    <!-- 第二行 IOB LLC -->
    <template>
      <v-card class="mt-3" flat tile>
        <v-container v-if="!isLogin">
          <!-- <v-layout row wrap> -->
          <v-col cols="12" class="pa-1">
            <span class="fs-16 font-weight-bold lightred--text">{{$t("right.iobllc")}}</span>
          </v-col>
          <v-col cols="8" class="pa-1">
            <a :href="SIP" target="_blank" class="mr-3 del-underline">{{$t("right.sip")}}</a>
          </v-col>
          <v-col cols="8" class="pa-1">
            <a
              :href="SIP+'/#/about'"
              target="_blank"
              class="mr-3 del-underline"
            >{{$t("right.about")}}</a>
          </v-col>
          <!-- <v-flex xs12 md8 lg12 class="pa-1">
               <a :href="SIP+'/about/jobs'" target="_blank" class="mr-3 del-underline">{{$t("right.jobs")}}</a>
          </v-flex>-->
          <v-col cols="8" class="pa-1">
            <a
              :href="SIP+'/#/ir'"
              target="_blank"
              class="mr-3 del-underline"
            >{{$t("right.investors")}}</a>
          </v-col>
          <v-col cols="8" class="pa-1">
            <a
              :href="SIP+'/#/about/contact'"
              target="_blank"
              class="mr-3 del-underline"
            >{{$t("right.contact")}}</a>
          </v-col>

          <!-- <v-flex xs12 md8 lg12 class="pa-1">
              <span class="font-14 fw-500 pointer" @click="gotoIOBllcAdvisors">
                Advertise
              </span>
          </v-flex>-->
          <!-- </v-layout> -->
        </v-container>
      </v-card>
    </template>
  </div>
</template>
<script>
import Vue from "vue";
import config from "@/config.js";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      SIP: config.sip,
      iobots: config.domainName
    };
  },
  computed: {
    ...mapGetters(["user"]),
    // 是否登录
    isLogin() {
      if (this.user.token) {
        return true;
      } else {
        return false;
      }
    }
  },
  created() {},
  mounted() {},
  watch: {},
  methods: {
    // gotoIOBllcAdvisors() {
    //   window.open('https://iob.llc/about/contact-us/', '_blank');
    // },
    // gotoIOBllcem() {
    //   window.open('https://iob.llc/about/events-media/', '_blank');
    // },
    // gotoIOBllcJobs() {
    //   window.open('https://iob.llc/about/jobs/', '_blank');
    // },
    // gotoIOBllcir() {
    //   window.open('https://iob.llc/ir/', '_blank');
    // },
    // gotoIOBllcAbout() {
    //   window.open('https://iob.llc/about/', '_blank');
    // },
    // gotoRegister() {
    //   window.open(this.SIP + "#/register")
    // },
    gotoSIP() {
      window.open(this.SIP, "_blank");
    },
    login() {
      this.$router.push({
        name: "signIn"
      });
    }
  }
};
</script>
