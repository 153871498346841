<template>
  <div>
   <v-container fluid class="grid-list-xl pl-0 pt-0">
     <v-row class="mx-4">
      <v-col cols="12" sm="12" md="12">
        <h5 class="mb-3">
         {{$t("terms.title")}}
        </h5>
        <div class="fs-12 mt-2">{{$t("terms.termsConditionsTxt1")}}</div>
        <div class="fs-12 mt-2">{{$t("terms.termsConditionsTxt2")}}</div>
        <div class="fs-12 mt-2">{{$t("terms.termsConditionsTxt3")}}</div>
        <div class="fs-12 mt-2">{{$t("terms.termsConditionsTxt4")}}</div>
        <div class="fs-12 mt-2">{{$t("terms.termsConditionsTxt5")}}</div>
        <h5 class="mt-4">{{$t("terms.noInvestmentAdvice")}}</h5>
        <div class="fs-12 mt-2">{{$t("terms.noInvestmentAdviceTxt")}}</div>
        <div class="fs-12 mt-2">{{$t("terms.noInvestmentAdviceTxt1")}}</div>
        <h5 class="fs-14 mt-4">{{$t("terms.investmentRisks")}}</h5>
        <div class="fs-12 mt-2">{{$t("terms.investmentRisksTxt")}}</div>
        <h5 class="fs-12 mt-4">{{$t("terms.TradingRisks")}}</h5>
        <div class="fs-12 mt-2">{{$t("terms.TradingRisksTxt")}}</div>
      </v-col>
     </v-row>
     </v-container>
  </div>
</template>

<script>
  import Vue from 'vue'
  import config from '@/config.js'
    export default {
        data() {
        return {

        };
        },
        computed: {

        },
        created() {

        },
        mounted() {

        },
        watch: {

        },
        methods: {
        }
    }

</script>
