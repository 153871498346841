<template>
  <div>
    <v-app-bar dense class="Vuely-toolbar" fixed app :clipped-left="$vuetify.breakpoint.mdAndUp">
   <!--     <v-container > -->
         <!--   <v-row no-gutters v-if="responsive == 'PC'"> -->
        <v-col class="w-20 mt-2 pt-2 pl-1" v-if="responsive == 'PC'">
            <v-navigation-drawer expand-on-hover width="160" mini-variant-width="43" :class="[darkMode?'gray-dark':'gray-lighter']">
                <div class="pointer">
                    <a :href="iobots">
                        <logo type="twoLines" color="PCsolid"></logo>
                    </a>
                </div>
            </v-navigation-drawer>
        </v-col>
				<v-col class="d-custom-flex ml-n8 px-0" v-if="responsive == 'PC'">
          <!-- 指数 -->
          <v-menu open-on-hover v-if="responsive =='PC'" offset-y origin="left top" z-index="99" class="notification-dropdown pl-2" content-class="notification-dropdown" transition="slide-y-transition" fixed :open-delay="100" :max-width="220">
            <template v-slot:activator="{ on }">
					    <v-btn text tile v-on="on" class="text-none">
					      <span v-header-title="indexHeaderTitle"></span>
					      <v-icon>mdi-chevron-down</v-icon>
					    </v-btn>
					  </template>
					  <div class="dropdown-content">
					    <v-list class="dropdown-list">
					      <v-list-item class="pointer" @click="goToSyntheticIndices">
                  <span class="pointer">
                    <span>
                      <v-icon small>mdi-calendar-text</v-icon>
                    </span>
                    <span class="ml-3">{{$t("header.syntheticIndices")}}</span>
                  </span>
                </v-list-item>
					    </v-list>
					  </div>
          </v-menu>
          <!-- 指标 -->
					<v-menu open-on-hover v-if="responsive =='PC'" offset-y origin="left top" z-index="99" class="notification-dropdown pl-2" content-class="notification-dropdown" transition="slide-y-transition" fixed :open-delay="100" :max-width="160">
					  <template v-slot:activator="{ on }">
					    <v-btn text tile v-on="on" class="text-none">
					      <span v-header-title="indicatorsHeaderTitle"></span>
					      <v-icon>mdi-chevron-down</v-icon>
					    </v-btn>
					  </template>
					  <div class="dropdown-content">
					    <v-list class="dropdown-list">
					      <v-list-item class="pointer" @click="goToMarketTypes">
					        <span class="pointer">
					          <v-icon small>mdi-shape-plus</v-icon>
					          <span class="ml-3">{{$t("header.marketTypes")}}</span>
					        </span>
					      </v-list-item>
					    </v-list>
					  </div>
					</v-menu>
					<!-- 业绩 -->
					<v-menu open-on-hover v-if="responsive =='PC'" offset-y origin="left top" z-index="99" class="notification-dropdown pl-2" content-class="notification-dropdown" transition="slide-y-transition" fixed :open-delay="100" :max-width="220">
					  <template v-slot:activator="{ on }">
					    <v-btn text tile v-on="on" class="text-none">
					      <span v-header-title="performanceHeaderTitle"></span>
					      <v-icon>mdi-chevron-down</v-icon>
					    </v-btn>
					  </template>
					  <div class="dropdown-content">
					    <v-list class="dropdown-list">
                <v-list-item class="pointer" @click="goToTradingSignalResults">
                  <span class="pointer">
                    <span>
                      <v-icon small>mdi-factory</v-icon>
                    </span>
                    <span class="ml-3">{{$t("header.tradingSignalResults")}}</span>
                  </span>
                </v-list-item>
					      <v-list-item class="pointer" @click="goToSignalsHistory">
					        <span class="pointer">
					          <v-icon small>mdi-sort-variant</v-icon>
					          <span class="ml-3">{{$t("header.signalsHistory")}}</span>
					        </span>
					      </v-list-item>
					      <v-list-item class="pointer" @click="goToDefyHedgedPool">
					        <span class="pointer">
					          <span>
					            <icon symbol="DEFY" color="white" v-if="!darkMode"></icon>
					            <icon symbol="DEFY" color="black" v-if="darkMode"></icon>
					          </span>
					          <span class="ml-4">{{$t("header.defyHedgedPool")}}</span>
					        </span>
					      </v-list-item>
					      <v-list-item class="pointer" @click="goToQuasarFund">
					        <span class="pointer">
					          <v-icon small>icon icon-uniE938</v-icon>
					          <span class="ml-3">{{$t("header.quasarFund")}}</span>
					        </span>
					      </v-list-item>
					      <v-list-item class="pointer" @click="goToModelPortfolio">
					        <span class="pointer">
					          <v-icon small>mdi-table-large</v-icon>
					          <span class="ml-3">{{$t("header.modelPortfolio")}}</span>
					        </span>
					      </v-list-item>
                <!-- <v-list-item class="pointer" @click="goToHistoricalPortfolioResults">
                  <span class="pointer">
                    <span>
                      <v-icon small>mdi-view-module</v-icon>
                    </span>
                    <span class="ml-3">{{$t("header.historicalPortfolioResults")}}</span>
                  </span>
                </v-list-item> -->
					    </v-list>
					  </div>
					</v-menu>
					<!-- 选择期货/现货 -->
					<v-menu open-on-hover v-if="responsive =='PC'" offset-y origin="left top" z-index="99" class="notification-dropdown pl-2" content-class="notification-dropdown" transition="slide-y-transition" fixed :open-delay="100" :max-width="160">
					  <template v-slot:activator="{ on }">
					    <v-btn text tile v-on="on" class="text-none">
					      <span v-header-title="tradingHeaderTitle"></span>
					      <v-icon>mdi-chevron-down</v-icon>
					    </v-btn>
					  </template>
					  <div class="dropdown-content">
					    <v-list class="dropdown-list">
					      <v-list-item class="pointer" @click="goToSpot">
					        <span class="pointer">
					          <v-icon small>mdi-chart-areaspline</v-icon>
					          <span class="ml-3">{{$t("header.spot")}}</span>
					        </span>
					      </v-list-item>
					      <v-list-item class="pointer" @click="goToFutures">
					        <span class="pointer">
					          <v-icon small>mdi-chart-histogram</v-icon>
					          <span class="ml-3">{{$t("header.futures")}}</span>
					        </span>
					      </v-list-item>
					      <v-list-item class="pointer" @click="goToSwapUsdt">
					        <span class="pointer">
					          <v-icon small>mdi-chart-bar</v-icon>
					          <span class="ml-3">{{$t("header.swapUsdt")}}</span>
					        </span>
					      </v-list-item>
					    </v-list>
					  </div>
					</v-menu>
					<!-- assets -->
					<div v-if="responsive == 'PC'" class="ml-3 mt-2">
					  <a :href="assetsUrl" class="del-underline">{{ $t('menu.assets') }}</a>
					</div>
					<!-- iob.fi -->
					<div v-if="responsive == 'PC'" class="ml-10 mt-2">
					  <a :href="fi" target="_blank" class="del-underline">iob.fi</a>
					</div>
					<!-- SiP -->
					<div v-if="responsive == 'PC'" class="mx-10 mt-2">
					  <a :href="sip" target="_blank" class="del-underline">SiP</a>
					</div>
				</v-col>
      <!--      </v-row> -->
      <!--  </v-container> -->
		<v-app-bar-nav-icon class="ml-0" v-if="responsive =='mobile'"  @click="showMenuSideLeftbar"></v-app-bar-nav-icon>
      <div class="site-mobile-logo mt-1 ml-8 align-sm-center justify-center flex-b" v-if="responsive =='mobile'">
        <a :href="iobots" class="del-underline black--text">
          <logo type="twoLines" color="mobileSolid"></logo> 
        </a>
      </div>
      <div class="navbar-right v-breadcrumbs__item">
        <!-- 环境标识 -->
        <div class="font-2x mr-5 upgrade-btn t-none" v-if="responsive =='PC'">
          <span v-if="environment == 'Development'">{{ $t('header.development') }}</span>
          <span v-if="environment == 'Test'">{{ $t('header.test') }}</span>
        </div>
        <!-- 当前时间 -->
        <div class="flex fs-12 t-none" v-if="responsive =='PC'">
          <div v-if="currentUTCTime != null" :class="isUTCTime ? '' : 'grey--text'">{{ $t('header.utcTime') }} :</div>
          <div v-if="currentLocalTime != null" :class="isUTCTime ? 'grey--text' : ''">{{ $t('header.localTime') }} :</div>
        </div>
        <div class="flex fs-12 t-none" v-if="responsive =='PC'">
          <div class="ml-2" :class="isUTCTime ? '' : 'grey--text'" v-week='UTCWeek'></div>
          <div class="ml-2" :class="isUTCTime ? 'grey--text' : ''" v-week='localWeek'></div>
        </div>
        <div class="flex fs-12 t-none" v-if="responsive =='PC'">
          <div class="ml-2" :class="isUTCTime ? '' : 'grey--text'">{{ currentUTCTime }}</div>
          <div class="ml-2" :class="isUTCTime ? 'grey--text' : ''">{{ currentLocalTime }}</div>
        </div>
        <!-- 产品 -->
        <!-- <span class="ml-4">
          <app-product></app-product>
        </span> -->
        <!-- 通知 -->
         <v-tooltip bottom v-if="responsive == 'PC'">
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon small class="pointer ml-4" @click="isShowNoticationSidebar = !isShowNoticationSidebar" v-on="on" v-bind="attrs">
              <v-badge  overlap color="lightred" :value="notificationNum">
                <template v-slot:badge>
                  <span v-if="notificationNum > 0" class="black--text">{{ notificationNum }}</span>
                </template>
                <v-icon v-show="notificationNum > 0" class="animated infinite wobble">mdi-bell-ring-outline</v-icon>
                <v-icon v-show="notificationNum == 0">mdi-bell-outline</v-icon>
              </v-badge>
            </v-btn>
           </template>
            <span>{{ $t('header.notifications') }}</span>
          </v-tooltip>
          <!-- 手机 -->
         <v-btn v-if="responsive == 'mobile'" icon small class="pointer mx-0 px-0" @click="isShowNoticationSidebar = !isShowNoticationSidebar">
            <v-badge  overlap color="lightred" :value="notificationNum">
              <template v-slot:badge>
                <span v-if="notificationNum > 0" class="black--text">{{ notificationNum }}</span>
              </template>
              <v-icon v-show="notificationNum > 0" class="animated infinite wobble">mdi-bell-ring-outline</v-icon>
              <v-icon v-show="notificationNum == 0">mdi-bell-outline</v-icon>
            </v-badge>
          </v-btn>
        <!-- 用户头像 -->
        <v-tooltip bottom v-if="responsive == 'PC'">
          <template v-slot:activator="{ on, attrs }">
            <v-btn small icon class="ml-4 mr-3 pointer" @click="showMenuSidebar" v-on="on" v-bind="attrs">
              <v-avatar v-if="user != null && user.token != null" size="30" color="lightred white--text">
                <v-img v-if="user.avatar != null" :src="user.avatar"></v-img>
                <span v-else>{{ user.username != null ? user.username.substring(0, 1).toLocaleUpperCase() : '' }}</span>
              </v-avatar>
              <v-icon medium v-else>mdi-account-circle</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('header.account') }}</span>
        </v-tooltip>
        <!-- 手机头像 -->
        <v-btn v-if="responsive == 'mobile'" icon class="pointer" @click="showMenuSidebar">
          <v-avatar v-if="user != null && user.token != null" size="30" color="lightred white--text">
            <v-img v-if="user.avatar != null" :src="user.avatar"></v-img>
            <span v-else>{{ user.username != null ? user.username.substring(0, 1).toLocaleUpperCase() : '' }}</span>
          </v-avatar>
          <v-icon medium v-else>mdi-account-circle</v-icon>
        </v-btn>
      </div>
    </v-app-bar>
    <!-- 通知侧边栏 -->
    <v-navigation-drawer fixed right temporary hide-overlay v-model="isShowNoticationSidebar" width="420" class="mt-12">
      <app-notification-menu :isShowNoticationSidebar.sync="isShowNoticationSidebar"></app-notification-menu>
    </v-navigation-drawer>
    <!-- 菜单左边栏 -->
    <v-navigation-drawer fixed left temporary hide-overlay v-model="isShowMenuSideLeftbar" width="320">
      <app-side-left-menu :isShowMenuSideLeftbar.sync="isShowMenuSideLeftbar"></app-side-left-menu>
    </v-navigation-drawer>
      <!-- 菜单侧边栏 -->
    <v-navigation-drawer fixed right temporary v-model="isShowMenuSidebar" width="320">
      <app-side-menu :isShowMenuSidebar.sync="isShowMenuSidebar"></app-side-menu>
    </v-navigation-drawer>
    <!-- 消息通知栏 -->
    <v-snackbar color="grey darken-3 white--text" v-if="responsive == 'PC'" v-model="isShowSnackbar" :timeout="5000">
      {{ snackbarMessage }}
      <v-icon small class="float-right ml-2" @click="closeSnackbarMessage">mdi-close</v-icon>
    </v-snackbar>
    <v-snackbar class="z-index-99 pb-16" color="grey darken-3 white--text" v-if="responsive == 'mobile'" v-model="isShowSnackbar" multi-line :timeout="5000">
      {{ snackbarMessage }}
      <v-icon small class="float-right ml-2" @click="closeSnackbarMessage">mdi-close</v-icon>
    </v-snackbar>
    <v-bottom-sheet v-model="isShowCookieStatement" hide-overlay persistent dense>
      <v-card class="text-center pa-2">
        {{$t("header.cookieStatementMessage")}}
        <a :href="sip+'/#/about/privacy'" class="lightred--text">{{$t("header.privacyPolicy")}}</a>
        {{$t("header.period")}}
        <v-btn class="ml-4" tile outlined text small @click="acceptCookieStatement">{{$t("header.accept")}}</v-btn>
      </v-card>
    </v-bottom-sheet>
    <iobots-web-socket></iobots-web-socket>
  </div>
</template>
<style scoped>
  .theme--dark.v-toolbar {
    background-color: #424242 !important;
    color: #fff;
  }
  .theme--dark.v-sheet {
   background-color: #424242!important;
   border-color: #424242!important;
   color: #fff;
}


</style>
<script>
  import Vue from "vue";
  import config from "@/config.js";
  import { mapGetters } from "vuex";
  import Logo from '@/components/common/Logo';
  import Icon from '@/components/Icon';
  import { setInterval, clearInterval } from 'timers';
  import IobotsWebSocket from "@/websocket/IobotsWebSocket.vue";
  export default {
    data() {
      return {
        environment: config.environment,
        isShowMenuSidebar: false, // 是否显示菜单侧边栏
        isShowMenuSideLeftbar: false, 
        isShowNoticationSidebar: false,
        categoryleft: this.$t("header.signals"),
        category: this.$t("header.trading"),
        symbol: this.$route.params.symbol,
        sip: config.sip,
        fi: config.fi,
        assetsUrl: config.domainName + '/user/assets',
        iobots: config.domainName,
        // 当前UTC时间定时器
        currentUTCTimeTimer: null,
        // 当前UTC时间
        currentUTCTime: null,
        // 当前Local时间定时器
        currentLocalimeTimer: null,
        // 当前Local时间
        currentLocalTime: null,
        //是否显示使用cookies消息，null为显示，false为不显示
        isShowCookieStatement: localStorage.getItem("iobots-isShowCookieStatement") == null,
        // 当前时间是本地 / UTC
        isUTCTime: localStorage.getItem("iobots-time") == "UTCTime",
        // 星期
        localWeek: null,
        UTCWeek: null
      };
    },
    components: {
      IobotsWebSocket,Logo,Icon
    },
    computed: {
      ...mapGetters(["user", "darkMode", "defaultExchange", "snackbarMessage", "responsive", "notificationNum", "signalHeaderTitle", "tradingHeaderTitle","performanceHeaderTitle", "indicatorsHeaderTitle","indexHeaderTitle"]),
      // 是否显示提示信息
      isShowSnackbar: {
        get() {
          return this.$store.getters.isShowSnackbar;
        },
        set(v) {
          this.$store.dispatch("isShowSnackbarHandler", v);
        }
      }
    },
    mounted() {
      // this.getUrl();
      this.currentLocalTimeTimer = setInterval(() => {
        let timestamp = +new Date();
        this.currentLocalTime = formatTime(timestamp);
        this.localWeek = getDayOfWeek(timestamp);
      }, 1000);
      this.currentUTCTimeTimer = setInterval(() => {
        let timestamp = +new Date();
        this.currentUTCTime = formatUTCTime(timestamp);
        this.UTCWeek = getUTCDayOfWeek(timestamp);
      }, 1000);
    },
    methods: {
      acceptCookieStatement() {
        this.isShowCookieStatement = false;
        localStorage.setItem("iobots-isShowCookieStatement", 0);
      },
      //显示菜单侧边栏
      showMenuSidebar() {
        // if (this.user.token) {
        this.isShowMenuSidebar = !this.isShowMenuSidebar;
        // } else {
        //   this.gotoSignIn();
        // }
      },
      showMenuSideLeftbar(){
        // if (this.user.token) {
        this.isShowMenuSideLeftbar = !this.isShowMenuSideLeftbar;
        // } else {
        //   this.gotoSignIn();
        // }
      },
      // 关闭提示信息
      closeSnackbarMessage() {
        this.$store.dispatch("isShowSnackbarHandler", false);
      },
      gotoHome() {
        // if (this.user.token) {
        this.goToSignals();
        // } else {
        //   this.gotoSignIn();
        // }
      },
      // 跳转Signals
      goToSignals() {
        if (this.user.token) {
          this.goToMoreSignals();
        } else {
          this.$router.push({ name: "signals" });
          this.$store.dispatch("signalHeaderTitleHandler", 'signals');
          this.$store.dispatch("tradingHeaderTitleHandler", 'trading');
          this.$store.dispatch("performanceHeaderTitleHandler", 'performance');
        }
      },
      goToMoreSignals() {
        this.$router.push({ name: "moreSignals" });
        this.$store.dispatch("signalHeaderTitleHandler", 'signals');
        this.$store.dispatch("tradingHeaderTitleHandler", 'trading');
        this.$store.dispatch("performanceHeaderTitleHandler", 'performance');
      },
      // 跳转到Spot
      goToSpot() {
        if (this.user.token) {
          if (this.user.role == "TRADER") {
            this.$router.push({
              name: "spotTrading",
              params: {
                exchangeCode: this.defaultExchange,
                symbol: "BTC-USDT"
              }
            });
            this.$store.dispatch("signalHeaderTitleHandler", 'signals');
            this.$store.dispatch("tradingHeaderTitleHandler", 'spot');
            this.$store.dispatch("performanceHeaderTitleHandler", 'performance');
          } else {
            this.$store.dispatch("snackbarMessageHandler", this.$t("header.snackbarMessage"));
          }
        } else {
          this.gotoSignIn();
        }
      },
      // 跳转到goToFutures
      goToFutures() {
        if (this.user.token) {
          if (this.user.role == "TRADER") {
            this.$router.push({
              name: "futuresTrading",
              params: {
                exchangeCode: this.defaultExchange,
                symbol: "PI_BTCUSD"
              }
            });
            this.$store.dispatch("signalHeaderTitleHandler", 'signals');
            this.$store.dispatch("tradingHeaderTitleHandler", 'futures');
            this.$store.dispatch("performanceHeaderTitleHandler", 'performance');
          } else {
            this.$store.dispatch("snackbarMessageHandler", this.$t("header.snackbarMessage"));
          }
        } else {
          this.gotoSignIn();
        }
      },
      // 跳转到SwapUsdt
      goToSwapUsdt() {
        if (this.user.token) {
          if (this.user.role == "TRADER") {
            this.$router.push({
              name: "swapUsdtTrading",
              params: {
                exchangeCode: this.defaultExchange,
                symbol: "BTC-USDT-SWAP"
              }
            });
            this.$store.dispatch("signalHeaderTitleHandler", 'signals');
            this.$store.dispatch("tradingHeaderTitleHandler", 'swapUsdt');
            this.$store.dispatch("performanceHeaderTitleHandler", 'performance');
          } else {
            this.$store.dispatch("snackbarMessageHandler", this.$t("header.snackbarMessage"));
          }
        } else {
          this.gotoSignIn();
        }
      },
      // 跳转到SIP
      gotoSip() {
        window.open(config.sip, "_blank");
      },
      // 跳转到Poet
      gotoPoet() {
        window.open("http://beta.poet.best/", "_blank");
      },
      // 跳转到ioBots
      GotoIoBots() {
        window.open(config.domainName, "_blank");
      },
      // 跳转到MarketTypes
      goToMarketTypes(){
        this.$router.push({name: "marketTypes"});
        this.$store.dispatch("indicatorsHeaderTitleHandler", 'marketTypes');
        this.$store.dispatch("signalHeaderTitleHandler", 'signals');
        this.$store.dispatch("tradingHeaderTitleHandler", 'trading');
        this.$store.dispatch("performanceHeaderTitleHandler", 'performance');
        this.$store.dispatch("indexHeaderTitleHandler", 'index');
      },
      // 跳转到SignalsHistory
      goToSignalsHistory() {
        this.$router.push({ name: "signalHistory", params: { symbol: "BTCUSD" } });
        this.$store.dispatch("indicatorsHeaderTitleHandler", 'indicators');
        this.$store.dispatch("signalHeaderTitleHandler", 'signalsHistory');
        this.$store.dispatch("tradingHeaderTitleHandler", 'trading');
        this.$store.dispatch("performanceHeaderTitleHandler", 'signalsHistory');
        this.$store.dispatch("indexHeaderTitleHandler", 'index');
      },
      // 跳转到Model Portfolio
      goToModelPortfolio(){
        this.$router.push({name: "modelPortfolio"});
        this.$store.dispatch("indicatorsHeaderTitleHandler", 'indicators');
        this.$store.dispatch("signalHeaderTitleHandler", 'modelPortfolio');
        this.$store.dispatch("tradingHeaderTitleHandler", 'trading');
        this.$store.dispatch("performanceHeaderTitleHandler", 'modelPortfolio');
        this.$store.dispatch("indexHeaderTitleHandler", 'index');
      },
      // 跳转到QuasarFund
      goToQuasarFund(){
        this.$router.push({name: "quasarFund"});
        this.$store.dispatch("indicatorsHeaderTitleHandler", 'indicators');
        this.$store.dispatch("signalHeaderTitleHandler", 'signals');
        this.$store.dispatch("tradingHeaderTitleHandler", 'trading');
        this.$store.dispatch("performanceHeaderTitleHandler", 'quasarFund');
        this.$store.dispatch("indexHeaderTitleHandler", 'index');
      },
      goToDefyHedgedPool(){
        this.$router.push({name: "defyHedgedPool"});
        this.$store.dispatch("indicatorsHeaderTitleHandler", 'indicators');
        this.$store.dispatch("signalHeaderTitleHandler", 'signals');
        this.$store.dispatch("tradingHeaderTitleHandler", 'trading');
        this.$store.dispatch("performanceHeaderTitleHandler", 'defyHedgedPool');
        this.$store.dispatch("indexHeaderTitleHandler", 'index');
      },
      // 跳转到登录页面
      gotoSignIn() {
        this.$store.dispatch( "snackbarMessageHandler", this.$t("header.signInMessage"));
        this.$router.push({ name: "signIn" });
        this.$store.dispatch("indicatorsHeaderTitleHandler", 'indicators');
        this.$store.dispatch("signalHeaderTitleHandler", 'signals');
        this.$store.dispatch("tradingHeaderTitleHandler", 'trading');
        this.$store.dispatch("performanceHeaderTitleHandler", 'performance');
        this.$store.dispatch("indexHeaderTitleHandler", 'index');
      },
      // 退出
      signOut() {
        localStorage.removeItem("iobots-user");
        this.$router.go();
      },
      // 获取当前url
      // getUrl() {
      //   var url = document.location.toString();
      //   var arrUrl = url.split("/");
      //   var param = arrUrl[4];
      //   if ("spot" == param) {
      //     this.category = this.$t("header.spot");
      //   }
      //   if ("futures" == param) {
      //     this.category = this.$t("header.futures");
      //   }
      //   if ("swap" == param) {
      //     this.category = this.$t("header.swapUsdt");
      //   }
      //   if ("history" == param) {
      //     this.categoryleft = this.$t("header.signalsHistory");
      //   }
      //   if ("portfolio" == param) {
      //     this.categoryleft = this.$t("header.modelPortfolio");
      //   }
      // },
      // 获取历史订单
      getHistoricOrders() {
        let api =
          config.baseUrl + "/exchange/kraken/futures/private/getRecentOrders";
        this.$http.get(api).then(response => {
          var data = response.data;
          console.log(data);
        });
      },
      goToHistoricalPortfolioResults(){
        this.$router.push({name: "historicalPortfolioResults"});
        this.$store.dispatch("indicatorsHeaderTitleHandler", 'indicators');
        this.$store.dispatch("signalHeaderTitleHandler", 'signals');
        this.$store.dispatch("tradingHeaderTitleHandler", 'trading');
        this.$store.dispatch("performanceHeaderTitleHandler", 'performance');
        this.$store.dispatch("indexHeaderTitleHandler", 'index');
      },
      goToSyntheticIndices(){
        this.$router.push({name: "syntheticIndices"});
        this.$store.dispatch("indicatorsHeaderTitleHandler", 'indicators');
        this.$store.dispatch("signalHeaderTitleHandler", 'signals');
        this.$store.dispatch("tradingHeaderTitleHandler", 'trading');
        this.$store.dispatch("performanceHeaderTitleHandler", 'performance');
        this.$store.dispatch("indexHeaderTitleHandler", 'syntheticIndices');
      },
      goToTradingSignalResults(){
        this.$router.push({name: "tradingSignalResults"});
        this.$store.dispatch("indicatorsHeaderTitleHandler", 'indicators');
        this.$store.dispatch("signalHeaderTitleHandler", 'signals');
        this.$store.dispatch("tradingHeaderTitleHandler", 'trading');
        this.$store.dispatch("performanceHeaderTitleHandler", 'tradingSignalResults');
        this.$store.dispatch("indexHeaderTitleHandler", 'index');
      },
    }
  };
</script>