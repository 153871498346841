import Vue from 'vue'

let Side = {
  	//转换时间
  	formatSide: function(side){
      switch(side){
        case 'open_long': return window.vm.$t('side.long');
        case 'open_short': return window.vm.$t('side.short');
        case 'close_buy': return window.vm.$t('side.buy');
        case 'close_sell': return window.vm.$t('side.sell');
        default : return side;
      }
  	}
}

Vue.directive('side', {
  bind: function(el, binding, vnode){
    let timestamp = new Date().getTime();
    // 给vnode更新key，用于数据更新时重新渲染改节点元素
    vnode.key = timestamp;
    el.innerHTML = Side.formatSide(binding.value);
  },
  unbind: function(el){

  }
});

export default Side
