import Vue from 'vue'

let HeaderTitle = {
    //转换标题
    formatHeaderTitle: function(headerTitle){
      try {
        switch(headerTitle){
          case 'signals': return window.vm.$t('header.signals');
          case 'signalsHistory': return window.vm.$t('header.signalsHistory');
          case 'modelPortfolio': return window.vm.$t('header.modelPortfolio');
          case 'trading': return window.vm.$t('header.trading');
          case 'spot': return window.vm.$t('header.spot');
          case 'futures': return window.vm.$t('header.futures');
          case 'swapUsdt': return window.vm.$t('header.swapUsdt');
          case 'performance': return window.vm.$t('header.performance');
          case 'quasarFund': return window.vm.$t('header.quasarFund');
          case 'indicators': return window.vm.$t('header.indicators');
          case 'index': return window.vm.$t('header.index');
          case 'marketTypes': return window.vm.$t('header.marketTypes');
          case 'defyHedgedPool': return window.vm.$t('header.defyHedgedPool');
          case 'historicalPortfolioResults': return window.vm.$t('header.historicalPortfolioResults');
          case 'syntheticIndices': return window.vm.$t('header.syntheticIndices');
          case 'tradingSignalResults': return window.vm.$t('header.tradingSignalResults');
          default : return headerTitle;
        }
      } catch(error) {
        return headerTitle;
      }
    }
}

Vue.directive('headerTitle', {
  bind: function(el, binding, vnode){
    let timestamp = new Date().getTime();
    // 给vnode更新key，用于数据更新时重新渲染改节点元素
    vnode.key = timestamp;
    el.innerHTML = HeaderTitle.formatHeaderTitle(binding.value);
  },
  unbind: function(el){

  }
});

export default HeaderTitle
