<template>
  <v-app :dark="darkMode">
    <app-header></app-header>
    <div id="App" class="fill" data-app="true">
      <keep-alive>
        <router-view class="view" v-if="$route.meta.keepAlive">
          <!-- 这里是会被缓存的视图组件 -->
        </router-view>
      </keep-alive>
      <router-view v-if="!$route.meta.keepAlive">
          <!-- 这里是不被缓存的视图组件 -->
      </router-view>
    </div>
    <version-released></version-released>
  </v-app>
</template>
<script>
/* eslint-disable */
import Vue from 'vue'
import config from '@/config.js'
import { mapGetters } from 'vuex'
import AppSideLeftMenu from '@/components/common/SideLeftMenu.vue'
import AppSideMenu from '@/components/common/SideMenu.vue'
import AppNotificationMenu from '@/components/common/NotificationMenu.vue'
import AppHeader from "@/components/common/Header.vue";
import AppRight from "@/components/common/Right.vue";
import AppTerms from "@/components/common/Terms.vue";
import AppFooter from "@/components/common/Footer.vue";
import AppProduct from "@/components/common/Product.vue";
import SignalMobileChange from "@/components/signal/SignalMobileChange"
import MobileBtn from "@/components/common/MobileBtn";
import VersionReleased from "@/components/common/VersionReleased.vue";

Vue.component('AppSideLeftMenu', AppSideLeftMenu);
Vue.component('AppSideMenu', AppSideMenu);
Vue.component('AppNotificationMenu', AppNotificationMenu);
Vue.component('AppHeader', AppHeader);
Vue.component('AppRight', AppRight);
Vue.component('AppTerms', AppTerms);
Vue.component('AppFooter', AppFooter);
Vue.component('AppProduct', AppProduct);
Vue.component('SignalMobileChange', SignalMobileChange);
Vue.component('MobileBtn', MobileBtn);
Vue.component('VersionReleased', VersionReleased);

export default {
  name: "App",
  data() {
    return {
      timeOut: ''
    }
  },
  computed: {
    ...mapGetters(["darkMode","responsive"])
  },
  created(){
    // 数字格式化
    Vue.filter('NumFormat', function(value) {
        if (value == '--') return '--';
        if(!value) return '0.00000';
        var intPart = Number(value).toFixed(0); //获取整数部分
        intPart = intPart > Number(value) ? intPart - 1 : intPart; // 若四舍五入进了1则减去1
        var intPartFormat = intPart.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,'); //将整数部分逢三一断
        var floatPart = ".00000"; //预定义小数部分
        var value2Array = 0;
        if (value.indexOf('.') > -1) {
          value2Array = value.split(".");
        }
        //=2表示数据有小数位
        if(value2Array.length == 2) {
            floatPart = value2Array[1].toString(); //拿到小数部分
            if(floatPart.length == 1) { //补0,实际上用不着
                return intPartFormat + "." + floatPart + '0';
            } else {
                return intPartFormat + "." + floatPart;
            }
        } else {
            return intPartFormat + floatPart;
        }
    });
    // 给数组添加remove()方法
    //根据数组的下标，删除该下标的元素
    Array.prototype.remove = function(val) {
      var index = this.indexOf(val);
      if (index > -1) {
        this.splice(index, 1);
      }
    };
    // 由于做了订单推送，暂时先不刷新页面
    // this.isTimeOut();
  },
  mounted(){
			this.addVisitVolume();
      this.onMovableDialog();
	},
  methods:{
    addVisitVolume(){
			this.$http.get(config.baseUrl + "/visit/public/addVisit");
		},
    // 可移动的弹出框
    onMovableDialog(){
      const d = {};
      document.addEventListener("mousedown", e => {
          const closestDialog = e.target.closest(".v-dialog.v-dialog--active");
          if (e.button === 0 && closestDialog != null && e.target.classList.contains("v-card__title") && this.responsive == 'PC') { // element which can be used to move element
              d.el = closestDialog; // element which should be moved
              d.mouseStartX = e.clientX;
              d.mouseStartY = e.clientY;
              d.elStartX = d.el.getBoundingClientRect().left;
              d.elStartY = d.el.getBoundingClientRect().top;
              d.el.style.position = "fixed";
              d.el.style.margin = 0;
              d.oldTransition = d.el.style.transition;
              d.el.style.transition = "none"
          }
      });
      document.addEventListener("mousemove", e => {
          if (d.el === undefined) return;
          d.el.style.left = Math.min(
              Math.max(d.elStartX + e.clientX - d.mouseStartX, 0),
              window.innerWidth - d.el.getBoundingClientRect().width
          ) + "px";
          d.el.style.top = Math.min(
              Math.max(d.elStartY + e.clientY - d.mouseStartY, 0),
              window.innerHeight - d.el.getBoundingClientRect().height
          ) + "px";
      });
      document.addEventListener("mouseup", () => {
          if (d.el === undefined) return;
          d.el.style.transition = d.oldTransition;
          d.el = undefined
      });
      setInterval(() => { // prevent out of bounds
          const dialog = document.querySelector(".v-dialog.v-dialog--active");
          if (dialog === null) return;
          dialog.style.left = Math.min(parseInt(dialog.style.left), window.innerWidth - dialog.getBoundingClientRect().width) + "px";
          dialog.style.top = Math.min(parseInt(dialog.style.top), window.innerHeight - dialog.getBoundingClientRect().height) + "px";
      }, 1);
    },
    // 判断是否超时
    isTimeOut() {
      if(this.$route.path == "/") {
        // 页面加载出来开始计时
        this.startTimer();
      }
      document.body.onmouseup = this.startTimer;
      document.body.onmousemove = this.startTimer;
      document.body.onkeyup  = this.startTimer;
      document.body.onclick  = this.startTimer;
      document.body.ontouchend  = this.startTimer;
    },
    // 页面10分钟无操作时刷新页面
    startTimer() {
      let _this = this;
      clearInterval(this.timeOut);
      this.timeOut = setInterval(() => {
        _this.$router.go();
      }, 600000)
    },

  }
};
</script>
