<template>
    <div>
        <!-- <v-row dense>
            <v-col cols="12">
                <v-card tile flat :class="[darkMode?'grey darken-3':'grey lighten-3']" class="lightred--text py-3 pl-2 fs-20">
                    <h4>{{$t("menu.signals")}}</h4>
                </v-card>
            </v-col>
        </v-row> -->
        <v-row class="px-1" id="fundOrIndexSelect">
            <v-overflow-btn dense :class="[darkMode?'grey darken-3':'grey lighten-3']" block class="my-2 fs-16" hide-details :items="pageItems" target="#dropdown-example" v-model="page"></v-overflow-btn>
        </v-row>
    </div>
</template>
<style>
.theme--dark.v-overflow-btn.theme--dark.v-overflow-btn > .v-input__control > .v-input__slot {
    border-color: #424242!important;
}
#fundOrIndexSelect div{
    color: #FEE108;
}
</style>
<script>
    import Vue from 'vue'
    import config from '@/config.js';
    import { mapGetters } from "vuex";
    export default {
        data(){
            return{
                pageItems:[this.$t("header.tradingSignalResults"),this.$t("menu.signalsHistory"), this.$t("menu.defyHedgedPool"),this.$t("menu.quasarFund"),this.$t("menu.modelPortfolio")]
            }
        },
        created(){

        },
        mounted(){

        },
        computed: {
            ...mapGetters(["user","responsive","darkMode"]),
            page:{
            	get () {
            		return this.$store.getters.signalPage;
		        },
		        set (val) {
		            this.$store.dispatch('signalPageHandler', val)
		            if(val == this.$t("menu.signals")){
	                    if(this.user.token) {
	                    	this.$router.push({ name: "moreSignals" });
	                    }else{
	                    	this.$router.push({ name: "signals" });
	                    }
                    }else if(val == this.$t("header.tradingSignalResults")){
                        this.$router.push({name: "tradingSignalResults"});
                    }else if(val == this.$t("menu.signalsHistory")){
                        this.$router.push({ name: "signalHistory", params: { symbol: "BTCUSD" } });
	                }else if(val == this.$t("menu.modelPortfolio")){
                        this.$router.push({name: "modelPortfolio"});
	                }else if(val == this.$t("menu.quasarFund")){
                        this.$router.push({name: "quasarFund"});
                    }else if(val == this.$t("menu.defyHedgedPool")){
                        this.$router.push({name: "defyHedgedPool"});
                    }
		        }
            }
        },
        watch:{

        },
        methods: {

        },
    }
</script>
