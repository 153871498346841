// src/plugins/vuetify.js

import Vue from 'vue'
import Vuetify from 'vuetify/lib'


Vue.use(Vuetify)

function setTheme() {
  localStorage.setItem("iobots-darkMode", 1);
}

// 默认黑色主题
setTheme();

export default new Vuetify({
  theme: {
    dark: localStorage.getItem("iobots-darkMode") == 1 ? true : false,
    themes: {
      light: {
        primary: '#9e9e9e',
        red: localStorage.getItem("iobots-colorStyle") == 'greenUpRedDown' ? '#F44336' : '#4CAF50',
        green: localStorage.getItem("iobots-colorStyle") == 'greenUpRedDown' ? '#4CAF50' : '#F44336',
        upBars: localStorage.getItem("iobots-colorStyle") == 'greenUpRedDown' ? '#A9DCC3' : '#F5A6AE',
        downBars: localStorage.getItem("iobots-colorStyle") == 'greenUpRedDown' ? '#F5A6AE' : '#A9DCC3',
      },
      dark: {
        primary: '#9e9e9e',
        red: localStorage.getItem("iobots-colorStyle") == 'greenUpRedDown' ? '#F44336' : '#4CAF50',
        green: localStorage.getItem("iobots-colorStyle") == 'greenUpRedDown' ? '#4CAF50' : '#F44336',
        upBars: localStorage.getItem("iobots-colorStyle") == 'greenUpRedDown' ? '#A9DCC3' : '#F5A6AE',
        downBars: localStorage.getItem("iobots-colorStyle") == 'greenUpRedDown' ? '#F5A6AE' : '#A9DCC3',
      },
    },
  },
})