<template>
 <div>
    <v-container fluid class="grid-list-xl pt-0 mt-12 px-0 pb-0 mb-0" v-if="responsive == 'PC'">
    <v-row no-gutters>
      <v-col cols="12" md="12" class="mb-0 my-0 py-0">
        <v-tabs v-model="tab" show-arrows="mobile" :slider-size="5" slider-color="lightred">
           <v-tab href="#spot">
            <span>{{ $t('common.spot') }}</span>
          </v-tab>
          <v-tab href="#coin-swap">
            <span>{{ $t('common.futures') }}</span>
          </v-tab>
          <v-tab href="#usdt-swap">
            <span>{{ $t('common.swapUsdt') }}</span>
          </v-tab>
          <v-tab href="#ledger">
            <span>{{ $t('log.ledgerHistory') }}</span>
          </v-tab>
          <v-tab href="#coin-swap-records">
            <span>{{ $t('log.tradingRecords') }}</span>
          </v-tab>
          <v-tab href="#usdt-swap-records">
            <span>{{ $t('log.usdtTradingRecords') }}</span>
          </v-tab>
        </v-tabs>
      </v-col>
      <v-col cols="12" md="12" class="mb-0 my-0 py-0" v-if="responsive == 'PC'">
        <v-tabs-items v-model="tab">
          <v-tab-item value="spot">
            <spot-log></spot-log>
          </v-tab-item>
          <v-tab-item value="coin-swap">
            <futures-log></futures-log>
          </v-tab-item>
          <v-tab-item value="usdt-swap">
            <swap-usdt-log></swap-usdt-log>
          </v-tab-item>
          <v-tab-item value="ledger">
            <ledger-log></ledger-log>
          </v-tab-item>
          <v-tab-item value="coin-swap-records">
            <coin-trading-record-log></coin-trading-record-log>
          </v-tab-item>
          <v-tab-item value="usdt-swap-records">
            <usdt-trading-record-log></usdt-trading-record-log>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </v-container>
  <v-container v-if="responsive == 'mobile'" class="flex-b pl-0 mt-6 pr-0 mb-5 pb-5">
    <v-row class="mt-5 mx-3" no-gutters>
        <v-col cols="1">
          <v-menu offset-y :nudge-width="240" :max-height="300">
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" fab dark x-small color="lightred">
                <v-icon color="black" dense>mdi-menu</v-icon>
              </v-btn>
            </template>
            <v-list dense>
              <!-- 1 -->
              <v-list-item @click="tab='spot'">
                <v-list-item-title class="fs-14">
                  {{ $t('common.spot') }}
                </v-list-item-title>
              </v-list-item>
              <!-- 2 -->
              <v-list-item @click="tab='coin-swap'">
                <v-list-item-title class="fs-14">
                  {{ $t('common.futures') }}
                </v-list-item-title>
              </v-list-item>
              <!-- 3 -->
              <v-list-item @click="tab='usdt-swap'">
                <v-list-item-title class="fs-14">
                  {{ $t('common.swapUsdt') }}
                </v-list-item-title>
              </v-list-item>
              <!-- 4 -->
              <v-list-item @click="tab='ledger'">
                <v-list-item-title class="fs-14">
                  {{ $t('log.ledgerHistory') }}
                </v-list-item-title>
              </v-list-item>
              <!-- 5 -->
              <v-list-item @click="tab='coin-swap-records'">
                <v-list-item-title class="fs-14">
                  {{ $t('log.tradingRecords') }}
                </v-list-item-title>
              </v-list-item>
              <!-- 6 -->
              <v-list-item @click="tab='usdt-swap-records'">
                <v-list-item-title class="fs-14">
                  {{ $t('log.usdtTradingRecords') }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-col>
        <v-col cols="8" class="align-self-sm-center my-2 ml-3">
          <!-- {{tab.toUpperCase()}} -->
          <span  v-if="tab=='spot'">
            {{$t('common.spot')}}
          </span>
          <span v-if="tab=='coin-swap'">
            {{ $t('common.futures') }}
          </span>
          <span v-if="tab=='usdt-swap'">
            {{ $t('common.swapUsdt') }}
          </span>
          <span v-if="tab=='ledger'">
            {{ $t('log.ledgerHistory') }}
          </span>
          <span v-if="tab=='coin-swap-records'">
            {{ $t('log.tradingRecords') }}
          </span>
          <span v-if="tab=='usdt-swap-records'">
            {{ $t('log.usdtTradingRecords') }}
          </span>
        </v-col>
      </v-row>    
      <v-container fluid class="px-2" v-if="tab=='spot'">
        <spot-log></spot-log>
      </v-container>
      <v-container fluid class="px-2" v-if="tab=='coin-swap'">
        <futures-log></futures-log>
      </v-container>
      <v-container fluid class="px-2" v-if="tab=='usdt-swap'">
        <swap-usdt-log></swap-usdt-log>
      </v-container>
      <v-container fluid class="px-2" v-if="tab=='ledger'">
        <ledger-log></ledger-log>
      </v-container>
      <v-container fluid class="px-2" v-if="tab=='coin-swap-records'">
        <coin-trading-record-log></coin-trading-record-log>
      </v-container>
      <v-container fluid class="px-2" v-if="tab=='usdt-swap-records'">
        <usdt-trading-record-log></usdt-trading-record-log>
      </v-container>
  </v-container>
  <mobile-btn></mobile-btn>
 </div>
</template>
<style>
.v-tab:before {
  background-color: #FEE108!important;
}
</style>
<script>
  import { mapGetters } from "vuex";
  import SpotLog from '@/components/logInfo/Spot';
  import FuturesLog from '@/components/logInfo/Futures';
  import SwapUsdtLog from '@/components/logInfo/SwapUSDT';
  import LedgerLog from '@/components/logInfo/Ledger';
  import CoinTradingRecordLog from '@/components/logInfo/CoinTradingRecord';
  import UsdtTradingRecordLog from '@/components/logInfo/USDTTradingRecord';
  import MobileBtn from "@/components/common/MobileBtn";
  

  export default {
    data() {
      return {
        tab: 'spot',
      }
    },
    computed: {
      ...mapGetters(['user','responsive'])
    },
    components:{
      SpotLog,
      FuturesLog,
      SwapUsdtLog,
      LedgerLog,
      CoinTradingRecordLog,
      UsdtTradingRecordLog,
      MobileBtn
    },
    created() {

    },
    mounted() {
      this.isLogin();
      this.judgeUserRole();
    },
    watch: {
      tab(val){
        this.$router.push({ hash: '#' + val });
      },
      $route:{
        handler:function(){
          let hash = this.$route.hash;
          if (hash != null && hash.length > 1) {
            this.tab = this.$route.hash.substring(1);
          }
        },
        immediate:true
      }
    },
    methods: {
      // 是否登录
      isLogin() {
        if (!this.user.token) {
          this.$router.push({
            name: 'home'
          });
        }
      },
      // 判断角色
      judgeUserRole() {
        if (this.user != null && this.user.role != 'TRADER') {
          this.$store.dispatch('snackbarMessageHandler', this.$t("common.notTrader"));
          this.$router.push({
            name: 'home'
          });
        }
      },
    }
  }
</script>
