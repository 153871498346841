const state = {
	hideZeroBalances: false,
	assetsSearchCoinFilter: null
}

const getters = {
	hideZeroBalances: state => {
		let hideZeroBalances = localStorage.getItem('iobots-hideZeroBalances');
		if (hideZeroBalances == 0) {
			state.hideZeroBalances = false;
		} else if (hideZeroBalances == 1) {
			state.hideZeroBalances = true;
		} else if(hideZeroBalances == null){
			state.hideZeroBalances = false;
			localStorage.setItem('iobots-hideZeroBalances', 0);
		}
		return state.hideZeroBalances;
	},
	assetsSearchCoinFilter: state => {
		return state.assetsSearchCoinFilter;
	},
}

const actions = {
    hideZeroBalancesHandler(context, payload){
  	  context.commit('hideZeroBalancesHandler', payload);
    },
    assetsSearchCoinFilterHandler(context, payload){
  	  context.commit('assetsSearchCoinFilterHandler', payload);
    },
}

const mutations = {
    hideZeroBalancesHandler(state, hideZeroBalances){
    	state.hideZeroBalances = hideZeroBalances;
		localStorage.setItem('iobots-hideZeroBalances', hideZeroBalances ? 1 : 0);
    },
    assetsSearchCoinFilterHandler(state, assetsSearchCoinFilter){
    	state.assetsSearchCoinFilter = assetsSearchCoinFilter;
    },
}

export default {
	state,
	getters,
	actions,
	mutations
}