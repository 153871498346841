<template>
  <div>
    <v-col cols="12" md="7" offset-md="8" class="py-0" v-if="responsive == 'PC'">
      <v-row dense>
        <v-col cols="12" sm="6" md="2">
            <v-select class="fs-12" v-model="usdtTradeSearchForm.exchange" :items="exchanges" :label="$t('common.exchange')" item-text="name" item-value="code" clearable></v-select>
          </v-col>
          <v-col cols="12" sm="6" md="2">
            <v-select class="fs-12" v-model="usdtTradeSearchForm.side" :items="sides" :label="$t('common.side')" clearable></v-select>
          </v-col>
          <v-col cols="12" sm="5" md="2">
            <v-select class="fs-12" v-model="usdtTradeSearchForm.symbol" :items="swapUsdtSymbols" :label="$t('common.symbol')"
              clearable></v-select>
          </v-col>
          <v-col cols="12" sm="2" md="2" class="py-5">
            <v-btn icon @click="getUSDTTradingRecords">
              <v-icon>mdi-magnify</v-icon>
            </v-btn>
          </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" class="px-5" v-if="responsive == 'mobile'">
      <v-row dense>
        <v-col cols="12">
          <div class="d-custom-flex">
             <v-select dense class="fs-12 mr-5" v-model="usdtTradeSearchForm.exchange" :items="exchanges" :label="$t('common.exchange')" item-text="name" item-value="code" clearable></v-select>
             <v-select dense class="fs-12" v-model="usdtTradeSearchForm.side" :items="sides" :label="$t('common.side')" clearable></v-select>
          </div>
          <div class="d-custom-flex">
            <v-select class="fs-12 mr-3" dense v-model="usdtTradeSearchForm.symbol" :items="swapUsdtSymbols" :label="$t('common.symbol')"
              clearable></v-select>
            <v-btn class="float-right mt-2" x-small icon  @click="getUSDTTradingRecords">
            <v-icon>mdi-magnify</v-icon>
          </v-btn>
          </div>
        </v-col>
      </v-row> 
    </v-col>
    <v-col cols="12" md="12" class="pa-0 ma-0 pa-0 px-3" v-if="responsive == 'PC'">
      <v-row dense v-if="usdtTradingRecordLoading"> 
          <v-col cols="12">
              <v-skeleton-loader dense type="table-row-divider@30" class="mx-auto"></v-skeleton-loader>
          </v-col>
      </v-row>
      <v-data-table dense :headers="usdtTradingRecordsHeaders" :items="usdtTradingRecords" hide-default-footer v-if="!usdtTradingRecordLoading" :items-per-page="pageSize" disable-pagination>
        <template v-slot:body="{ items }">
          <tbody>
            <tr v-for="(item,index) in items" :key="index">
              <td :class="CommonJS.formatExchangeColor(item.exchange)">{{ getExchangeNameByCode(item.exchange) }}</td>
              <td>{{ item.symbol }}</td>        
              <td class="text-right">{{item.openSide == 'open_long' ? '' : '-'}}{{ item.size }}</td>
              <td class="text-right">{{ formatNumber(item.value) }}</td>
              <td><span v-status="item.status"></span></td>
              <td class="pointer" :title="item.openId" @click="copyToClipboard(item.openId)">{{ item.openId.substring(0,10) }}...</td>
              <td :class="item.openSide == 'open_long' ? 'green--text' : 'red--text'"><span
                  v-side="item.openSide"></span></td>
              <td>{{ formatDate(item.openTimestamp) }}</td>
              <td class="text-right">{{ formatNumber(item.openAvgPrice.toFixed(getSwapUsdtDecimalPlace(item.exchange, item.symbol))) }}</td>
              <td class="text-right" :title="item.openCommission">{{ formatNumber(item.openCommission.toFixed(4)) +" USDT"}}</td>
              <td class="text-right" :title="item.openRatio">{{ formatNumber((item.openRatio * 100 ).toFixed(4)) }}%</td>
              <td class="pointer" :title="item.closeId" @click="copyToClipboard(item.closeId)">{{ item.closeId.substring(0,10) }}...</td>
              <td :class="item.closeSide == 'close_buy' ? 'green--text' : 'red--text'"><span
                  v-side="item.closeSide"></span></td>
              <td>{{ formatDate(item.closeTimestamp) }}</td>
              <td class="text-right">{{ formatNumber(item.closeAvgPrice.toFixed(getSwapUsdtDecimalPlace(item.exchange, item.symbol))) }}</td>
              <td class="text-right" :title="item.closeCommission">{{ formatNumber(item.closeCommission.toFixed(4)) +" USDT"}}</td>
              <td class="text-right" :title="item.closeRatio">{{ formatNumber((item.closeRatio * 100 ).toFixed(4)) }}%</td>
              <td class="text-right"  :title="item.profit" :class="item.profit > 0 ? 'green--text' : ( item.profit == 0 ? '' : 'red--text')">{{ formatNumber(item.profit.toFixed(4)) + ' USDT'}}</td>
              <td class="text-right"  :title="item.roe" :class="item.roe > 0 ? 'green--text' : ( item.roe == 0 ? '' : 'red--text')">{{ formatNumber((item.roe * 100).toFixed(2)) }}%</td>
            </tr>
            <tr v-if="!usdtTradingRecordLoading && usdtTradingRecordTotalElements == 0">
              <td class="text-center" colspan="20">{{ $t('signalHistory.noDataAvailable')}}</td>
            </tr>
          </tbody>
        </template>
      </v-data-table>
      <v-divider v-if="!usdtTradingRecordLoading"></v-divider>
      <v-row class="pb-5" v-if="usdtTradingRecordTotalElements > 0">
        <v-col cols="auto" class="pl-0">
          <v-pagination color="lightred" circle prev-icon="mdi-menu-left" next-icon="mdi-menu-right" v-model="usdtTradingRecordPageNo"
            :length="usdtTradingRecordTotalPage"  :total-visible="15" @input="getUSDTTradingRecords" class="ml-2 mt-3" :items-per-page="pageSize">
          </v-pagination>
        </v-col>
        <v-col cols="auto" class="mt-5 pt-5">
          {{$t("log.totalNumber") + formatNumber(usdtTradingRecordTotalElements)}}
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="12" sm="6" md="3" class="text-right mt-3">
          <v-btn outlined tile class="ma-2" small @click="exportorCurrent()" :loading="exportorLoading">
            <v-icon small class="mr-2">mdi-download</v-icon>{{$t("log.downloadCurrent")}}
          </v-btn>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" md="12" class="pa-0 ma-0 pa-0 px-3" v-if="responsive == 'mobile'">
      <v-simple-table dense>
        <template v-slot:default>
          <thead>
            <tr>
              <th>{{$t("common.exchange")}}</th>
              <th>{{$t("common.symbol")}}</th>
              <th class="text-right">{{$t("log.size")}}</th>
              <th class="text-right">{{$t("log.value")}}</th>
              <th>{{$t("log.status")}}</th>
              <th>{{$t("log.openId")}}</th>
              <th>{{$t("log.openSide")}}</th>
              <th>{{$t("log.openTime(utc)")}}</th>
              <th class="text-right">{{$t("log.openAvgPrice")}}</th>
              <th class="text-right">{{$t("log.openCommission")}}</th>
              <th class="text-right">{{$t("log.openRatio")}}</th>
              <th>{{$t("log.closeId")}}</th>
              <th>{{$t("log.closeSide")}}</th>
              <th>{{$t("log.closeTime(utc)")}}</th>
              <th class="text-right">{{$t("log.closeAvgPrice")}}</th>
              <th class="text-right">{{$t("log.closeCommission")}}</th>
              <th class="text-right">{{$t("log.closeRatio")}}</th>
              <th class="text-right">{{$t("log.p/l")}}</th>
              <th class="text-right">{{$t("log.roe")}}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="usdtTradingRecordLoading">
              <td colspan="17">
                <v-progress-linear indeterminate ></v-progress-linear>
              </td>
            </tr>
            <tr v-if="!usdtTradingRecordLoading && usdtTradingRecordTotalElements == 0">
              <td class="text-center" colspan="17">{{ $t('log.noDataAvailable')}}</td>
            </tr>
            <tr v-for="(item,index) in usdtTradingRecords" :key="index">
              <td :class="CommonJS.formatExchangeColor(item.exchange)">{{ getExchangeNameByCode(item.exchange) }}</td>
              <td>{{ item.symbol }}</td>
              <td class="pointer" :title="item.openId" @click="copyToClipboard(item.openId)">{{ item.openId.substring(0,10) }}...</td>
              <td class="pointer" :title="item.closeId" @click="copyToClipboard(item.closeId)">{{ item.closeId.substring(0,10) }}...</td>
              <td class="text-right">{{item.openSide == 'open_long' ? '' : '-'}}{{ item.size }}</td>
              <td class="text-right">{{ formatNumber(item.value) }}</td>
              <td>{{ formatDate(item.openTimestamp) }}</td>
              <td :class="item.openSide == 'open_long' ? 'green--text' : 'red--text'"><span
                  v-side="item.openSide"></span></td>
              <td class="text-right">{{ formatNumber(item.openAvgPrice.toFixed(getSwapUsdtDecimalPlace(item.exchange, item.symbol))) }}</td>
              <td class="text-right">{{ formatNumber(item.openCommission.toFixed(4)) +" USDT"}}</td>
              <td class="text-right">{{ formatNumber((item.openRatio * 100 ).toFixed(4)) }}%</td>
              <td><span v-status="item.status"></span></td>
              <td>{{ formatDate(item.closeTimestamp) }}</td>
              <td :class="item.closeSide == 'close_buy' ? 'green--text' : 'red--text'"><span
                  v-side="item.closeSide"></span></td>
              <td class="text-right">{{ formatNumber(item.closeAvgPrice.toFixed(getSwapUsdtDecimalPlace(item.exchange, item.symbol))) }}</td>
              <td class="text-right">{{ formatNumber(item.closeCommission.toFixed(4)) +" USDT"}}</td>
              <td class="text-right">{{ formatNumber((item.closeRatio * 100 ).toFixed(4)) }}%</td>
              <td class="text-right" :class="item.profit > 0 ? 'green--text' : ( item.profit == 0 ? '' : 'red--text')">{{ formatNumber(item.profit.toFixed(4)) + ' USDT'}}</td>
              <td class="text-right" :class="item.roe > 0 ? 'green--text' : ( item.roe == 0 ? '' : 'red--text')">{{ formatNumber((item.roe * 100).toFixed(2)) }}%</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <v-divider></v-divider>
      <v-row class="pb-5" v-if="usdtTradingRecordTotalElements > 0">
        <v-col cols="12" class="pl-0">
          <v-pagination color="lightred" circle prev-icon="mdi-menu-left" next-icon="mdi-menu-right" v-model="usdtTradingRecordPageNo"
            :length="usdtTradingRecordTotalPage" :total-visible="15" @input="getUSDTTradingRecords" class="ml-2 mt-3" :items-per-page="pageSize">
          </v-pagination>
        </v-col>
        <v-col cols="auto" class="mt-5 pt-5">
          {{$t("log.totalNumber") + formatNumber(usdtTradingRecordTotalElements)}}
        </v-col>
        <v-col cols="auto" class="float-right mt-3">
          <v-btn outlined tile class="ma-2" small @click="exportorCurrent()" :loading="exportorLoading">
            <v-icon small class="mr-2">mdi-download</v-icon>{{$t("log.downloadCurrent")}}
          </v-btn>
        </v-col>
      </v-row>
    </v-col>
  </div>
</template>

<script>
  import Vue from 'vue';
  import config from '@/config.js';
  import { mapGetters } from "vuex";
  import CsvExportor from "csv-exportor";;

  export default {
    components: {

    },
    data() {
      return {
        usdtTradingRecordsHeaders:[
          { text:this.$t("common.exchange"), sortable:false},
          { text:this.$t("common.symbol"), sortable:false},
          { text:this.$t("log.size"), sortable:false, align:'right'},
          { text:this.$t("log.value"), sortable:false, align:'right'},
          { text:this.$t("log.status"), sortable:false},
          { text:this.$t("log.openId"), sortable:false},
          { text:this.$t("log.openSide"), sortable:false},
          { text:this.$t("log.openTime(utc)"), sortable:false},
          { text:this.$t("log.openAvgPrice"), sortable:false, align:'right'},
          { text:this.$t("log.openCommission"), sortable:false, align:'right'},
          { text:this.$t("log.openRatio"), sortable:false, align:'right'},
          { text:this.$t("log.closeId"), sortable:false},
          { text:this.$t("log.closeSide"), sortable:false},
          { text:this.$t("log.closeTime(utc)"), sortable:false},
          { text:this.$t("log.closeAvgPrice"), sortable:false, align:'right'},
          { text:this.$t("log.closeCommission"), sortable:false, align:'right'},
          { text:this.$t("log.closeRatio"), sortable:false, align:'right'},
          { text:this.$t("log.p/l"), sortable:false, align:'right'},
          { text:this.$t("log.roe"), sortable:false, align:'right'}
        ],
        pageSize:30,
        usdtTradingRecordTotalElements:0,
        usdtTradingRecordPageNo:1,
        usdtTradingRecordTotalElements:0,
        usdtTradingRecordTotalPage:0,
        usdtTradingRecordLoading:false,
        exportorLoading:false,
        usdtTradingRecords:[],
        usdtTradeSearchForm:{
          exchange:null,
          side:null,
          symbol:null,
        },
        sides: ["Long", "Short"],
      }
    },
    created (){

    },

    destroyed(){

    },
    watch:{
      
    },
    mounted() {
     this.getUSDTTradingRecords();
    },
    computed:{
      ...mapGetters(['darkMode','exchanges','swapUsdtSymbolInfos','swapUsdtSymbols','time','responsive']),
    },
    methods: {
      exportorCurrent(){
        this.exportorLoading = true;
        var heads = Object.keys(this.usdtTradingRecords[0]);
        var body =[];
        for( var i = 0 ; i < this.usdtTradingRecords.length ; i++){
          var line = [];
          heads.map(item=>{
            // 处理时间
            if( item.indexOf("Timestamp") != -1 ){
              if(this.usdtTradingRecords[i][item] == undefined || this.usdtTradingRecords[i][item] == null){
                line.push("--");
              }else if( this.usdtTradingRecords[i][item] == -1 ){
                line.push("-1");
              }else if( this.usdtTradingRecords[i][item] == 0 ){
                line.push("--");
              }else{
                line.push(this.formatDate(this.usdtTradingRecords[i][item]));
              }
            }
            if( item.indexOf("Timestamp") == -1 ){
              // 处理无值的
              if(this.usdtTradingRecords[i][item] == undefined){
                line.push("--");
              }else{
                if(this.usdtTradingRecords[i][item] == 0){
                  line.push("--");
                }else{
                  // size的处理
                  if(item.indexOf("size") != -1){
                    if(this.usdtTradingRecords[i]["openSide"] == "open_long" || this.usdtTradingRecords[i]["openSide"] == "close_buy"){
                      line.push(this.usdtTradingRecords[i][item]);
                    }else{
                      line.push("-"+this.usdtTradingRecords[i][item]);
                    }
                  // roe处理
                  }else if(item.indexOf("roe") != -1){
                    line.push(this.usdtTradingRecords[i][item] * 100 + "%");
                  }else{
                    line.push(this.usdtTradingRecords[i][item]);
                  }
                }
              }
            }
          })
          body.push(line);
        }
        CsvExportor.downloadCsv(body, { header: heads }, 'USDT-Trading-Record-' +this.user.username + '.csv');
        this.exportorLoading = false;
      },
      // 获取USDT 交易往返记录
      getUSDTTradingRecords(){
        this.usdtTradingRecordLoading = true;
        this.usdtTradingRecords = [];
        var last = "";
        if (this.usdtTradeSearchForm.exchange != null) {
          last = last + "&exchange=" + this.usdtTradeSearchForm.exchange;
        }
        if (this.usdtTradeSearchForm.side != null) {
          if (this.usdtTradeSearchForm.side == 'Long') {
            last = last + "&side=open_long"
          }
          if (this.usdtTradeSearchForm.side == 'Short') {
            last = last + "&side=open_short"
          }
        }
        if (this.usdtTradeSearchForm.symbol != null) {
          last = last + "&symbol=" + this.usdtTradeSearchForm.symbol;
        }
        let api = config.baseUrl + "/order/swap/usdt/private/getTradingRecords?pageNo=" + this.usdtTradingRecordPageNo + "&pageSize=" + this.pageSize + last;
        this.$http.get(api).then(res => {
          let data = res.data;
          if (data.code == 200) {
            this.usdtTradingRecords = data.result.tradingRecords;
            this.usdtTradingRecordTotalElements = data.result.total;
            this.usdtTradingRecordTotalPage = Math.ceil(this.usdtTradingRecordTotalElements / this.pageSize);
            this.usdtTradingRecordLoading = false;
          } else {
            this.usdtTradingRecordTotalElements = 0;
            this.usdtTradingRecordTotalPage = 0;
            this.usdtTradingRecordLoading = false;
          }
        })
      },
      // 交易所code转为交易所name
      getExchangeNameByCode(code) {
        return getExchangeNameByCode(this.exchanges, code);
      },
      // 格式化日期
      formatDate(timestamp) {
        if ('localTime' == this.time) {
          return formatTime(timestamp);
        } else {
          return formatUTCTime(timestamp);
        }
      },
      // 格式化数字
      formatNumber(num) {
        return formatNumber(num)
      },
      // 获取USDT永续合约小数位
      getSwapUsdtDecimalPlace(exchange, symbol) {
        if (exchange == null || symbol == null) {
          return 8;
        }
        let currentExchangeSwapUsdtSymbolInfos = this.swapUsdtSymbolInfos[exchange];
        for (let i = 0, len = currentExchangeSwapUsdtSymbolInfos.length; i < len; i++) {
          if (currentExchangeSwapUsdtSymbolInfos[i].symbol == symbol) {
            return currentExchangeSwapUsdtSymbolInfos[i].priceDecimalPlace;
          }
        }
        return 8;
      },
      // 复制到剪切板
      copyToClipboard(message) {
        this.$copyText(message).then(res => {
          // 复制成功
          this.$store.dispatch('snackbarMessageHandler', this.$t('common.copied'));
        }).catch(err => {
          // 复制失败
          this.$store.dispatch('snackbarMessageHandler', this.$t('common.copyFailed'));
        })
      },
    }
  }

</script>

<style>

</style>
