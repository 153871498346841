<template>
  <v-card class="pt-5 h-100">
    <!-- ************************************提示***************************************************** -->
    <span class="mx-5 flex-b">
      <v-icon color="grey" @click="$emit('update:isShowNoticationSidebar', !isShowNoticationSidebar)">mdi-arrow-left</v-icon>
    </span>
    <v-list>
      <v-list-item class="ma-0">
        <v-list-item-icon>
          <v-icon class="ml-1" @click="refresh">mdi-bell-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            <span class="fs-20">{{$t("notification.notifications")}}</span>
            <span v-if="isLogin" class="pointer float-right mr-4" @click="markAllAsRead">
              {{ $t("notification.markAllAsRead") }}
            </span>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-divider></v-divider>
    <div class="mx-4 fs-12">
      {{ $t('notification.information') }}
    </div>
    <v-divider></v-divider>
    <!-- ************************************未登录通知***************************************************** -->
    <v-list class="mx-5" v-if="!isLogin">
      <div class="mb-3" v-for="(notification, index) in notificationList" :key="index">
        <div>
          <span class="fs-12">{{ formatTime(notification.timestamp) }}</span>
          <span class="fs-12 ml-2" v-time="notification.timestamp"></span>
        </div>
        <div class="mt-2">
          <span class="fs-14">{{ notification.message }}</span>
        </div>
        <div v-if="index!=notificationList.length-1" class="d-custom-flex mt-3">
          <v-divider class="mr-3"></v-divider>
          <div>·</div>
          <v-divider class="ml-3"></v-divider>
        </div>
      </div>
      <div v-if="notificationList.length > 0">
        <div class="d-custom-flex mt-3">
          <v-divider class="mr-3"></v-divider>
          <div>·</div>
          <v-divider class="ml-3"></v-divider>
        </div>
        <div class="text-center mb-16 fs-18">{{$t("notification.signInMessage")}}</div>
      </div>
    </v-list>
    <!-- ************************************登录通知***************************************************** -->
    <v-list class="mx-5" v-if="isLogin">
      <!-- 无限下滑div -->
      <div>
        <div class="mb-3" v-for="(notification, index) in notificationList" :key="index">
          <div>
            <span class="fs-12">{{ formatTime(notification.timestamp) }}</span>
            <span class="fs-12 ml-2" v-time="notification.timestamp" :key="index"></span>
            <span v-show="notification.beNotRead" class="float-right font-weight-bold pointer" @click="readNotification(notification)">
              <v-icon class="green--text" size="12">mdi-circle</v-icon>
            </span>
          </div>
          <div class="mt-2">
            <span class="fs-14">{{ notification.message }}</span>
          </div>
          <div v-show="index!=notificationList.length-1" class="d-custom-flex mt-3">
            <v-divider class="mr-3"></v-divider>
            <div>·</div>
            <v-divider class="ml-3"></v-divider>
          </div>
        </div>
        <!--infinite-loading这个组件要放在列表的底部，滚动的盒子里面！-->
        <infinite-loading @infinite="getNotificationList" class="mb-3" :identifier="infiniteId">
          <div slot="spinner">
            <v-progress-circular indeterminate color="primary"></v-progress-circular>
          </div>
          <div slot="no-more"></div>
          <div slot="no-results"></div>
        </infinite-loading>
      </div>
    </v-list>
  </v-card>
</template>

<script>
  import Vue from 'vue'
  import { mapGetters } from "vuex";
  import InfiniteLoading from 'vue-infinite-loading';
  import config from '@/config.js'
  export default {
    data() {
      return {
        isLoadingNotificationList: false, // 是否正在加载通知集合
        notificationList: [], // 消息通知的集合
        pageNo: 0, // 页码
        pageSize: 10, // 每次查询几条数据
        infiniteId: +new Date(), // 无限加载id
      }
    },
    props: {
      isShowNoticationSidebar: Boolean
    },
    computed: {
      ...mapGetters(['user', 'time', 'webSocketData', 'webSocketDataIndex', 'notificationNum']),
      // 是否登录
      isLogin() {
        return (this.user.token != null && this.user.token != "");
      }
    },
    components: {
      InfiniteLoading
    },
    created() {

    },
    mounted() {
      if (!this.isLogin) {
        this.getPublicNotificationList();
      }
    },
    watch: {
      webSocketDataIndex(newVal, oldVal) {
        if (this.webSocketData) {
          this.getWebSocketData();
        }
      }
    },
    methods: {
      // 刷新
      refresh() {
        this.pageNo = 0;
        this.notificationList = [];
        if (this.user != null && this.user.token) {
          this.infiniteId += 1;
          this.getNewNotificationNum();
        } else {
          this.getPublicNotificationList();
        }
      },
      // 查询公开消息通知的集合
      getPublicNotificationList() {
        let api = config.baseUrl + '/notification/public/getNotificationList';
        this.$http.get(api).then(response => {
          let data = response.data;
          if (data.code == 200) {
            // 将新查询的集合添加在已有集合的尾部
            this.notificationList = [...this.notificationList, ...data.result];
          }
        })
      },
      // 查询消息通知的集合
      getNotificationList($state) {
        if (this.isLoadingNotificationList) {
          return;
        }
        this.isLoadingNotificationList = true;
        let api = config.baseUrl + '/notification/private/getNotificationList?pageNo=' + this.pageNo + '&pageSize=' +
          this.pageSize;
        this.$http.get(api).then(response => {
          let data = response.data;
          if (data.code == 200) {
            this.pageNo += 1;
            // 将新查询的集合添加在已有集合的尾部
            this.notificationList = [...this.notificationList, ...data.result];
            this.isLoadingNotificationList = false;
            $state.loaded();
          } else {
            $state.complete();
          }
        })
      },
      // 标记所有为已读
      markAllAsRead() {
        this.$store.dispatch('notificationNumHandler', 0);
        this.updateReadIndex(this.notificationList[0].autoIndex);
        for (let i = 0, len = this.notificationList.length; i < len; i++) {
          if (this.notificationList[i].beNotRead) {
            this.notificationList[i].beNotRead = false;
          }
        }
      },
      // 阅读消息
      readNotification(notification) {
        notification.beNotRead = false;
        this.$store.dispatch('notificationNumHandler', this.notificationNum - 1);
        this.updateReadIndex(notification.autoIndex);
      },
      // 更新已读下标
      updateReadIndex(readIndex) {
        if (readIndex > this.user.readIndex) {
          this.$http.get(config.baseUrl + '/user/private/updateReadIndex?readIndex=' + readIndex).then(response => {
            let data = response.data;
            if (data.code == 200) {
              this.$store.dispatch('readIndexHandler', data.result);
            }
          })
        }
      },
      // 查询消息通知数量
      getNewNotificationNum() {
        if (this.user != null && this.user.token != null) {
          this.$http.get(config.baseUrl + '/notification/private/getNewNotificationNum').then(response => {
            let data = response.data;
            if (data.code == 200) {
              this.$store.dispatch('notificationNumHandler', data.result);
            }
          });
        }
      },
      // 获取WebSocket数据
      getWebSocketData() {
        if (this.webSocketData == null) {
          return;
        }
        if (this.webSocketData.NOTIFICATION) {
          // let notification = this.webSocketData.NOTIFICATION;
          // this.notificationList.unshift(notification);
          // this.$store.dispatch('notificationNumHandler', this.notificationNum + 1);
          this.$store.dispatch('webSocketDataHandler', null);
          this.refresh();
        }
      },
      // 格式化时间
      formatTime(timestamp) {
        if ('UTCTime' == this.time) {
          return formatUTCTime(timestamp);
        } else {
          return formatTime(timestamp);
        }
      },
    }
  }
</script>
