export default {
  root:true,
  dark: localStorage.getItem("iobots-darkMode") == 1 ? true : false,
  themes:{
    light:{
      primary: '#9E9E9E',
      // red: '#4CAF50',
      // green: '#F44336',
      // upBars: '#F5A6AE',
      // downBars: '#A9DCC3',
      },
    dark:{
      primary: '#9E9E9E',
      // red: '#4CAF50',
      // green: '#F44336',
      // upBars: '#F5A6AE',
      // downBars: '#A9DCC3',
    },
  },
  // red: '#4CAF50',
  // green: '#F44336',
  // upBars: '#F5A6AE',
  // downBars: '#A9DCC3',
}